import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FirebaseRequestsService } from "./firebase-requests.service";
import { Rol } from "../models/rol.interface";
import { UserBase } from "../models/userBase";
import { UserType } from "../enums/UserTypes";
import { permisos as permisosData } from "../utils/userPermisos";
import { Permiso } from "../models/permisosinterface";

export type PermisosType = {
  [key: string]: string[]; // Para todas las claves de tipo string, los valores son arrays de strings
} & {
  [key in UserType]: string[]; // Para cada miembro del enum UserType, los valores son arrays de strings
};
@Injectable({
  providedIn: "root",
})
export class PermisosService {
  public permisos!: PermisosType;
  permisosSvc: any;

  constructor(private firebaseRequest: FirebaseRequestsService) {}

  getRoles(): Observable<Rol[]> {
    return this.firebaseRequest.getCollectionFirebaseWithQueryObservable<Rol>(
      "roles"
    );
  }

  async getPermisos(uid?: string): Promise<PermisosType> {
    if (!this.permisos && uid) {
      // Requiero ver la tabla para crear modelos correctos
      const user: UserBase =
        await this.firebaseRequest.getDocFirebaseWithIdPromise<UserBase>(
          `usuarios/${uid}`
        );
      const roles: UserType[] =
        typeof user.tipo === "string" ? [user.tipo] : user.tipo;

      const promises: Promise<{
        [x: string]: string[];
      }>[] = roles.map(async (rol) => {
        const rolDoc: Rol =
          await this.firebaseRequest.getDocFirebaseWithIdPromise<Rol>(
            `roles/${rol}`
          );
        return { [rol]: rolDoc.permisos };
      });

      const permisosTmpArray: {
        [x: string]: string[];
      }[] = await Promise.all(promises);
      const permisosTmp: PermisosType = Object.assign({}, ...permisosTmpArray);

      this.permisos = permisosTmp;
    }
    return this.permisos;
  }

  updateRole(id: string, permisos: string[]): Promise<void> {
    return this.firebaseRequest.updateDocFirebase(`roles/${id}`, { permisos });
  }

  eliminarPermisosDuplicados = (roles: PermisosType): string[] => {
    // Validar
    const tmp: PermisosType = { ...roles };
    const permisos: string[] = [];
    Object.keys(tmp).forEach((r: string) => {
      tmp[r].forEach((p: string) => {
        !permisos.includes(p) && permisos.push(p);
      });
    });
    return permisos;
  };

  async getBotones(): Promise<Permiso[]> {
    try {
      const tmp: string[] = this.eliminarPermisosDuplicados(
        await this.getPermisos()
      );
      const btns: Permiso[] = permisosData.filter(
        (p: Permiso) => tmp.includes(p.name) && !p.hidden
      );
      return btns;
    } catch (e) {
      console.error(e);
      throw new Error("Error getBotones");
    }
  }
}
