import { CrudService } from "src/app/core/service/crud.service";
import { NEW_WT_LICENSE, WtLicense } from "../model/wt-license";
import { WtLicenseState } from "../model/wt-license.state";
import { Injectable, OnDestroy } from "@angular/core";
import { WtLicenseFirestoreService } from "./wt-license-firestore.service";
import { WtLicenseStore } from "./wt-license.store";

@Injectable({
  providedIn: "root",
})
export class WtLicenseService
  extends CrudService<WtLicense, WtLicenseState>
  implements OnDestroy
{
  constructor(
    protected override firestore: WtLicenseFirestoreService,
    protected override store: WtLicenseStore
  ) {
    super(firestore, store);
  }

  public init(): void {
    this.firestore.basePath = "wt_licenses";
    this.initCollection();
  }

  public emptyDocument(): WtLicense {
    return NEW_WT_LICENSE;
  }
}
