<div class="modal-header">
  <h4 class="modal-title">Subasta creada</h4>
</div>
<div class="modal-body">
  <p>La subasta ha sido creada exitosamente. ¿Desea activarla?
    Si la activa dando click en <b>SI</b> el producto estará disponible
    al público, si da click en <b>NO</b> estará disponible para editar</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">No</button>
  <button type="button" class="btn btn-success" (click)="onYes()">Si</button>
</div>