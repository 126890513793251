import { Injectable } from "@angular/core";
import { LocalizeRouterService } from "@gilsdav/ngx-translate-router";
import { BehaviorSubject } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class LangTranslateService {
  public currrentLang: BehaviorSubject<string> = new BehaviorSubject(
    this.localize?.parser?.currentLang
  );
  public lang: string = this.localize?.parser?.currentLang;

  constructor(private localize: LocalizeRouterService) {}

  setLang(lang: "en" | "es"): void {
    this.currrentLang.next(lang);
    this.lang = lang;
  }
}
