<h3 class="subtitulo-texto mt-5">Gestión de revistas</h3>
<div>
  <form [formGroup]="form" class="p-3">
    <div class="row">
      <div class="col-sm-4 col-img">
        <div
          class="cont-img"
          [ngClass]="{
            'is-invalid':
              form.get('imagen')?.invalid && form.get('imagen')?.touched
          }"
        >
          <img
            [src]="
              files['imagen'] && imgPrev
                ? imgPrev
                : '../../../assets/imagenes/noImage.png'
            "
            alt=""
          />
          <input
            accept=".jpg,.jpeg,.png,.bmp"
            type="file"
            hidden
            #inputPortada
            (change)="selectFile($event, 'imagen')"
          />
        </div>
        <div class="row">
          <div class="col-sm">
            <app-errores [control]="form.get('imagen')"></app-errores>
          </div>
        </div>
        <div class="row justify-content-center mt-1">
          <button
            *ngIf="!files['imagen'] && !imgPrev"
            type="button"
            class="btn btn-primary"
            (click)="inputPortada.click()"
          >
            Seleccionar Imagen
          </button>
          <button
            *ngIf="files['imagen'] && imgPrev"
            type="button"
            class="btn btn-danger"
            (click)="this.resetFile('imagen')"
            [disabled]="saving"
          >
            Limpiar imagen
            <img
              *ngIf="loadingImage"
              class="img-gif"
              src="../../assets/imagenes/loading_gif.gif"
              alt="cargando"
            />
          </button>
        </div>
      </div>
      <div class="col-sm-8">
        <div class="row">
          <div class="mb-3 col-12">
            <label for="exampleFormControlInput1" class="form-label"
              >Título</label
            >
            <input
              type="text"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  form.get('titulo')?.invalid && form.get('titulo')?.touched
              }"
              formControlName="titulo"
              id="titulo"
            />
            <app-errores [control]="form.get('titulo')"></app-errores>
          </div>
          <div class="mb-3 col-12">
            <label for="exampleFormControlTextarea1" class="form-label"
              >Descripción</label
            >
            <textarea
              class="form-control is-invalid"
              rows="2"
              formControlName="descripcion"
              #descripcion
              [ngClass]="{
                'is-invalid':
                  form.get('descripcion')?.invalid &&
                  form.get('descripcion')?.touched
              }"
            ></textarea>
            <app-errores [control]="form.get('descripcion')"></app-errores>
          </div>
          <div
            class="input-group mb-3 col-12"
            *ngIf="(editing && cambiarPdf) || !editing"
          >
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroupFileAddon01"
                >Revista Pdf</span
              >
            </div>
            <div class="custom-file">
              <input
                type="file"
                accept=".pdf"
                class="custom-file-input"
                [ngClass]="{
                  'is-invalid':
                    form.get('pdf')?.invalid && form.get('pdf')?.touched
                }"
                id="inputGroupFile01"
                #inputGroupFile01
                aria-describedby="inputGroupFileAddon01"
                (change)="selectFile($event, 'pdf')"
              />
              <label class="custom-file-label" for="inputGroupFile01">{{
                files["pdf"]?.name || "Choose file"
              }}</label>
            </div>
          </div>
          <div *ngIf="editing && !cambiarPdf" class="w-100 text-center">
            <button
              class="btn btn-primary m-1"
              (click)="tablaComp.abrirPdf(editing)"
            >
              Ver Revista
            </button>

            <button class="btn btn-danger m-1" (click)="cambiarPdf = true">
              Cambiar PDF
            </button>
          </div>
          <span class="col-12">
            <app-errores [control]="form.get('pdf')"></app-errores>
          </span>
        </div>
        <div class="col-12" *ngIf="saving">
          <div class="row">
            <div class="col">
              <p>Portada</p>
            </div>
            <div class="col-10">
              <div class="progress m-1">
                <div
                  class="progress-bar"
                  role="progressbar"
                  [style]="'width: ' + (savingImage || 0) + '%;'"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {{ (savingImage | number: "1.0-2") || 0 }}%
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p>PDF</p>
            </div>
            <div class="col-10">
              <div class="progress m-1">
                <div
                  class="progress-bar"
                  role="progressbar"
                  [style]="'width: ' + (savingPdf || 0) + '%;'"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {{ (savingPdf | number: "1.0-2") || 0 }}%
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <button
            type="button"
            class="btn btn-guardar m-1"
            [disabled]="saving || form.invalid"
            (click)="guardar()"
          >
            Guardar
            <img
              *ngIf="saving"
              class="img-gif"
              src="../../assets/imagenes/loading_gif.gif"
              alt="cargando"
            />
          </button>
          <button
            type="button"
            class="btn btn-warning m-1"
            [disabled]="saving"
            (click)="clearForm()"
          >
            Limpiar
          </button>
        </div>
      </div>
    </div>
  </form>
  <app-tabla-revistas
    (revistaToEdit)="seleccionarRevista($event)"
  ></app-tabla-revistas>
</div>
