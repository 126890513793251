<div class="seccion">
  <h2 class="titulo-seccion">
    {{ "proyecto.proceso" | translate }}
  </h2>
  <p
    class="parrafo-seccion"
    innerHTML="{{ 'proyecto.parrafo1' | translate }}"
  ></p>
  <p
    class="parrafo-seccion"
    innerHTML="{{ 'proyecto.parrafo2' | translate }}"
  ></p>
  <p class="tabla-responsiva-texto">
    {{ "proyecto.desliceIzquierda" | translate }}
  </p>
  <div class="table-responsive-md">
    <table class="table table-bordered">
      <thead class="table-dark border-light">
        <tr>
          <th scope="col-6" colspan="2">
            {{ "proyecto.paraColombia" | translate }}
          </th>
          <th scope="col-6" colspan="1">
            {{ "proyecto.pagosElectronicos" | translate }}
          </th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th scope="col-3">{{ "proyecto.tiempo" | translate }}</th>
          <th scope="col-3">{{ "proyecto.costo" | translate }}</th>
          <th scope="col-3">
            <img
              class="img-table"
              src="../../assets/imagenes/wompi.svg"
              alt="PayPal"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1 {{ "proyecto.mes" | translate }}</td>
          <td>{{ "proyecto.precioColombia1Mes" | translate }}</td>
          <td>
            <button type="button" class="btn btn btn-primary">
              <a
                class="btn-text"
                href="https://checkout.wompi.co/l/UhUJRb"
                target="_blank"
              >
                {{ "proyecto.pago" | translate }}
              </a>
            </button>
          </td>
        </tr>
        <tr>
          <td>3 {{ "proyecto.meses" | translate }}</td>
          <td>{{ "proyecto.precioColombia3Mes" | translate }}</td>
          <td>
            <button type="button" class="btn btn btn-primary">
              <a
                class="btn-text"
                href="https://checkout.wompi.co/l/iluHVd"
                target="_blank"
              >
                {{ "proyecto.pago" | translate }}
              </a>
            </button>
          </td>
        </tr>
        <tr>
          <td>6 {{ "proyecto.meses" | translate }}</td>
          <td>{{ "proyecto.precioColombia6Mes" | translate }}</td>
          <td>
            <button type="button" class="btn btn btn-primary">
              <a
                class="btn-text"
                href="https://checkout.wompi.co/l/PjIHat"
                target="_blank"
              >
                {{ "proyecto.pago" | translate }}
              </a>
            </button>
          </td>
        </tr>
        <tr>
          <td>12 {{ "proyecto.meses" | translate }}</td>
          <td>{{ "proyecto.precioColombia1Año" | translate }}</td>
          <td>
            <button type="button" class="btn btn btn-primary">
              <a
                class="btn-text"
                href="https://checkout.wompi.co/l/531VT9"
                target="_blank"
              >
                {{ "proyecto.pago" | translate }}
              </a>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  {{ "proyecto.iva" | translate }}

  <p
    class="parrafo-seccion"
    innerHTML="{{ 'proyecto.pagoRealizado' | translate }}"
  ></p>
  <ol
    class="listado-suscripción"
    innerHTML="{{ 'proyecto.informacionPago' | translate }}"
  ></ol>
  <p class="parrafo-seccion">
    {{ "proyecto.pagoRecibido" | translate }}
  </p>

  <!-- Beneficios de la suscripción-->
  <h2 class="titulo-seccion">
    {{ "proyecto.beneficiosSuscripcion" | translate }}
  </h2>
  <p class="parrafo-seccion">
    {{ "proyecto.pagoSuscripcion" | translate }}
  </p>
  <ol
    class="listado-suscripción"
    innerHTML="{{ 'proyecto.beneficios' | translate }}"
  >
    .
  </ol>
  <h2 class="titulo-seccion">{{ "proyecto.paisesCompradores" | translate }}</h2>
  <p
    class="parrafo-seccion"
    innerHTML="{{ 'proyecto.eresComprador' | translate }}"
  ></p>
  <!-- Incluir acá un mapa de los países donde tenemos influencia-->
  <div class="contenedor-img">
    <img class="img-fluid" src="../../assets/imagenes/mapaMundo.webp" />
  </div>
  <h2 class="titulo-seccion pb-3">{{ "proyecto.bancolombia" | translate }}</h2>
  <p
    class="parrafo-seccion mt-3"
    innerHTML="{{ 'proyecto.bancolombiaParrafo1' | translate }}"
  ></p>
  <p class="parrafo-seccion">
    {{ "proyecto.bancolombiaParrafo2" | translate }}
  </p>
  <div class="contenedor-img">
    <img class="imagen-pago" src="../../assets/imagenes/CodigoQR.webp" />
  </div>
  <app-btn-registro></app-btn-registro>
</div>
