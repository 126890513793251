import { Component, OnDestroy } from "@angular/core";
import { takeUntil, map, tap, switchMap } from "rxjs/operators";
import { Observable, Subject, combineLatest } from "rxjs";
import { AuthService } from "src/app/services/auth.service";
import { CalificacionService } from "src/app/services/calificacion.service";
import { UserData, UserService } from "src/app/services/user.service";
import { User } from "@angular/fire/auth";
import { UserDataHelpers } from "src/app/utils/getApellidosUserData";

@Component({
  selector: "app-calificacion",
  templateUrl: "./calificacion.component.html",
  styleUrls: ["./calificacion.component.css"],
})
export class CalificacionComponent implements OnDestroy {
  private unsubscriber: Subject<void> = new Subject();
  private user$: Observable<User | null> = this.authSvc
    .authStateChanged()
    .pipe(takeUntil(this.unsubscriber));
  public currentPage: number = 1;
  public itemsPerPage: number = 5;
  public calificaciones: any = [];

  constructor(
    private authSvc: AuthService,
    private calificacionService: CalificacionService,
    private userService: UserService
  ) {
    this.user$
      .pipe(
        takeUntil(this.unsubscriber),
        map((user: User | null) => user?.uid),
        tap({
          next: (userId: string | undefined) => {
            if (userId) {
              this.getCalificaciones(userId);
            }
          },
          error: (error) => {
            console.error(error);
          },
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  getCalificaciones(userId: string): void {
    let calificacionesTmp: any;
    this.calificacionService
      .getUserCalificaciones(userId)
      .pipe(
        takeUntil(this.unsubscriber),
        map((calificaciones: any) => {
          return calificaciones.map((calif: any) => {
            calif.puntuacion = new Array(calif.puntuacion).fill("*");
            return calif;
          });
        }),
        switchMap((calificaciones: any) => {
          calificacionesTmp = calificaciones;
          const arrayObs$: Observable<string>[] = calificacionesTmp.map(
            (calif: any) =>
              this.getComprador(calif.idCalificador).pipe(
                tap({
                  next: (vendedor: string) => (calif.vendedor = vendedor),
                  error: (error) => {
                    console.error(error);
                  },
                })
              )
          );
          return combineLatest(arrayObs$);
        }),
        tap({
          next: () => {
            this.calificaciones = calificacionesTmp;
          },
          error: (error) => {
            console.error(error);
          },
        })
      )
      .subscribe();
  }

  getComprador(id: string): Observable<string> {
    return this.userService
      .getUserById(id)
      .pipe(
        map(
          (user: UserData) =>
            `${user.nombres || ""} ${UserDataHelpers.getApellidos(user)}`
        )
      );
  }
}
