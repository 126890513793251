<div class="card">
  <div class="card-body">
    <h5 class="card-title">
      <div class="header">
        <span class="d-flex">
          <img [src]="fotoUsuario | async" alt="" />
          <p class="mx-1">
            {{ nombreCliente | titlecase }}
          </p>
        </span>
        <button type="button" class="btn btn-danger" (click)="toClose()">
          {{ "formularios.cerrar" | translate }}
        </button>
      </div>
    </h5>
    <div class="body-chat" #chatScroll>
      <div
        class="w-100 d-flex"
        *ngFor="let item of mensajes"
        [ngClass]="{ 'justify-content-end': item.idUsuario === idUsuario }"
      >
        <div
          class="alert w-75"
          role="alert"
          [ngClass]="
            item.idUsuario === idUsuario ? 'alert-primary' : 'alert-success'
          "
        >
          <div class="w-100 date-msg">
            <b>{{ item.fecha | timestampToString }}</b>
          </div>
          <p class="message">
            {{ item.mensaje }}
          </p>
        </div>
      </div>
    </div>
    <div class="footer-chat row m-1">
      <input type="text" class="col-7 col-md-10" #mensaje />
      <button
        class="btn btn-primary btn-sm mx-1 col"
        (click)="enviarMensaje(mensaje)"
      >
        {{ "conversacion.enviar" | translate }}
        <img
          class="icon-svg"
          src="../../../assets/imagenes/paper-plane-solid-white.svg"
          alt="avion"
        />
      </button>
    </div>
  </div>
</div>
