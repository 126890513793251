import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  DatosCertificado,
  DatosProducto,
} from "../models/certificadoProducto.interface";
import { CertificadoProductoService } from "../services/certificado-producto.service";
import { CertificadoPdfService } from "../services/certificado-pdf.service";
import { Actions } from "../enums/Actions";

@Component({
  selector: "app-certificado-producto",
  templateUrl: "./certificado-producto.component.html",
  styleUrls: ["./certificado-producto.component.css"],
})
export class CertificadoProductoComponent implements OnInit {
  @ViewChild("content") modal!: ElementRef;

  @Output() abrirModalCertificado: EventEmitter<
    (idCompra: string, idPedido: string) => Promise<void>
  > = new EventEmitter();
  datosCertificado!: DatosCertificado;
  actionsEnum: typeof Actions = Actions;

  constructor(
    private modalService: NgbModal,
    private certificadoService: CertificadoProductoService,
    private certificadoPdfService: CertificadoPdfService
  ) {}

  ngOnInit(): void {
    this.abrirModalCertificado.emit(this.open.bind(this));
  }

  async open(idCompra: string, idPedido: string): Promise<void> {
    try {
      const idCertificado = await this.certificadoService.addCertificado({
        idCompra,
        idPedido,
      });

      this.datosCertificado = await this.certificadoService.getDatosCertificado(
        idCompra,
        idPedido
      );

      this.datosCertificado.idCertificado = idCertificado;
      this.datosCertificado.idPedido = idPedido;

      this.modalService.open(this.modal, {
        size: "lg",
        ariaLabelledBy: "modal-basic-title",
        centered: true,
        backdrop: "static",
        keyboard: false,
      });
    } catch (e) {
      console.error(e);
    }
  }

  getKeys(object: DatosProducto | undefined): string[] {
    if (!object) return [];

    return Object.keys(object).filter((key) => {
      return (
        object[key] &&
        !["especie", "unidadMedida", "cantidad", "categoria"].includes(key)
      );
    });
  }

  generarPdf(accion: Actions) {
    if (this.datosCertificado?.idPedido) {
      this.certificadoPdfService.generarCertificadoPdf(
        this.datosCertificado.idPedido,
        accion
      );
    }
  }
}
