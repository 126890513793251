<div class="w-100 row m-2 file-progress" *ngFor="let file of progress">
  <div class="col-2">
    <h5 class="nombre-archivo">
      {{ file.name | titlecase }}
    </h5>
  </div>

  <div class="col-10">
    <div class="progress">
      <div
        class="progress-bar"
        role="progressbar"
        [ngStyle]="{ width: ((file.currentUploads | async)!.progress | porcentajeRound) + '%' }"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        {{ ((file.currentUploads | async)!.progress | porcentajeRound) }}%
      </div>
    </div>
  </div>
</div>
