<ul class="nav nav-tabs mt-5" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button
      class="nav-link active"
      id="misVentas-tab"
      data-bs-toggle="tab"
      data-bs-target="#misVentas"
      type="button"
      role="tab"
      aria-controls="misVentas"
      aria-selected="true"
    >
      {{ "ventas.misVentas" | translate }}
    </button>
  </li>
  <li class="nav-item" role="presentation">
    <button
      class="nav-link"
      id="mensajes-tab"
      data-bs-toggle="tab"
      data-bs-target="#mensajes"
      type="button"
      role="tab"
      aria-controls="mensajes"
      aria-selected="false"
    >
      {{ "mensajes.mensajes" | translate }}
    </button>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <div
    class="tab-pane fade show active"
    id="misVentas"
    role="tabpanel"
    aria-labelledby="misVentas-tab"
  >
    <h3 class="titulo-contenido-perfil">
      {{ "ventas.misVentas" | translate }}
    </h3>
    <p>
      {{ "ventas.encontrarInformacion" | translate }}
    </p>
    <div class="table-responsive-lg">
      <table class="table table-hover">
        <thead class="thead-dark">
          <tr>
            <th scope="col">{{ "ventas.pedido" | translate }}</th>
            <th scope="col">{{ "ventas.informacion" | translate }}</th>
            <th scope="col">{{ "ventas.comprador" | translate }}</th>
            <th scope="col">{{ "ventas.vendido" | translate }}</th>
            <th scope="col">{{ "ventas.enviado" | translate }}</th>
            <th scope="col">{{ "ventas.recibido" | translate }}</th>
            <th scope="col">{{ "ventas.estado" | translate }}</th>
            <th scope="col">{{ "ventas.calificacion" | translate }}</th>
          </tr>
        </thead>
        <tbody
          class="contenido-tabla"
          *ngFor="
            let pedido of misPedidos
              | paginate
                : { itemsPerPage: itemsPerPage, currentPage: currentPage };
            let elementPos = index
          "
        >
          <tr
            *ngFor="let item of pedido.productos; let productoPos = index"
            [ngClass]="{ oculto: item.idVendedor !== userID }"
          >
            <th>{{ pedido.fechaFormat }}</th>
            <td>
              <a
                type="button"
                class="btn btn-dark"
                data-bs-toggle="modal"
                data-bs-target="#solicitarInformacion"
                (click)="
                  getDetalle(
                    item.idProducto,
                    item,
                    pedido?.fecha,
                    pedido.idComprador
                  )
                "
              >
                {{ "ventas.detalle" | translate }}
              </a>
            </td>
            <td>{{ item.comprador }}</td>
            <td>
              <button
                data-bs-toggle="modal"
                data-bs-target="#modalConfirmSale"
                type="button"
                class="btn btn-success"
                (click)="seleccionarPedido(pedido, productoPos, true)"
                [disabled]="item.vendido"
                *ngIf="
                  !item.vendidoModificado ||
                  (item.vendido && item.vendidoModificado)
                "
              >
                {{ "formularios.si" | translate }}
              </button>
              <button
                data-bs-toggle="modal"
                data-bs-target="#modalRejectSale"
                id="botonNo1"
                type="button"
                (click)="seleccionarPedido(pedido, productoPos, false)"
                class="btn btn-danger"
                [disabled]="item.vendidoModificado"
                *ngIf="
                  (item.vendidoModificado && !item.vendido) ||
                  !item.vendidoModificado
                "
              >
                {{ "formularios.no" | translate }}
              </button>
            </td>
            <td>
              <input
                hidden
                (change)="onFileSelected(item.id, $event)"
                #fileInput
                type="file"
                id="file"
                accept=".jpg,.jpeg,.png,.bmp,.pdf"
              />
              <button
                [disabled]="!item.vendido"
                *ngIf="!item.guia"
                (click)="fileInput.click()"
                type="button"
                class="btn btn-success"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title="Adjunte guía de envío"
              >
                <img
                  class="imagen-calificacion"
                  src="../../../assets/imagenes/truck-solid.svg"
                />
              </button>
              <button
                [disabled]="item.estado === 'Rechazado'"
                *ngIf="item.guia"
                type="button"
                class="btn btn-success"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title="Descargue la guía de envío"
                data-bs-toggle="modal"
                data-bs-target="#modalGuia"
                (click)="guiaMostrar = item.guia"
              >
                <img
                  class="imagen-calificacion"
                  src="../../../assets/imagenes/file-download-solid.svg"
                />
              </button>
            </td>
            <td>
              {{ "formularios." + (item.recibido ? "si" : "no") | translate }}
            </td>
            <td>
              {{ item.estado }}
            </td>
            <td>
              <button
                [disabled]="
                  item.calificacionVendedor || item.estado === 'pendiente'
                "
                type="button"
                class="btn btn-info"
                data-bs-toggle="modal"
                data-bs-target="#calificacionModal"
                (click)="calificar(pedido, productoPos)"
              >
                <img
                  class="imagen-calificacion"
                  src="../../../assets/imagenes/thumbs-up-solid.svg"
                />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="text-center" *ngIf="loading === true">
        <img
          class="img-gif"
          src="../../assets/imagenes/loading_gif.gif"
          alt="cargando"
        />
        <span>{{ "formularios.cargando" | translate }}</span>
      </div>
      <!--paginado para los resultados-->
      <pagination-controls
        class="my-pagination"
        (pageChange)="currentPage = $event"
        previousLabel="Anterior"
        nextLabel="Siguiente"
      >
      </pagination-controls>
    </div>
  </div>
  <div
    class="tab-pane fade"
    id="mensajes"
    role="tabpanel"
    aria-labelledby="mensajes-tab"
  >
    <h3 class="titulo-contenido-perfil">
      {{ "ventas.misPreguntas" | translate }}
    </h3>
    <p>{{ "ventas.parrafoMensaje" | translate }}</p>
    <app-mensajes [idVendedor]="userID"></app-mensajes>
  </div>
</div>
<!--modal, ventana emergente para calificación y comentarios del comprador-->
<div
  class="modal fade"
  id="calificacionModal"
  tabindex="-1"
  data-bs-backdrop="static"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <app-calificacion-y-comentarios
          [datosCalificacion]="datosCalificacion"
          (idCalificacion)="marcarCalificado($event)"
        ></app-calificacion-y-comentarios>
      </div>
      <button
        #cerrarModal
        hidden
        type="button"
        class="close"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
  </div>
</div>
<!--moda, ventana emergente para informacion del producto-->
<div
  class="modal fade"
  id="solicitarInformacion"
  tabindex="-1"
  data-bs-backdrop="static"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Detalle del pedido</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <ol class="pl-3">
          <li class="pt-2">
            <b>{{ "ventas.fechaSolicitud" | translate }}</b>
            {{ detalles?.fecha | date: "dd/MM/yyyy" }}
          </li>
          <li class="pt-2">
            <b>{{ "formularios.categoria" | translate }}</b>
            {{ detalles?.categoria }}
          </li>
          <li class="pt-2">
            <b>{{ "formularios.especie" | translate }}</b>
            {{ detalles?.especie }}
          </li>
          <li class="pt-2">
            <b>{{ "ventas.precioUnidad" | translate }}</b>
            {{ detalles?.precio | currency }}
          </li>
          <li class="pt-2">
            <b>{{ "ventas.unidadMedida" | translate }}</b>
            {{ detalles?.unidadMedida }}
          </li>
          <li class="pt-2">
            <b>{{ "ventas.unidadesSolicitadas" | translate }}</b>
            {{ detalles?.unidadesSolicitadas | number }}
          </li>
          <li class="pt-2">
            <b>{{ "ventas.totalVenta" | translate }}</b>
            {{ detalles?.total | currency }}
          </li>
        </ol>
        <h5 class="subtituloModal">{{ "ventas.datosCliente" | translate }}</h5>
        <ol class="pl-3">
          <li class="pt-2">
            <b>{{ "ventas.nombreComprador" | translate }}</b>
            {{ detalles?.nombre | titlecase }}
          </li>
          <li class="pt-2">
            <b>{{ "ventas.email" | translate }}</b
            >{{ detalles?.email }}
          </li>
          <li class="pt-2">
            <b>{{ "formularios.telefono" | translate }}</b
            >{{ detalles?.telefono }}
          </li>
        </ol>
      </div>
      <div class="modal-footer">
        <button
          #closeButton
          type="button"
          class="btn btn-danger"
          data-bs-dismiss="modal"
          (click)="closeModal()"
        >
          Cerrar
        </button>
      </div>
    </div>
  </div>
</div>
<!--Modal guía de envío-->
<div
  class="modal fade"
  id="modalGuia"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl">
    <div class="modal-content" id="modalFile">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Guía de envío</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <iframe
          *ngIf="guiaMostrar"
          class="file-modal"
          [src]="guiaMostrar"
          frameborder=""
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        >
        </iframe>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
          {{ "formularios.cerrar" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<!--Modal rechazar venta-->
<div
  class="modal fade"
  id="modalRejectSale"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Rechazar venta</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <p>
          {{ "ventas.seguroRechazar" | translate }}
        </p>
        <p>
          {{ "ventas.acuerdo" | translate }}
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
          {{ "formularios.cancelar" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-success"
          data-bs-dismiss="modal"
          (click)="recibir()"
        >
          Continuar
        </button>
      </div>
    </div>
  </div>
</div>
<!--Modal aceptar venta-->
<div
  class="modal fade"
  id="modalConfirmSale"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Aceptar venta</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <p>
          {{ "ventas.seguroAceptar" | translate }}
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
          {{ "formularios.cancelar" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-success"
          data-bs-dismiss="modal"
          (click)="recibir()"
        >
          Continuar
        </button>
      </div>
    </div>
  </div>
</div>
