import { Injectable, NgZone } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Select } from "@ngxs/store";
import { Observable, firstValueFrom } from "rxjs";
import { skipWhile, take } from "rxjs/operators";

import { Path } from "../enums/Path";
import {
  AuthenticationState,
  AuthenticationStateModel,
} from "../state/authentication-state";
import { permisos } from "../utils/userPermisos";
import { PermisosService, PermisosType } from "../services/permisos.service";
import { LangTranslateService } from "../services/lang-translate.service";
import { User } from "@angular/fire/auth";
import { Permiso } from "../models/permisosinterface";

@Injectable({
  providedIn: "root",
})
export class AccessGuard {
  @Select(AuthenticationState.user)
  user$!: Observable<AuthenticationStateModel["user"]>;

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private permisosSvc: PermisosService,
    private langService: LangTranslateService
  ) {}

  public navigate(path: string): void {
    this.ngZone.run(() => this.router.navigateByUrl(path)).then();
  }

  isAdmin(roles: any) {
    return Object.keys(roles).includes("administrador");
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    const user: User | null = await firstValueFrom(
      this.user$.pipe(
        skipWhile((val) => !val), // se agrega validación para evitar redireccionamiento de pagina actual al actualizar app estando loggeado
        take(1)
      )
    );

    const roles: PermisosType = await this.permisosSvc.getPermisos(user?.uid);
    const currentUrl: string = state.url;
    let permiteAcceso: boolean = false;

    if (!roles) {
      this.navigate(`${this.langService.lang}/${Path.Home}`);
      return false;
    }

    if (this.isAdmin(roles) && currentUrl.split("/")[2] === "administrador") {
      permiteAcceso = true;
    } else {
      const modulo: Permiso | undefined = permisos.find((p) =>
        currentUrl.includes(p.path)
      );
      if (modulo) {
        for (const r of Object.keys(roles)) {
          try {
            if (roles[r].includes(modulo.name)) {
              permiteAcceso = true;
            }
          } catch (e) {
            permiteAcceso = false;
          }
        }
      }
    }
    if (!permiteAcceso) {
      this.navigate(`${this.langService.lang}/${Path.Home}`);
    }
    return permiteAcceso;
  }
}
