<div class="modal-header">
  <button type="button" class="btn btn-danger" (click)="close()">Cerrar</button>
  <img
    class="img-gif"
    src="../../assets/imagenes/loading_gif.gif"
    alt="cargando"
    *ngIf="loading"
  />
</div>
<div class="modal-body" *ngIf="urlSegura">
  <object
    [data]="urlSegura"
    type="application/pdf"
    height="800px"
    width="100%"
    typemustmatch
  ></object>
</div>
<div *ngIf="!urlSegura && !loading">
  <h3 class="text-center">Url no encontrada</h3>
</div>
