<ul class="nav nav-tabs mt-5" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button
      class="nav-link active"
      id="myDriverServices-tab"
      data-bs-toggle="tab"
      data-bs-target="#myDriverServices"
      type="button"
      role="tab"
      aria-controls="myDriverServices"
      aria-selected="true"
    >
      {{ "transportador.misServicios" | translate }}
    </button>
  </li>
  <li class="nav-item" role="presentation">
    <button
      class="nav-link"
      id="addDriverService-tab"
      data-bs-toggle="tab"
      data-bs-target="#addDriverService"
      type="button"
      role="tab"
      aria-controls="addDriverService"
      aria-selected="false"
    >
      {{ "transportador.crearServicio" | translate }}
    </button>
  </li>
  <li class="nav-item" role="presentation">
    <button
      class="nav-link"
      id="driverServiceRequest-tab"
      data-bs-toggle="tab"
      data-bs-target="#driverServiceRequest"
      type="button"
      role="tab"
      aria-controls="driverServiceRequest"
      aria-selected="false"
    >
      {{ "transportador.solicitudes" | translate }}
    </button>
  </li>
</ul>
<!--Secciones-->
<div class="tab-content" id="myTabContent">
  <!--Sección de consulta y edición de servicios-->
  <div
    class="tab-pane active"
    id="myDriverServices"
    role="tabpanel"
    aria-labelledby="myDriverServices-tab"
  >
    <!--Transportador Forestal-->
    <h4>{{ "transportador.transportadorForestal" | translate }}</h4>
    <p>
      {{ "transportador.aquiPodras" | translate }}
    </p>
    <div class="table-responsive-lg" *ngIf="serviceDriver?.length">
      <table class="table mt-3 text-center">
        <thead class="thead-dark">
          <tr>
            <th scope="col">No</th>
            <th scope="col">{{ "transportador.tipoVehiculo" | translate }}</th>
            <th scope="col">
              {{ "transportador.anosExperiencia" | translate }}
            </th>
            <th scope="col">{{ "transportador.marca" | translate }}</th>
            <th scope="col">{{ "formularios.accion" | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let service of serviceDriver
                | paginate
                  : { itemsPerPage: itemsPerPage, currentPage: currentPage };
              let i = index
            "
          >
            <th scope="row">{{ itemsPerPage * (currentPage - 1) + i + 1 }}</th>
            <td>{{ service.truckType }}</td>
            <td>{{ service.driverYearExpirience }}</td>
            <td>{{ service.truckBrand }}</td>
            <td>
              <button
                (click)="currentServiceId = service.id"
                type="button"
                class="btn btn-danger"
                title="Eliminar producto"
                data-bs-toggle="modal"
                data-bs-target="#modalDeleteTransporterService"
              >
                <img
                  class="img-icon-svg"
                  src="../../../assets/imagenes/trash-alt-solid.svg"
                />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <!--paginado para los resultados-->
      <pagination-controls
        class="my-pagination"
        (pageChange)="currentPage = $event"
        previousLabel="Anterior"
        nextLabel="Siguiente"
      >
      </pagination-controls>
    </div>
    <div *ngIf="!serviceDriver?.length" class="no-servicios">
      <p>{{ "transportador.noServicios" | translate }}</p>
    </div>
  </div>
  <!--Sección de creación de servicio-->
  <div
    class="tab-pane fade"
    id="addDriverService"
    role="tabpanel"
    aria-labelledby="addDriverService-tab"
  >
    <h4>{{ "transportador.creaServicios" | translate }}</h4>
    <p>
      {{ "transportador.aquiPodrasCrear" | translate }}
    </p>
    <!--Formulario para transportadores-->
    <form
      class="mt-3"
      [formGroup]="addDriverServiceForm"
      (ngSubmit)="onCreateDriverService()"
    >
      <div class="work-experience mb-3">
        <h3>{{ "transportador.informacionVehiculo" | translate }}</h3>
        <div class="form-group">
          <label for="truckType"
            >{{ "transportador.tipoVehiculo" | translate }}
          </label>
          <select
            name="truckType"
            class="form-select"
            aria-label="Default select example"
            id="truckType"
            formControlName="truckType"
            [ngClass]="{
              'is-invalid': isInvalidField('truckType'),
              'is-valid': isValidField('truckType')
            }"
            required
          >
            <option value="" selected>
              {{ "transportador.seleccioneTipoVehiculo" | translate }}
            </option>
            <option [value]="tipo" *ngFor="let tipo of tiposVehiculo">
              {{ tipo }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="driverExperience">{{
            "transportador.anosExperiencia" | translate
          }}</label>
          <input
            type="number"
            name="driverExperience"
            class="form-control"
            placeholder="{{ 'transportador.anosExperiencia' | translate }}  "
            id="driverExperience"
            formControlName="driverYearExpirience"
            [ngClass]="{
              'is-invalid': isInvalidField('driverYearExpirience'),
              'is-valid': isValidField('driverYearExpirience')
            }"
            required
          />
        </div>
        <div class="form-group">
          <label for="driverWorkExperience">{{
            "transportador.describaExperiencia" | translate
          }}</label>
          <textarea
            type="text"
            name="driverWorkExperience"
            class="form-control"
            placeholder="{{ 'tecnico.cualEsServicio' | translate }}"
            id="driverWorkExperience"
            formControlName="driverWorkExperience"
            [ngClass]="{
              'is-invalid': isInvalidField('driverWorkExperience'),
              'is-valid': isValidField('driverWorkExperience')
            }"
            required
          ></textarea>
        </div>
        <div class="form-group">
          <label for="truckWeight">{{
            "transportador.peso" | translate
          }}</label>
          <input
            type="number"
            name="truckWeight"
            class="form-control"
            placeholder="Toneladas que puede cargar"
            formControlName="truckWeight"
            id="truckWeight"
            [ngClass]="{
              'is-invalid': isInvalidField('truckWeight'),
              'is-valid': isValidField('truckWeight')
            }"
            required
          />
        </div>
        <div class="form-group">
          <label for="truckVolume">{{
            "transportador.volumen" | translate
          }}</label>
          <input
            type="number"
            name="truckVolume"
            class="form-control"
            placeholder="{{ 'transportador.metrosCubicos' | translate }}"
            formControlName="truckVolume"
            id="truckVolume"
          />
        </div>
        <div class="form-group">
          <label for="truckBrand">{{
            "transportador.marcaVehiculo" | translate
          }}</label>
          <select
            name="truckBrand"
            class="form-select"
            aria-label="Default select example"
            formControlName="truckBrand"
            id="truckBrand"
            [ngClass]="{
              'is-invalid': isInvalidField('truckBrand'),
              'is-valid': isValidField('truckBrand')
            }"
            required
          >
            <option value="" selected>
              {{ "transportador.seleccioneMarca" | translate }}
            </option>
            <option [value]="marca" *ngFor="let marca of marcasVehiculo">
              {{ marca }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="truckYear">{{
            "transportador.modeloVehiculo" | translate
          }}</label>
          <input
            type="number"
            name="truckYear"
            class="form-control"
            placeholder="{{ 'transportador.deQueAnoEs' | translate }}"
            formControlName="truckYear"
            id="truckYear"
            [ngClass]="{
              'is-invalid': isInvalidField('truckYear'),
              'is-valid': isValidField('truckYear')
            }"
            required
          />
        </div>
      </div>
      <div class="text-center mt-3">
        <button
          [disabled]="addDriverServiceForm.invalid"
          type="submit"
          class="btn btn-warning btn-lg"
          data-bs-toggle="modal"
          data-bs-target="#modalCrearServicio"
          [ngClass]="{
            'btn-secondary': addDriverServiceForm.invalid,
            'btn-warning': addDriverServiceForm.valid
          }"
        >
          {{ "transportador.crearServicio" | translate }}
        </button>
      </div>
    </form>
  </div>
  <!--Sección de consulta para servicios solicitados por clientes-->
  <div
    class="tab-pane fade"
    id="driverServiceRequest"
    role="tabpanel"
    aria-labelledby="driverServiceRequest-tab"
  >
    <h4>{{ tipoSolicitudes | translate }}</h4>
    <p>
      {{ "transportador.aquiEncontraras" | translate }}
    </p>
    <div class="table-responsive-lg" *ngIf="solicitudes?.length">
      <table class="table">
        <thead class="thead-dark">
          <tr>
            <th scope="col">{{ "formularios.fecha" | translate }}</th>
            <th scope="col">{{ "formularios.nombre" | translate }}</th>
            <th scope="col">{{ "formularios.departamento" | translate }}</th>
            <th scope="col">{{ "formularios.municipio" | translate }}</th>
            <th scope="col">{{ "transportador.servicio" | translate }}</th>
            <th scope="col">{{ "transportador.estado" | translate }}</th>
            <th scope="col">{{ "transportador.calificacion" | translate }}</th>
          </tr>
        </thead>
        <tbody class="contenido-tabla">
          <tr *ngFor="let svc of solicitudes">
            <th>
              {{
                svc.fechaContratacion
                  | timestampToString: "es-US" : "DD/MM/YYYY"
              }}
            </th>
            <td>
              {{ svc.user.nombres + " " + (svc.user | transformarApellidos) }}
            </td>
            <td>{{ svc.user.departamento }}</td>
            <td>{{ svc.user.municipio }}</td>
            <td>{{ svc.servicioUsuario.nameService | titlecase }}</td>
            <td>{{ svc.estado | titlecase }}</td>
            <td>
              <button
                [disabled]="
                  svc.calificadoContratado || svc.estado !== 'finalizado'
                "
                type="button"
                class="btn btn-info"
                data-bs-toggle="modal"
                data-bs-target="#calificacionModalTransportador"
                (click)="currentServiceId = svc.id; setDatosCalificacion(svc)"
              >
                <img src="../../../assets/imagenes/thumbs-up-solid.svg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="!solicitudes?.length" class="no-servicios">
      <p>{{ "transportador.noSolicitudes" | translate }}</p>
    </div>
  </div>
</div>
<!--modal, ventana emergente para crear servicio-->
<div
  class="modal fade"
  id="modalCrearServicio"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "transportador.servicioCreado" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div
        class="modal-body"
        innerHTML="{{ 'transportador.servicioCreadoMsj' | translate }}"
      ></div>
      <div class="modal-footer">
        <a type="button" class="btn btn-danger" data-bs-dismiss="modal">
          {{ "formularios.cerrar" | translate }}
        </a>
      </div>
    </div>
  </div>
</div>

<!--modal, ventana emergente para eliminar el servicio-->
<div
  class="modal fade"
  id="modalDeleteTransporterService"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "transportador.eliminarServicio" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div
        class="modal-body"
        innerHTML="{{ 'transportador.realmenteEliminar' | translate }}"
      ></div>
      <div class="modal-footer">
        <a type="button" class="btn btn-danger" data-bs-dismiss="modal">
          {{ "formularios.cancelar" | translate }}
        </a>

        <a
          type="button"
          class="btn btn-success"
          data-bs-dismiss="modal"
          (click)="deleteServicio(currentServiceId)"
        >
          {{ "formularios.eliminar" | translate }}
        </a>
      </div>
    </div>
  </div>
</div>

<!--modal, ventana emergente para calificación y comentarios sobre el prestador del servicio-->
<div
  class="modal fade"
  id="calificacionModalTransportador"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <app-calificacion-y-comentarios
          [datosCalificacion]="datosCalificacion"
          [hideComentarios]="true"
          (idCalificacion)="marcarCalificado($event)"
        ></app-calificacion-y-comentarios>
      </div>
      <button
        #cerrarModal
        hidden
        type="button"
        class="close"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
  </div>
</div>
