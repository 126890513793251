<div class="seccion">
  <h2 class="titulo-seccion">
    {{ "responsabilidad-social.titulo" | translate }}
  </h2>
  <p class="parrafo-seccion">
    {{ "responsabilidad-social.aportes" | translate }}
  </p>
  <h3 class="subtitulo-seccion">FREDLearnig</h3>
  <p class="parrafo-seccion">
    {{ "responsabilidad-social.fredLearning" | translate }}
  </p>
  <div class="contenedor-img">
    <img
      src="../../assets/imagenes/EscuelaForestal.webp"
      class="img-fluid text-center"
    />
  </div>
  <h3 class="subtitulo-seccion mt-3">FREDTalks</h3>
  <p class="parrafo-seccion">
    {{ "responsabilidad-social.fredTalks" | translate }}
  </p>
  <div class="contenedor-img">
    <img
      src="../../assets/imagenes/BosquesyComunidades.webp"
      class="img-fluid"
    />
  </div>
  <h3 class="subtitulo-seccion mt-3">FREDLabs</h3>
  <p class="parrafo-seccion">
    {{ "responsabilidad-social.fredLabs" | translate }}
  </p>
  <div class="contenedor-img">
    <img
      src="../../assets/imagenes/InvestigacionBaner.webp"
      class="img-fluid"
    />
  </div>
  <h3 class="subtitulo-seccion mt-3">FREDBank</h3>
  <p class="parrafo-seccion">
    {{ "responsabilidad-social.fredBank" | translate }}
  </p>
  <!--Sección de ODS's-->
  <h3 class="subtitulo-seccion mt-5 text-center">
    {{ "responsabilidad-social.objetivosDesarrollo" | translate }}
  </h3>
  <p class="parrafo-seccion">
    {{ "responsabilidad-social.programas" | translate }}
  </p>

  <div class="row">
    <div class="col-lg-3">
      <img
        src="../../assets/imagenes/ods2.png"
        class="align-self-center mr-2"
        alt="ODS2"
      />
    </div>
    <div class="col-lg-9">
      {{ "responsabilidad-social.hambreCero" | translate }}
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3">
      <img
        src="../../assets/imagenes/ods4.png"
        class="align-self-center mr-2"
        alt="ODS4"
      />
    </div>
    <div class="col-lg-9">
      {{ "responsabilidad-social.educacionCalidad" | translate }}
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3">
      <img
        src="../../assets/imagenes/ods12.png"
        class="align-self-center mr-2"
        alt="ODS12"
      />
    </div>
    <div class="col-lg-9">
      {{ "responsabilidad-social.produccionConsumo" | translate }}
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3">
      <img
        src="../../assets/imagenes/ods13.png"
        class="align-self-center mr-2"
        alt="ODS13"
      />
    </div>
    <div class="col-lg-9">
      {{ "responsabilidad-social.accionClima" | translate }}
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3">
      <img
        src="../../assets/imagenes/ods15.png"
        class="align-self-center mr-2"
        alt="ods15"
      />
    </div>
    <div class="col-lg-9">
      {{ "responsabilidad-social.floraFauna" | translate }}
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3">
      <img
        src="../../assets/imagenes/ods17.png"
        class="align-self-center mr-2"
        alt="ods17"
      />
    </div>
    <div class="col-lg-9">
      {{ "responsabilidad-social.alianzas" | translate }}
    </div>
  </div>

  <div class="contenedor-img">
    <h2 class="subtitulo-seccion">
      {{ "responsabilidad-social.docsFundation" | translate }}
    </h2>
    <button
      type="button"
      class="btn btn-success"
      data-bs-toggle="modal"
      data-bs-target="#modalDocumentFundacion"
    >
      {{ "responsabilidad-social.openBtn" | translate }}
    </button>
  </div>
  <app-btn-registro></app-btn-registro>
</div>
<!--Ventana emergente con documentos de la fundación red forestal-->
<div
  class="modal fade"
  id="modalDocumentFundacion"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "responsabilidad-social.docsFundation" | translate }}
        </h5>
      </div>
      <div class="modal-body">
        <ul>
          <li>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/ecommerce-red-forestal-des-1.appspot.com/o/RedForestal%2Fca%CC%81mara%20de%20comercio.pdf?alt=media&token=f4e6f2b4-fa46-443f-924e-fb03bc1009c5"
              target="_blank"
            >
              Cámara de Comercio
            </a>
          </li>
          <li>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/ecommerce-red-forestal-des-1.appspot.com/o/RedForestal%2F3.%20MODELO%20CERTIFICACION%20ART%20364-3.pdf?alt=media&token=7b10cd79-679f-4dfe-96c4-87eb994b3b36"
              target="_blank"
            >
              Certificación Junta Directiva
            </a>
          </li>
          <li>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/ecommerce-red-forestal-des-1.appspot.com/o/RedForestal%2F3.%20MODELO%20CERTIFICACION%20Numeral%2013%20del%20para%CC%81grafo%202%20del%20arti%CC%81culo%20364-5%20E.T..pdf?alt=media&token=04ec6196-3d59-4aa1-9cc1-bfdbedfe59f3"
              target="_blank"
            >
              Certificación Estatuto Tributario
            </a>
          </li>
          <li>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/ecommerce-red-forestal-des-1.appspot.com/o/RedForestal%2FActa%20Restructuracio%CC%81n.pdf?alt=media&token=2b492f20-2570-4d34-8c1a-3c67421a36b2"
              target="_blank"
            >
              Acta Restructuración
            </a>
          </li>
          <li>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/ecommerce-red-forestal-des-1.appspot.com/o/RedForestal%2FBALANCE%20INICIAL%20(2).pdf?alt=media&token=369ff4f3-27ed-4c01-be76-ae92cf4b17d5"
              target="_blank"
            >
              Balance Inicial
            </a>
          </li>
        </ul>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
          {{ "formularios.cerrar" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
