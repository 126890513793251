<h3 class="titulo-contenido-perfil">
  {{ "crear-producto.productosVendedor" | translate }}
</h3>
<p>
  {{ "crear-producto.aquiPodras" | translate }}
</p>
<div class="table-responsive-lg">
  <table class="table table-hover text-center">
    <thead class="thead-dark">
      <tr>
        <th scope="col">No.</th>
        <th scope="col">{{ "formularios.categoria" | translate }}</th>
        <th scope="col">{{ "formularios.especie" | translate }}</th>
        <th scope="col">{{ "crear-producto.cantidad" | translate }}</th>
        <th scope="col">{{ "crear-producto.precioUnidad" | translate }}</th>
        <th scope="col">{{ "crear-producto.fechaCreacion" | translate }}</th>
        <th scope="col">{{ "formularios.accion" | translate }}</th>
      </tr>
    </thead>
    <tbody class="contenido-tabla">
      <tr
        *ngFor="
          let producto of productosUser
            | paginate
              : { itemsPerPage: itemsPerPage, currentPage: currentPage };
          let i = index
        "
      >
        <th scope="row">{{ itemsPerPage * (currentPage - 1) + i + 1 }}</th>
        <td>{{ producto?.categoria }}</td>
        <td>{{ producto?.especie }}</td>
        <td>{{ producto?.disponibilidadVenta | number }}</td>
        <td>{{ producto?.precio | currency }}</td>
        <td>
          {{ producto.tiempoCreado | timestampToString: "es" : "dd/MM/yyyy" }}
        </td>
        <td>
          <button
            class="btn btn-success"
            type="button"
            title="Editar producto"
            data-bs-toggle="tooltip"
            data-bs-toggle="modal"
            data-bs-target="#modalEditarProducto"
            (click)="getImages(producto)"
          >
            <img
              class="img-icon-svg"
              src="../../../../assets/imagenes/pen-square-solid.svg"
            />
          </button>
          <button
            type="button"
            class="btn btn-danger"
            title="Eliminar producto"
            data-bs-toggle="tooltip"
            title="Eliminar producto"
            data-bs-toggle="modal"
            data-bs-target="#modalEliminarProducto"
            (click)="productoEliminar(producto['id'])"
          >
            <img
              class="img-icon-svg"
              src="../../../../assets/imagenes/trash-alt-solid.svg"
            />
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<!--paginado para los resultados-->
<pagination-controls
  class="my-pagination"
  (pageChange)="currentPage = $event"
  previousLabel="{{ 'paginacion.anterior' | translate }}"
  nextLabel="{{ 'paginacion.siguiente' | translate }}"
>
</pagination-controls>

<!--modal, ventana emergente para eliminar producto-->
<div
  class="modal fade"
  id="modalEliminarProducto"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "crear-producto.estaSeguro" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="productoEliminar()"
        ></button>
      </div>
      <div class="modal-body">
        {{ "crear-producto.realmente" | translate }}
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-danger"
          data-bs-dismiss="modal"
          (click)="productoEliminar()"
          #closebutton
        >
          {{ "formularios.no" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-success"
          (click)="borrarProducto()"
        >
          {{ "formularios.si" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!--Input usado para seleccioón de imagen-->
<input
  #inputFile
  hidden
  type="file"
  accept="image/png, image/jpeg"
  (change)="onFileChanged2($event)"
/>
<!--modal, editar producto-->

<div
  class="modal fade"
  id="modalEditarProducto"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "crear-producto.editarProducto" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="foto-producto-edit col-md-6 border">
            <h4 class="text-center">
              {{ "crear-producto.imagenPrincipal" | translate }}
            </h4>
            <div class="row">
              <div class="col text-center">
                <img
                  class="img-product mb-3"
                  [src]="filePaths['file_0'] || noImage"
                />
              </div>
              <div class="col align-self-center">
                <button class="btn btn-warning" (click)="selectPhoto(0)">
                  {{ "crear-producto.cambiarImagen" | translate }}
                </button>
                <button class="btn btn-danger" (click)="deleteFile(0)">
                  {{ "crear-producto.eliminarImagen" | translate }}
                </button>
              </div>
            </div>
          </div>
          <div class="foto-producto-edit col-md-6 border">
            <h4 class="text-center">
              {{ "crear-producto.imagenDos" | translate }}
            </h4>
            <div class="row">
              <div class="col text-center">
                <img
                  class="img-product mb-3"
                  [src]="filePaths['file_1'] || noImage"
                />
              </div>
              <div class="col align-self-center">
                <button class="btn btn-warning" (click)="selectPhoto(1)">
                  {{ "crear-producto.cambiarImagen" | translate }}
                </button>
                <button class="btn btn-danger" (click)="deleteFile(1)">
                  {{ "crear-producto.eliminarImagen" | translate }}
                </button>
              </div>
            </div>
          </div>
          <div class="foto-producto-edit col-md-6 border">
            <h4 class="text-center">
              {{ "crear-producto.imagenTres" | translate }}
            </h4>
            <div class="row">
              <div class="col text-center">
                <img
                  class="img-product mb-3"
                  [src]="filePaths['file_2'] || noImage"
                />
              </div>
              <div class="col align-self-center">
                <button class="btn btn-warning" (click)="selectPhoto(2)">
                  {{ "crear-producto.cambiarImagen" | translate }}
                </button>
                <button class="btn btn-danger" (click)="deleteFile(2)">
                  {{ "crear-producto.eliminarImagen" | translate }}
                </button>
              </div>
            </div>
          </div>
          <div class="foto-producto-edit col-md-6 border">
            <h4 class="text-center">
              {{ "crear-producto.imagenCuatro" | translate }}
            </h4>
            <div class="row">
              <div class="col text-center">
                <img
                  class="img-product mb-3"
                  [src]="filePaths['file_3'] || noImage"
                />
              </div>
              <div class="col align-self-center">
                <button class="btn btn-warning" (click)="selectPhoto(3)">
                  {{ "crear-producto.cambiarImagen" | translate }}
                </button>
                <button class="btn btn-danger" (click)="deleteFile(3)">
                  {{ "crear-producto.eliminarImagen" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="form-group col-12">
            <label for="otros"> Precio </label>
            <input
              class="form-control"
              placeholder="Precio por Unidad"
              type="number"
              [(ngModel)]="precio"
            />
          </div>
          <div class="form-group col-12">
            <label for="otros">{{
              "crear-producto.disponibilidadVenta" | translate
            }}</label>
            <input
              class="form-control"
              placeholder="cantidad disponible"
              type="number"
              [(ngModel)]="disponibilidadVenta"
            />
          </div>
          <div class="form-group col-12">
            <label for="otros">{{ "crear-producto.otrosDatos" | translate }}</label>
            <textarea
              class="form-control"
              placeholder="{{ 'crear-producto.porSuSeguridad' | translate }}"
              name="datosInteres"
              rows="3"
              id="otros"
              [(ngModel)]="datosInteres"
            ></textarea>
          </div>
          <div class="col-12 text-center mt-3">
            <button type="button" class="btn btn-success" (click)="guardar()">
              {{ "crear-producto.guardar" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
