import { Injectable, OnDestroy } from "@angular/core";
import { CrudService } from "src/app/core/service/crud.service";
import { NEW_WT_REPORT, WtReport } from "../model/wt-report";
import { WtReportState } from "../model/wt-report.state";
import { WtReportFirestoreService } from "./wt-report-firestore.service";
import { WtReportStore } from "./wt-report.store";

@Injectable({
  providedIn: "root",
})
export class WtReportService
  extends CrudService<WtReport, WtReportState>
  implements OnDestroy
{
  constructor(
    protected override firestore: WtReportFirestoreService,
    protected override store: WtReportStore
  ) {
    super(firestore, store);
  }

  public init(): void {
    this.firestore.basePath = "wt_reports";
    this.initCollection();
  }

  public emptyDocument(): WtReport {
    return NEW_WT_REPORT;
  }
}
