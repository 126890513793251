<form [formGroup]="suscripcionForm">
  <div class="row">
    <div class="form-group col-2">
      <label for="exampleFormControlSelect1">Meses</label>
      <select
        class="form-select"
        aria-label="Default select example"
        formControlName="duracion"
      >
        <option *ngFor="let item of [1, 3, 6, 12]" [value]="item">
          {{ item }}
        </option>
      </select>
    </div>
    <div class="form-group col-4">
      <label for="exampleFormControlInput1">Fecha Inicio</label>
      <input
        class="form-control"
        type="date"
        placeholder="Fecha Inicio"
        formControlName="fechaInicio"
      />
    </div>
    <div class="form-group col-4">
      <label for="exampleFormControlInput1">Fecha Final</label>
      <input
        class="form-control"
        readOnly
        type="date"
        placeholder="Fecha Final"
        formControlName="fechaFin"
      />
    </div>
    <div class="form-group col-sm-2 d-flex justify-content-end flex-column">
      <button
        class="btn btn-primary"
        (click)="agregarSuscripcion()"
        [disabled]="suscripcionForm.invalid"
      >
        Agregar
      </button>
    </div>
  </div>
</form>
<hr />
<table class="table text-center">
  <thead>
    <tr>
      <th scope="col">Meses</th>
      <th scope="col">Fecha inicio</th>
      <th scope="col">Fecha Fin</th>
      <th scope="col">Acciones</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let suscripcion of suscripciones">
      <th class="align-middle" scope="row">{{ suscripcion?.duracion }}</th>
      <td class="align-middle">{{ formatDate(suscripcion?.fechaInicio) }}</td>
      <td class="align-middle">{{ formatDate(suscripcion?.fechaFin) }}</td>
      <td class="align-middle">
        <button
          class="btn btn-danger"
          (click)="removerSuscripcion(suscripcion)"
        >
          <img
            class="svg-icon"
            src="../../../assets/imagenes/minus-circle-solid.svg"
          />
        </button>
      </td>
    </tr>
  </tbody>
</table>
