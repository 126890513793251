<div class="form-group row form-categorias">
  <div class="col-9">
    <select
      class="form-select"
      aria-label="Default select example"
      #selectEntidad
    >
      <option value="">Seleccione una Entidad</option>
      <option
        *ngFor="let entidadOpcion of entidadesOpciones"
        [value]="entidadOpcion"
      >
        {{ entidadOpcion }}
      </option>
    </select>
  </div>
  <div class="col-sm-3 text-center">
    <button
      type="button"
      class="btn btn-success mb-3"
      (click)="add(selectEntidad.value)"
    >
      Agregar
    </button>
  </div>
</div>
<h4>Entidades del usuario</h4>
<table class="table text-center">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Entidad</th>
      <th scope="col">Eliminar</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let entidad of entidadesForm?.controls; let i = index">
      <th scope="row">
        {{ i + 1 }}
      </th>
      <td>
        {{ entidad.value }}
      </td>
      <td>
        <button class="btn btn-danger" (click)="remove(entidad.value)">
          <img
            class="svg-icon"
            src="../../../assets/imagenes/minus-circle-solid.svg"
          />
        </button>
      </td>
    </tr>
  </tbody>
</table>
