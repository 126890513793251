export const categoriasLista: string[] = [
  "Bosque",
  "Árbol en pie",
  "Madera rolliza",
  "Madera aserrada",
  "No maderable",
  "Vivero",
  "Vivero ornamental",
  "Vivero frutal",
  "Equipo y Maquinaria",
  "Insumos",
  "Bonos de carbono",
  "Producto terminado",
  "Tierras para proyectos",
];
