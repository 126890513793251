<h3 class="subtitulo-texto mt-5">Creación de Aliados</h3>
<ul class="nav nav-tabs mt-5" id="myTab" role="tablist">
  <li class="nav-item" role="nuevo">
    <button
      class="nav-link active"
      id="nuevo-tab"
      data-bs-toggle="tab"
      data-bs-target="#nuevo"
      type="button"
      role="tab"
      aria-controls="nuevo"
      aria-selected="true"
    >
      Nuevo
    </button>
  </li>
  <li class="nav-item" role="antiguo">
    <button
      class="nav-link"
      id="antiguo-tab"
      data-bs-toggle="tab"
      data-bs-target="#antiguo"
      type="button"
      role="tab"
      aria-controls="antiguo"
      aria-selected="false"
    >
      Antiguo
    </button>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <div
    class="tab-pane fade show active"
    id="nuevo"
    role="tabpanel"
    aria-labelledby="nuevo-tab"
  >
    <div class="border">
      <h4 class="text-center">Creación de Aliado</h4>
      <div class="row mb-3">
        <div class="col-sm-4 text-center">
          <img
            [src]="
              forms['formCrear'].get('imagen')?.value ||
              '../../assets/imagenes/espacioPublicitario.webp'
            "
            class="imagen-aliado mx-auto d-block"
          />
          <button
            class="btn btn-warning mt-2"
            (click)="fileInput.click(); currentForm = 'formCrear'"
          >
            Elegir Imagen
          </button>
        </div>
        <input
          type="file"
          multiple="false"
          hidden
          #fileInput
          (change)="seleccionarFoto($event)"
          accept="image/x-png,image/gif,image/jpeg"
        />
        <div class="col-sm-8">
          <form [formGroup]="forms['formCrear']" class="pr-3">
            <div class="mb-3">
              <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label"
                  >Nombre del Aliado</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Nombre del aliado"
                  formControlName="nombre"
                />
              </div>
              <label for="exampleFormControlInput1" class="form-label"
                >Descripción de la tarjeta</label
              >
              <textarea
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="Descripción de máximo 25 palabras"
                formControlName="descripcion"
              ></textarea>
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label"
                >Enlace de la Empresa</label
              >
              <input
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="enlace"
                formControlName="enlace"
              />
            </div>
            <div>
              <label for="formFile" class="form-label">Tipo de Aliado</label>
              <select
                class="form-select"
                aria-label="Default select example"
                formControlName="tipo"
              >
                <option selected>Seleccionar Tipo Aliado</option>
                <option value="1">Educación</option>
                <option value="2">Gobierno</option>
                <option value="3">Emprendimiento</option>
                <option value="4">Financiero</option>
              </select>
            </div>

            <button
              type="submit"
              class="btn btn-warning mt-3"
              (click)="crear()"
              [disabled]="!forms['formCrear'].valid"
            >
              Crear
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div
    class="tab-pane fade"
    id="antiguo"
    role="tabpanel"
    aria-labelledby="antiguo-tab"
  >
    <div class="table-responsive">
      <table
        class="table table-striped mt-3 text-center"
        *ngIf="listaAliados?.length"
      >
        <thead>
          <tr>
            <th scope="col">Foto</th>
            <th scope="col">Descripción</th>
            <th scope="col">Enlace</th>
            <th scope="col">Tipo</th>
            <th scope="col">Acción</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let aliado of listaAliados">
            <th scope="row">
              <img
                [src]="
                  aliado?.imagen ||
                  '../../assets/imagenes/espacioPublicitario.webp'
                "
                class="imagen-aliado-tabla"
              />
            </th>
            <td>
              {{ aliado.descripcion }}
            </td>
            <td>
              <a [href]="aliado.enlace" target="_blank">
                {{ aliado.enlace }}
              </a>
            </td>
            <td>
              {{ aliado.tipo === "1" ? "Nacional" : "Internacional" }}
            </td>
            <td class="mx-auto d-block">
              <button
                class="m-1 btn btn-warning"
                title="Editar"
                type="button"
                (click)="openEdit(aliado)"
              >
                <img
                  class="svg-icon"
                  src="../../../assets/imagenes/pencil.svg"
                />
              </button>
              <button
                class="m-1 btn btn-danger"
                title="Eliminar"
                (click)="openDeleteModal(aliado)"
              >
                <img
                  class="svg-icon"
                  src="../../../assets/imagenes/trash-alt-solid.svg"
                />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="!listaAliados?.length">
        <h3 class="text-center mt-3">No hay Aliados disponibles</h3>
      </div>
    </div>
  </div>
</div>
<button
  #btnModal
  data-bs-toggle="modal"
  data-bs-target="#modalEditarAliado"
  hidden
></button>

<button
  #btnModalBorrar
  data-bs-toggle="modal"
  data-bs-target="#ModalBorrar"
  hidden
></button>

<!--modal, ventana emergente editar el Aliado-->
<div
  class="modal fade"
  id="modalEditarAliado"
  tabindex="-1"
  data-bs-backdrop="static"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Editar Aliado</h5>
        <button
          #btnCerrarModal
          type="button"
          class="close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img
          [src]="
            forms['formModal'].get('imagen')?.value ||
            '../../assets/imagenes/espacioPublicitario.webp'
          "
          class="imagen-aliado mx-auto d-block"
        />
        <div class="text-center">
          <button
            class="btn btn-warning mt-2"
            (click)="fileInput.click(); currentForm = 'formModal'"
          >
            Cambiar imagen
          </button>
        </div>
        <form class="pr-3" [formGroup]="forms['formModal']">
          <div class="mb-3">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label"
                >Nombre del Aliado</label
              >
              <input
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="Nombre del aliado"
                formControlName="nombre"
              />
            </div>
            <label for="exampleFormControlInput1" class="form-label"
              >Descripción de la tarjeta</label
            >
            <textarea
              type="text"
              class="form-control"
              id="exampleFormControlInput1"
              placeholder="Descripción de máximo 25 palabras"
              formControlName="descripcion"
            ></textarea>
          </div>
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label"
              >Enlace de la Empresa</label
            >
            <input
              type="text"
              class="form-control"
              id="exampleFormControlInput1"
              placeholder="enlace"
              formControlName="enlace"
            />
          </div>
          <div>
            <label for="formFile" class="form-label">Tipo de Aliado</label>
            <select
              class="form-select"
              aria-label="Default select example"
              formControlName="tipo"
            >
              <option value="1">Educación</option>
              <option value="2">Gobierno</option>
              <option value="3">Emprendimiento</option>
              <option value="4">Financiero</option>
            </select>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
          Cancelar
        </button>
        <button
          type="button"
          class="btn btn-success"
          (click)="actualizar()"
          [disabled]="!forms['formModal'].valid"
        >
          Continuar
        </button>
      </div>
    </div>
  </div>
</div>

<!--modal borrar-->
<div
  class="modal fade"
  id="ModalBorrar"
  tabindex="-1"
  data-bs-backdrop="static"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Borrar Aliado</h5>
      </div>
      <div class="modal-body">
        <h5 class="text-center">Estás seguro de borrar el aliado?</h5>
      </div>
      <div class="modal-footer">
        <button
          #cerrarModalBorrar
          type="button"
          class="btn btn-danger"
          data-bs-dismiss="modal"
        >
          Cancelar
        </button>
        <button type="button" class="btn btn-success" (click)="delete()">
          Continuar
        </button>
      </div>
    </div>
  </div>
</div>
