<div class="seccion">
  <h2 class="titulo-seccion mb-5">
    {{ "categorias.categoriasProductos" | translate }}
  </h2>

  <div class="row">
    <div class="col-lg-3">
      <img
        src="assets/imagenes/categoria_vivero.webp"
        class="img-body"
        alt="Imagen de la categoría vivero"
      />
    </div>
    <div class="col-lg-9">
      <h5>{{ "categorias.vivero" | translate }}</h5>
      <p>
        {{ "categorias.viveroP1" | translate }}
      </p>
      <p>{{ "categorias.viveroP2" | translate }}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3">
      <img
        src="../../assets/imagenes/categoría_Bosque.webp"
        class="img-body"
        alt="Imagen de la categoría bosque"
      />
    </div>
    <div class="col-lg-9">
      <h5>{{ "categorias.bosque" | translate }}</h5>
      <p>
        {{ "categorias.bosqueP1" | translate }}
      </p>
      <p>{{ "categorias.bosqueP2" | translate }}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3">
      <img
        src="assets/imagenes/categoría_árbol_en_pie.webp"
        class="img-body"
        alt="Imagen de la categoría árbol en pie"
      />
    </div>
    <div class="col-lg-9">
      <h5>{{ "categorias.arbolPie" | translate }}</h5>
      <p>
        {{ "categorias.arbolPieP1" | translate }}
      </p>
      <p>{{ "categorias.arbolPieP2" | translate }}</p>
      <p>{{ "categorias.arbolPieP3" | translate }}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3">
      <img
        src="../../assets/imagenes/categoría_Madera_Rolliza.webp"
        class="img-body"
        alt="Imagen de la categoría madera rolliza"
      />
    </div>
    <div class="col-lg-9">
      <h5>{{ "categorias.maderaRolliza" | translate }}</h5>
      <p>
        {{ "categorias.maderaRollizaP1" | translate }}
      </p>
      <p>
        {{ "categorias.maderaRollizaP2" | translate }}
      </p>
      <p>
        {{ "categorias.maderaRollizaP3" | translate }}
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3">
      <img
        src="assets/imagenes/categoría_Madera_Aserrada.webp"
        class="img-body"
        alt="Imagen de la categoría madera aserrada"
      />
    </div>
    <div class="col-lg-9">
      <h5>{{ "categorias.maderaAserrada" | translate }}</h5>
      <p>
        {{ "categorias.maderaAserradaP1" | translate }}
      </p>
      <p>
        {{ "categorias.maderaAserradaP2" | translate }}
      </p>
      <p>
        {{ "categorias.maderaAserradaP3" | translate }}
      </p>
      <p>
        {{ "categorias.maderaAserradaP4" | translate }}
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3">
      <img
        src="../../assets/imagenes/categoría_PFNM.webp"
        class="img-body"
        alt="Imagen de la categoría PFNM"
      />
    </div>
    <div class="col-lg-9">
      <h5>
        {{ "categorias.productosForestales" | translate }}
      </h5>
      <p>
        {{ "categorias.productosForestalesP1" | translate }}
      </p>
      <p>
        {{ "categorias.productosForestalesP2" | translate }}
      </p>
      <p>
        {{ "categorias.productosForestalesP3" | translate }}
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3">
      <img
        src="assets/imagenes/vivero_ornamental.webp"
        class="img-body"
        alt="Imagen de la categoría vivero ornamental"
      />
    </div>
    <div class="col-lg-9">
      <h5>{{ "index.viveroOrnamental" | translate }}</h5>
      <p>
        {{ "categorias.viveroOrnamentalP1" | translate }}
      </p>
      <p>
        {{ "categorias.viveroP2" | translate }}
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3">
      <img
        src="../../assets/imagenes/vivero_frutal.webp"
        class="img-body"
        alt="Imagen de la categoría vivero frutal"
      />
    </div>
    <div class="col-lg-9">
      <h5>{{ "index.viveroFrutal" | translate }}</h5>
      <p>
        {{ "categorias.viveroFrutalP1" | translate }}
      </p>
      <p>
        {{ "categorias.viveroFrutalP2" | translate }}
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3">
      <img
        src="assets/imagenes/producto_transformado.webp"
        class="img-body"
        alt="Imagen de la categoría producto transformado"
      />
    </div>
    <div class="col-lg-9">
      <h5>{{ "index.productoTerminado" | translate }}</h5>
      <p>
        {{ "categorias.productoTerminadop1" | translate }}
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3">
      <img
        src="../../assets/imagenes/maquinas_forestales.webp"
        class="img-body"
        alt="Imagen de la categoría equipos y máquinas"
      />
    </div>
    <div class="col-lg-9">
      <h5>{{ "index.equipoMaquinaria" | translate }}</h5>
      <p>
        {{ "categorias.equiposMaquinariaP1" | translate }}
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3">
      <img
        src="assets/imagenes/isumos_category.webp"
        class="img-body"
        alt="Imagen de la categoría insumos"
      />
    </div>
    <div class="col-lg-9">
      <h5 class="mt-0 mb-1">{{ "index.insumos" | translate }}</h5>
      <p>
        {{ "categorias.insumosP1" | translate }}
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3">
      <img
        src="../../assets/imagenes/agricultura.webp"
        class="img-body"
        alt="Imagen de la categoría productos agrícolas"
      />
    </div>
    <div class="col-lg-9">
      <h5>{{ "index.productoAgricola" | translate }}</h5>
      <p>
        {{ "categorias.productosAgricolasP1" | translate }}
      </p>
    </div>
  </div>
  <app-btn-registro></app-btn-registro>
</div>
