<nav class="navbar navbar-expand-lg fixed-top">
  <div class="container-fluid">
    <a class="navbar-brand">
      <img
        class="logo"
        src="../../assets/imagenes/Logo2021.svg"
        [routerLink]="[path.Home] | langRoute | async"
      />
    </a>
    <button
      type="button"
      id="btnLenguaje1"
      class="btn btn-outline-warning language-indicator"
      (click)="changeLanguage()"
    >
      {{ localize.parser.currentLang === "es" ? "English" : "Español" }}
      <img class="icon-svg" src="../../assets/imagenes/globe-solid.svg" />
    </button>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span>Menú</span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul
        class="navbar-nav me-auto mb-2 mb-lg-0"
        *ngIf="user && userIsActive; else login"
      >
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ "nav-bar.bienvenido" | translate }}
          </a>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item"
                data-bs-target="#navbarSupportedContent"
                data-bs-toggle="collapse"
                (click)="seleccionPerfil()"
              >
                {{ "nav-bar.miPerfil" | translate }}
              </a>
            </li>
            <li>
              <hr class="dropdown-divider" />
            </li>
            <li>
              <a
                class="dropdown-item"
                data-bs-target="#navbarSupportedContent"
                data-bs-toggle="collapse"
                [routerLink]="[path.Home] | langRoute | async"
                (click)="onLogout()"
              >
                {{ "nav-bar.cerrarSesion" | translate }}
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            [routerLink]="[path.CarroCompras] | langRoute | async"
            routerLinkActive="active"
            data-bs-target="#navbarSupportedContent"
            data-bs-toggle="collapse"
          >
            <img
              class="icon-svg"
              src="../../assets/imagenes/shopping-cart-solid-white.svg"
              alt="carro"
            />
          </a>
        </li>
      </ul>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a
            class="nav-link"
            data-bs-target="#navbarSupportedContent"
            data-bs-toggle="collapse"
            [routerLink]="[path.Home] | langRoute | async"
            routerLinkActive="active"
          >
            {{ "nav-bar.inicio" | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            [routerLink]="[path.Empresa] | langRoute | async"
            routerLinkActive="active"
            data-bs-target="#navbarSupportedContent"
            data-bs-toggle="collapse"
          >
            {{ "nav-bar.nosotros" | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            [routerLink]="[path.Proyecto] | langRoute | async"
            routerLinkActive="active"
            data-bs-target="#navbarSupportedContent"
            data-bs-toggle="collapse"
          >
            {{ "nav-bar.suscripcion" | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            [routerLink]="[path.ResponsabilidadSocial] | langRoute | async"
            routerLinkActive="active"
            data-bs-target="#navbarSupportedContent"
            data-bs-toggle="collapse"
          >
            {{ "nav-bar.social" | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            [routerLink]="[path.Certificacion] | langRoute | async"
            routerLinkActive="active"
            data-bs-target="#navbarSupportedContent"
            data-bs-toggle="collapse"
          >
            {{ "nav-bar.elSello" | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            [routerLink]="[path.Noticias] | langRoute | async"
            routerLinkActive="active"
            data-bs-target="#navbarSupportedContent"
            data-bs-toggle="collapse"
          >
            {{ "nav-bar.noticias" | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            [routerLink]="[path.Alianzas] | langRoute | async"
            routerLinkActive="active"
            data-bs-target="#navbarSupportedContent"
            data-bs-toggle="collapse"
          >
            {{ "nav-bar.aliados" | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            [routerLink]="[path.Validador] | langRoute | async"
            routerLinkActive="active"
            data-bs-target="#navbarSupportedContent"
            data-bs-toggle="collapse"
          >
            {{ "nav-bar.certificados" | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            [routerLink]="[path.Contactenos] | langRoute | async"
            routerLinkActive="active"
            data-bs-target="#navbarSupportedContent"
            data-bs-toggle="collapse"
          >
            {{ "nav-bar.contactenos" | translate }}
          </a>
        </li>
      </ul>
      <ng-template #login>
        <ul class="navbar-nav me-auto">
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="[path.Auth, path.Login] | langRoute | async"
              routerLinkActive="active"
              data-bs-target="#navbarSupportedContent"
              data-bs-toggle="collapse"
            >
              <b>{{ "nav-bar.ingresar" | translate }}</b>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="
                [path.Auth, path.SeleccionDeRegistro] | langRoute | async
              "
              routerLinkActive="active"
              data-bs-target="#navbarSupportedContent"
              data-bs-toggle="collapse"
            >
              <b>{{ "nav-bar.registrate" | translate }}</b>
            </a>
          </li>
        </ul>
      </ng-template>
    </div>
    <button
      type="button"
      id="btnLenguaje2"
      class="btn btn-outline-warning language-indicator"
      (click)="changeLanguage()"
    >
      {{ localize.parser.currentLang === "es" ? "English" : "Español" }}
      <img class="icon-svg" src="../../assets/imagenes/globe-solid.svg" />
    </button>
  </div>
</nav>
