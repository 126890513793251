import { Component } from "@angular/core";
import { Path } from "../enums/Path";

@Component({
  selector: "app-proyecto",
  templateUrl: "./proyecto.component.html",
  styleUrls: ["./proyecto.component.css"],
})
export class ProyectoComponent {
  public path: typeof Path = Path;

  constructor() {}
}
