import { paises } from "src/assets/data/paises";
import { CrearProductoComponent } from "../crear-producto.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { take, takeUntil, tap } from "rxjs/operators";
import { Subject } from "rxjs";
import { comparador } from "src/app/validator/comparador-campos.validator";
import { precioValidator } from "src/app/validator/precio.validator";
import { AuthService } from "src/app/services/auth.service";
import { UserData, UserService } from "src/app/services/user.service";
import { BaseFormService } from "src/app/services/base-form.service";
import { User } from "@angular/fire/auth";

@Component({
  selector: "app-base-form",
  template: `<div></div>`,
})
export class BaseFormComponent implements OnInit, OnDestroy {
  @Input() visualizar!: boolean;
  private unsubscriber: Subject<void> = new Subject();
  public paises: any = paises;
  public listaPaises: string[] = Object.keys(this.paises);
  public listaDepartamentos: string[] = Object.keys(this.paises.Colombia);
  public listaMunicipios: string[] = [];
  public especiesHabilitadas!: string[];
  public loading: boolean = false;
  public categoryUser: string[] = [];
  productoForm!: FormGroup;

  constructor(
    public crearProductoComponent: CrearProductoComponent,
    public authService: AuthService,
    public userService: UserService,
    public formBuilder: FormBuilder,
    public baseFormService: BaseFormService
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    console.log("Starting");
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  get categoriaSeleccionada(): string | undefined {
    return this.productoForm.get("categoria")?.value;
  }

  initForm(): void {
    this.productoForm = this.getNewForm() as FormGroup;
    this.productoForm.valueChanges
      .pipe(
        takeUntil(this.unsubscriber),
        take(1),
        tap({
          next: () => {
            this.baseFormService.currentForm.next(this.productoForm);
          },
          error: (err: Error) => {
            console.error(err);
          },
        })
      )
      .subscribe();

    this.traerEspeciesHabilitadas();
  }

  isInvalidField(field: string): boolean {
    try {
      return (
        ((this.productoForm.get(field)?.touched ||
          this.productoForm.get(field)?.dirty) &&
          !this.productoForm.get(field)?.valid) ||
        false
      );
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  isValidField(field: string): boolean {
    try {
      return (
        (this.productoForm.get(field)?.dirty &&
          this.productoForm.get(field)?.valid) ||
        false
      );
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  onSelectPais(pais: string): void {
    this.productoForm.get("municipio")?.setValue("");
    this.productoForm.get("departamento")?.setValue("");
    if (this.paises[pais]) {
      this.listaDepartamentos = Object.keys(this.paises[pais]);
    }
  }

  selectDepartamento(selectDep: string): void {
    const pais: string = this.productoForm.get("pais")?.value;
    this.listaMunicipios = this.paises[pais]
      ? this.paises[pais][selectDep]
      : [];
  }

  updateAllLocations(): void {
    const pais: string = this.productoForm.get("pais")?.value;
    const departamento: string = this.productoForm.get("departamento")?.value;
    if (this.paises[pais]) {
      this.listaDepartamentos = Object.keys(this.paises[pais]);
      this.listaMunicipios =
        this.paises[pais] && this.paises[pais][departamento]
          ? this.paises[pais][departamento]
          : [];
    }
  }

  async traerEspeciesHabilitadas(): Promise<void> {
    const currentUser: User | null = this.authService.getCurrentUser();
    try {
      if (currentUser) {
        const currentUserData: UserData = await this.userService.getUserData(
          currentUser.uid
        );
        this.especiesHabilitadas = currentUserData.especiesHabilitadas;
        this.categoryUser = currentUserData.categoryProductUser;
      }
    } catch (error) {
      console.error(error);
    }
  }

  getNewForm(): FormGroup {
    return this.formBuilder.group(
      {
        categoria: ["", Validators.required],
        pais: ["", Validators.required],
        departamento: ["", Validators.required],
        municipio: ["", Validators.required],
        especie: ["", Validators.required],
        altura: ["", [Validators.required, Validators.min(1)]],
        grosor: ["", [Validators.required, Validators.min(1)]],
        alturaBolsa: ["", [Validators.required, Validators.min(1)]],
        calibreBolsa: ["", [Validators.required, Validators.min(1)]],
        disponibilidadVenta: ["", [Validators.required, Validators.min(1)]],
        sistema: ["", Validators.required],
        precio: ["", [Validators.required]],
        cantidadMinimaVenta: ["", [Validators.required, Validators.min(1)]],
        datosInteres: [""],
        eliminado: [false],
        unidadMedida: ["", Validators.required],
        moneda: ["", Validators.required],
      },
      {
        validators: [
          comparador("disponibilidadVenta", ">=", "cantidadMinimaVenta"),
          precioValidator,
        ],
      }
    );
  }

  preventNonNumericalInput(input: HTMLInputElement) {
    input.value = input.value.replace(/\D+/g, "");
  }
}
