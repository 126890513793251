<div class="seccion">
  <h2 class="titulo-seccion">{{ "contactenos.contactenos" | translate }}</h2>
  <p class="parrafo-seccion">
    {{ "contactenos.tenemos" | translate }}
  </p>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-7">
        <div class="map-responsive">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d380.0149455790719!2d-74.07437486893043!3d4.646510256395436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9a357ee5fa5f%3A0x3296c5e4f9e07fcc!2sTv.%2027%20%2357-84%2C%20Bogot%C3%A1!5e0!3m2!1ses!2sco!4v1711806033965!5m2!1ses!2sco"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <div class="col-sm-5">
        <div class="row">
          <div class="col-sm">
            <img
              class="iconos-contacto"
              src="assets/imagenes/Ubicacion.png"
              alt="Ubicacion"
              col-md-4
            /><br />
            <p>Bogotá (Colombia)</p>
            <img
              class="iconos-contacto"
              src="assets/imagenes/llamada.png"
              alt="Telefono"
              col-md-10
            /><br />
            <p>(57) 3165338564</p>
            <img
              class="iconos-contacto"
              src="assets/imagenes/mensajes.png"
              alt="correo electrónico"
              col-md-10
            /><br />
            <p>info&#64;redforestal.com</p>
            <img
              class="iconos-contacto"
              src="assets/imagenes/whatsapp.png"
              alt="whastApp"
              col-md-10
            /><br />
            <p>
              <a href="https://wa.me/message/CAIW6PZHU5ZPP1" target="_blank"
                >3165338564</a
              >
            </p>
          </div>
          <div class="col-sm">
            <img
              class="iconos-contacto"
              src="assets/imagenes/facebook.png"
              alt="Facebook"
              col-md-10
            /><br />
            <p>
              <a href="https://www.facebook.com/red.forestal1" target="_blank"
                >&#64;red.forestal1</a
              >
            </p>
            <img
              class="iconos-contacto"
              src="assets/imagenes/instagram.png"
              alt="Instagram"
              col-md-10
            /><br />
            <p>
              <a href="https://www.instagram.com/red_forestal/" target="_blank"
                >&#64;red_forestal</a
              >
            </p>
            <img
              class="iconos-contacto"
              src="assets/imagenes/youtube.png"
              alt="youtube"
              col-md-10
            /><br />
            <p>
              <a
                href="https://www.youtube.com/channel/UCIC6Ww7UFkFwHUZoQ-qk5nw"
                target="_blank"
                >RED FORESTAL</a
              >
            </p>
            <img
              class="iconos-contacto"
              src="assets/imagenes/twitter.png"
              alt="twitter"
              col-md-10
            /><br />
            <p>
              <a href="https://twitter.com/redforestal1" target="_blank"
                >&#64;redforestal1</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <app-pqr-comentarios class="titulo-pqr"></app-pqr-comentarios>
  </div>
  <app-btn-registro></app-btn-registro>
</div>
