import { TimeStamp } from "src/app/core/types/time-stamp.type";

/**
 * Describe la estructura un usuario de la aplicación.
 * Esta interfaz es más precisa que User y debería de usarse en todo el código.
 *
 * @todo @diana terminar de documentar campos.
 */
export interface WtUser {
  id: string;
  email: string;
  /**
   * Solo para uso local. Cuando se envía al servidor, se elimina.
   */
  password?: string;
  fullName: string;
  docType: number;
  docNumber: string;
  emailVerified: boolean;
  genero: string;
  fNacimiento: TimeStamp | null;
  movil: string;
  /**
   * Dispositivos asociados al usuario.
   */
  devices: Array<{
    model: string;
    uuid: string;
  }>;
  /**
   * String with profile picture. In Firestore is stored the download URL. In localstorage is
   * stored as/converted to a base64 data url.
   */
  photo: string;
  activo: boolean;
  firstReport: boolean;
}

/** Empty WtUser. */
export const NEW_WT_USER: WtUser = {
  id: "0",
  email: "",
  password: "",
  fullName: "",
  docType: 0,
  docNumber: "",
  emailVerified: false,
  genero: "",
  fNacimiento: null,
  movil: "",
  devices: [],
  photo: "",
  activo: false,
  firstReport: false,
};
