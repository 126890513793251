import { Component, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-responsabilidad-social",
  templateUrl: "./responsabilidad-social.component.html",
  styleUrls: ["./responsabilidad-social.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class ResponsabilidadSocialComponent {
  constructor() {}
}
