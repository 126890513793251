import { Injectable } from "@angular/core";
import { Revista } from "../models/Revista";
import { StorageService } from "./storage.service";
import { catchError, map, switchMap } from "rxjs/operators";
import { forkJoin, Observable, of, Subject } from "rxjs";
import { FirebaseRequestsService } from "./firebase-requests.service";
import { DocumentData, orderBy } from "@angular/fire/firestore";
import { UploadTaskSnapshot } from "firebase/storage";
import { ResponseCurrentUpload } from "../models/storage.interface";

@Injectable({
  providedIn: "root",
})
export class RevistasService {
  deleteRevista$: Subject<boolean> = new Subject();

  constructor(
    private firebaseRequest: FirebaseRequestsService,
    private storageService: StorageService
  ) {}

  // función para crear revistas
  // data: objeto con datos de revista
  async create(data: Revista): Promise<void> {
    delete data.imagen;
    delete data.pdf;
    const id: string | undefined = data.id;
    delete data.id;
    try {
      await this.firebaseRequest.addMDocFirebaseWithCustomId(
        `revistas/${id}`,
        data
      );
    } catch (e) {
      console.error(e);
      throw new Error("error creating Revista");
    }
  }

  uploadCreate(data: Revista): Revista {
    const { imagen, pdf } = data;
    const customId: string = this.firebaseRequest.createId();
    data.id = customId;
    data.savingImage =
      imagen && imagen instanceof File
        ? this.saveFile(customId, imagen, "imagen").currentUploads.pipe(
            map(
              (result: { progress: number; snapshot: UploadTaskSnapshot }) =>
                result.progress
            )
          )
        : of(100);

    data.savingPdf =
      pdf && pdf instanceof File
        ? this.saveFile(customId, pdf, "pdf").currentUploads.pipe(
            map(
              (result: { progress: number; snapshot: UploadTaskSnapshot }) =>
                result.progress
            )
          )
        : of(100);

    return data;
  }

  // función para actualizar revistas
  // data: objeto con datos de revista
  // objectId: identificador de revista
  async updateFiles(objectId: string, data: Revista): Promise<Revista> {
    const { imagen, pdf, ...rest } = data;
    const response: Revista = {
      ...rest,
    };
    try {
      if (imagen) {
        await this.storageService.deleteFile(`revistas/imagen/${objectId}`);
        response.savingImage =
          imagen && imagen instanceof File
            ? this.generateSavingFile(objectId, "imagen", imagen)
            : of(100);
      } else {
        response.savingImage = of(100);
      }

      if (pdf) {
        await this.storageService.deleteFile(`revistas/pdf/${objectId}`);
        response.savingPdf =
          pdf && pdf instanceof File
            ? this.generateSavingFile(objectId, "pdf", pdf)
            : of(100);
      } else {
        response.savingPdf = of(100);
      }

      return response;
    } catch (e) {
      console.error(e);
      throw new Error("error updating Data");
    }
  }

  async updateData(objectId: string, data: Revista): Promise<void> {
    await this.firebaseRequest.updateDocFirebase(`revistas/${objectId}`, data);
  }

  // función para almacenar archivos realacionados a una revista
  // id : id de revista
  // file: archivo tipo file
  // type: identificador de tipo imagen o pdf
  saveFile(
    id: string,
    file: File,
    type: "imagen" | "pdf"
  ): ResponseCurrentUpload {
    const path: string = `revistas/${type}/${id}`;

    return this.storageService.uploadFilePercent(path, file, type);
  }

  // función para listar todas las revistas
  getRevistas(): Observable<DocumentData[]> {
    return this.firebaseRequest
      .getCollectionFirebaseWithQueryObservable<DocumentData>("revistas", [
        orderBy("fechaCreacion", "desc"),
      ])
      .pipe(
        switchMap((response: DocumentData[]) => {
          if (response.length > 0) {
            const observables: Observable<{ imagen: string; pdf: string }>[] =
              response.map((revista: DocumentData) => {
                const imagenObservable: Observable<string> = this.getFile(
                  revista["id"],
                  "imagen"
                );
                const pdfObservable: Observable<string> = this.getFile(
                  revista["id"],
                  "pdf"
                );

                return forkJoin({
                  imagen: imagenObservable,
                  pdf: pdfObservable,
                }).pipe(
                  catchError(() => {
                    return of({
                      imagen: "",
                      pdf: "",
                      ...revista,
                    });
                  }),
                  map(({ imagen, pdf }) => ({
                    imagen,
                    pdf,
                    ...revista,
                  }))
                );
              });

            return forkJoin(observables);
          }

          return of([]);
        })
      );
  }

  // función para obtener observable de archivo de revista imagen o pdf
  getFile(id: string, type: "imagen" | "pdf"): Observable<string> {
    return this.storageService.getStoreUrlImageObservable(
      `revistas/${type}/${id}`
    );
  }

  // retorna observable de guardado de imagen o pdf
  generateSavingFile(
    id: string,
    type: "imagen" | "pdf",
    file: File
  ): Observable<number> {
    return this.saveFile(id, file, type).currentUploads.pipe(
      map(
        (result: { progress: number; snapshot: UploadTaskSnapshot }) =>
          result.progress
      )
    );
  }

  // Función para eliminar una revista desde la colección, y storage con imagen y pdf.
  async delete(id: string): Promise<boolean> {
    try {
      await this.firebaseRequest.deleteDocFirebase(`revistas/${id}`);
      await this.storageService.deleteFile(`revistas/imagen/${id}`);
      await this.storageService.deleteFile(`revistas/pdf/${id}`);
      this.deleteRevista$.next(true);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }
}
