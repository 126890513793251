export const serviciosTecnicos: string[] = [
  "Administrador de Vivero",
  "Apoyo Gestión Libro de Operaciones",
  "Apoyo Registro ICA Cultivo Forestal",
  "Apoyo Técnico para Certificado de Incentivo Forestal (CIF)",
  "Aprovechamiento de Guadua",
  "Aprovechamiento Forestal",
  "Aprovechamiento de No Maderables",
  "Asesor en Exportación de Madera",
  "Asesor en Exportación de No Maderables",
  "Asistencia Técnica a Viveros",
  "Asistencia Técnica Apícola",
  "Avalúo Forestal",
  "Carpintero",
  "Chippeo de Residuos Vegetales",
  "Compensaciones Forestales",
  "Contrato de Aprendizaje",
  "Construcción con Guadua",
  "Corta y Aserrado de Árboles",
  "Defensa Jurídica Ambiental",
  "Ebanista",
  "Embolsador en vivero",
  "Establecimiento Cercas Vivas",
  "Establecimiento Plantación Forestal",
  "Establecimiento Sistema Agroforestal",
  "Establecimiento Sistema Silvopastoril",
  "Estrobador",
  "Estudio de Suelos",
  "Extracción de Carbón Vegetal",
  "Fertilización de Árboles",
  "Fotogrametría con Drones",
  "Gestión Permiso Aprovechamiento Forestal",
  "Identificación de maderas",
  "Inmunización de Madera",
  "Inventario Forestal",
  "Manejo de Plagas y Enfermedades Forestales",
  "Mecánico Maquinaria Forestal",
  "Medición de Predios y Elaboración de Planos",
  "Motosierrista",
  "Operador de Maquinaria Forestal",
  "Operario de Aserrío Portatil",
  "Operario de Aserrío",
  "Operario Tractor koller",
  "Pasantía",
  "Plan de Aprovechamiento Forestal No Maderables",
  "Plan de Manejo Forestal",
  "Poda de Árboles",
  "Procesado de Caucho Natural",
  "Proyectos de Bonos de Carbono",
  "Rayado y Recolección de Caucho",
  "Recolección de Semilla",
  "Rocería",
  "Secado de Madera",
  "Siembra de Árboles",
  "Transformación de la Guadua",
  "Traslado de Árboles",
];
