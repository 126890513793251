import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FacturaData } from "../../models/factura-data";
import { FacturacionService } from "src/app/services/facturacion.service";
import { Timestamp } from "@angular/fire/firestore";

@Component({
  selector: "app-facturacion",
  templateUrl: "./facturacion.component.html",
  styleUrls: ["./facturacion.component.css"],
})
export class FacturacionComponent {
  factura = new FormGroup({
    fechaFactura: new FormControl("", Validators.required),
    tipoOperacion: new FormControl("", Validators.required),
    tipoNegociacion: new FormControl("", Validators.required),
    fechaVencimientoFactura: new FormControl("", Validators.required),
    prefijo: new FormControl("", Validators.required),
    medioDePago: new FormControl("", Validators.required),
    actividadEconomica: new FormControl("", Validators.required),
    tdocumento: new FormControl(""),
    numeroID: new FormControl(""),
    nombreCompleto: new FormControl(""),
    departamento: new FormControl(""),
    ciudad: new FormControl(""),
    direccion: new FormControl(""),
    numeroTelefono: new FormControl(""),
    email: new FormControl(""),
    codigoProducto: new FormControl("", Validators.required),
    descripcionProducto: new FormControl(""),
    unidadProducto: new FormControl(""),
    cantidad: new FormControl("", Validators.required),
    precioSinIva: new FormControl(""),
    valorIVA: new FormControl(""),
    precioTotal: new FormControl(""),
    moneda: new FormControl("", Validators.required),
    totalIVA: new FormControl(""),
    totalCompra: new FormControl(""),
  });

  constructor(public facturacionService: FacturacionService) {}

  onCreate = async (): Promise<void> => {
    if (this.factura.invalid) {
      this.factura.markAllAsTouched();
      return;
    }

    const factura: FacturaData = {
      fechaFactura: Timestamp.fromDate(
        new Date(this.factura.value.fechaFactura || new Date())
      ),
      tipoOperacion: this.factura.value.tipoOperacion || "",
      tipoNegociacion: this.factura.value.tipoNegociacion || "",
      fechaVencimientoFactura: Timestamp.fromDate(
        new Date(this.factura.value.fechaVencimientoFactura || new Date())
      ),
      prefijo: this.factura.value.prefijo || "",
      medioDePago: this.factura.value.medioDePago || "",
      actividadEconomica: this.factura.value.actividadEconomica || "",
      tdocumento: this.factura.value.tdocumento || "",
      numeroID: parseInt(this.factura.value.numeroID as string) || 0,
      nombreCompleto: this.factura.value.nombreCompleto || "",
      departamento: this.factura.value.departamento || "",
      ciudad: this.factura.value.ciudad || "",
      direccion: this.factura.value.direccion || "",
      numeroTelefono:
        parseInt(this.factura.value.numeroTelefono as string) || 0,
      email: this.factura.value.email || "",
      codigoProducto:
        parseInt(this.factura.value.codigoProducto as string) || 0,
      descripcionProducto: this.factura.value.descripcionProducto || "",
      unidadProducto: this.factura.value.unidadProducto || "",
      cantidad: parseInt(this.factura.value.cantidad as string) || 0,
      precioSinIva: parseInt(this.factura.value.precioSinIva as string) || 0,
      valorIVA: parseInt(this.factura.value.valorIVA as string) || 0,
      precioTotal: parseInt(this.factura.value.precioTotal as string) || 0,
      moneda: this.factura.value.moneda || "",
      totalIVA: parseInt(this.factura.value.totalIVA as string) || 0,
      totalCompra: parseInt(this.factura.value.totalCompra as string) || 0,
    };
    try {
      await this.facturacionService.addFactura(factura);
      this.resetFormAndFiles();
    } catch (error) {
      console.error(error);
    }
  };
  isValidField(field: string): boolean {
    return (
      (this.factura.get(field)?.dirty && this.factura.get(field)?.valid) ||
      false
    );
  }

  isInvalidField(field: string): boolean {
    return (
      ((this.factura.get(field)?.touched || this.factura.get(field)?.dirty) &&
        !this.factura.get(field)?.valid) ||
      false
    );
  }

  resetFormAndFiles(): void {
    this.factura.reset();
  }
}
