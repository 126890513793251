<div class="container main">
  <div class="row">
    <div class="col-12">
      <h2>Permisos de usuario</h2>
    </div>
    <div class="col-md-4 col-5">
      <ul class="list-group">
        <li
          class="list-group-item role"
          [class]="{ selected: role.id === currentRole }"
          *ngFor="let role of roles"
          (click)="selectRole(role)"
        >
          {{ role.id | titlecase }}
        </li>
      </ul>
    </div>
    <div class="col-md-8 col-7">
      <ul class="list-group">
        <li
          class="list-group-item permiso"
          *ngFor="let permiso of permisosGenerales"
          (click)="check(permiso)"
        >
          <div class="input-group-prepend">
            <div class="input-group-text check">
              <p>
                <img [src]="permiso.img" />
                {{ permiso.label | titlecase }}
              </p>
              <input [checked]="isChecked(permiso)" type="checkbox" />
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="col-12 text-right mt-1">
      <button
        class="btn btn-primary"
        (click)="updateRole()"
        *ngIf="this.currentRole"
      >
        Actualizar
      </button>
    </div>
  </div>
</div>
