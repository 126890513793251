import { Pipe, PipeTransform } from "@angular/core";
import { WtUserService } from "../service/wt-user.service";

/**
 * Pipe que devuelve un string con el campo 'fullName' de un WtUser, dado su id.
 */
@Pipe({
  name: "wtUserName",
})
export class WtUserNamePipe implements PipeTransform {
  constructor(private crud: WtUserService) {}

  async transform(uid: string): Promise<string> {
    if (uid === "") {
      return "";
    }
    return this.crud
      .fetchDoc(uid)
      .then((wtUser) => wtUser?.fullName ?? "--")
      .catch(() => "");
  }
}
