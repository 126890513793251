import { Component, OnDestroy } from "@angular/core";
import { Path } from "../enums/Path";
import { AuthenticationStateModel } from "../state/authentication-state";
import { AuthenticationState } from "../state/authentication-state/state";
import { Select } from "@ngxs/store";
import { Observable, Subject, takeUntil, tap } from "rxjs";
import { NavBarComponent } from "../nav-bar/nav-bar.component";
import { User } from "@angular/fire/auth";

@Component({
  selector: "app-nav-bar-bottom",
  templateUrl: "./nav-bar-bottom.component.html",
  styleUrls: ["./nav-bar-bottom.component.css"],
  providers: [NavBarComponent],
})
export class NavBarBottomComponent implements OnDestroy {
  // observables
  @Select(AuthenticationState)
  authenticationState$!: Observable<AuthenticationStateModel>;

  // flags
  private sub$: Subject<void> = new Subject();
  public user: User | null = null;
  public userIsActive: boolean | string = false;

  public path: typeof Path = Path;
  constructor(private navbar: NavBarComponent) {
    this.authenticationState$
      .pipe(
        takeUntil(this.sub$),
        tap({
          next: (state: AuthenticationStateModel) => {
            this.userIsActive = state.userIsActive;
            this.user = state.user;
          },
          error: (error) => {
            console.log(error);
          },
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.sub$.next();
    this.sub$.complete();
  }
  async seleccionPerfil(): Promise<void> {
    await this.navbar.seleccionPerfil();
  }
}
