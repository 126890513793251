<ng-template let-modal #content let-c="close">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">
      {{ "certificado-producto.idCertificado" | translate }}
      {{ datosCertificado.idPedido }}
    </h5>
    <button
      type="button"
      class="close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="c()"
    >
      <span aria-hidden="true">{{ "formularios.cerrar" | translate }}</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="text-center">
      <img
        class="imagen-certificado"
        src="../../assets/imagenes/logo_red_forestal_png.png"
      />
    </div>
    <h1>{{ "certificado-producto.certificadoProducto" | translate }}</h1>
    <p
      innerHTML="{{ 'certificado-producto.redForestalCertifica' | translate }}"
    ></p>
    <h2 class="subtitle-certificate">
      {{ "certificado-producto.datosOrigen" | translate }}
    </h2>
    <div class="row">
      <div class="col-sm-6">
        <ul>
          <li>
            <b>{{ "formularios.pais" | translate }}</b>
            {{ datosCertificado.vendedor.pais | titlecase }}
          </li>
          <li>
            <b>{{ "formularios.departamento" | translate }}</b>
            {{ datosCertificado.vendedor.departamento | titlecase }}
          </li>
          <li>
            <b>{{ "formularios.municipio" | translate }}</b>
            {{ datosCertificado.vendedor.municipio | titlecase }}
          </li>
          <li>
            <b>{{ "formularios.categoria" | translate }}</b>
            {{ datosCertificado.producto.categoria | titlecase }}
          </li>
          <li>
            <b>{{ "certificado-producto.subcategoria" | translate }}</b>
            {{ datosCertificado.producto.subcategoria }}
          </li>
          <li *ngIf="datosCertificado?.vendedor?.entidades">
            <b>{{ "certificado-producto.registradoEn" | translate }}</b>
            <ul>
              <li
                *ngFor="let entidad of datosCertificado?.vendedor?.entidades"
                class="li-entidad"
              >
                <small>
                  {{ entidad }}
                </small>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="col-sm-6">
        <ul>
          <li>
            <b>{{ "formularios.especie" | translate }}</b>
            {{ datosCertificado.producto.especie }}
          </li>
          <li>
            <b>{{ "certificado-producto.unidad" | translate }}</b>
            {{ datosCertificado.producto.unidadMedida }}
          </li>
          <li>
            <b>{{ "certificado-producto.cantidad" | translate }}</b>
            {{ datosCertificado.producto.cantidad }}
          </li>
          <li *ngFor="let field of getKeys(datosCertificado?.producto)">
            <b>{{ "certificado-producto." + field | translate }}</b>
            {{ datosCertificado.producto[field] }}
          </li>
        </ul>
      </div>
    </div>

    <h2 class="subtitle-certificate">
      {{ "certificado-producto.datosVendedor" | translate }}
    </h2>
    <ul>
      <li>
        <b>{{ "formularios.nombre" | translate }}</b>
        {{ datosCertificado.vendedor.nombre | titlecase }}
      </li>
      <li>
        <b>{{ "formularios.telefono" | translate }}</b>
        {{ datosCertificado.vendedor.telefono }}
      </li>
      <li>
        <b>{{ "formularios.correo" | translate }}</b>
        {{ datosCertificado.vendedor.email }}
      </li>
    </ul>
    <h2 class="subtitle-certificate">
      {{ "certificado-producto.datosComprador" | translate }}
    </h2>
    <ul>
      <li>
        <b>{{ "formularios.nombre" | translate }}</b>
        {{ datosCertificado.comprador.nombre | titlecase }}
      </li>
      <li>
        <b>{{ "formularios.telefono" | translate }}</b>
        {{ datosCertificado.comprador.telefono }}
      </li>
      <li>
        <b>{{ "formularios.correo" | translate }}</b>
        {{ datosCertificado.comprador.email }}
      </li>
    </ul>
    <p class="footer-certificate">
      {{ "certificado-producto.autenticidad" | translate }}
      <a href="https://redforestal.com/validador"
        >https://redforestal.com/validador</a
      >
      {{ "certificado-producto.bajoNumero" | translate }}
      <b
        >{{ "certificado-producto.idCertificado" | translate }}
        {{ datosCertificado.idPedido }}</b
      >.
    </p>
  </div>
  <div class="modal-footer">
    <button
      #closeButton
      type="button"
      class="btn btn-danger"
      data-bs-dismiss="modal"
      (click)="c()"
    >
      {{ "formularios.cerrar" | translate }}
    </button>
    <button
      #closeButton
      type="button"
      class="btn btn-warning"
      data-bs-dismiss="modal"
      (click)="generarPdf(actionsEnum.print)"
    >
      {{ "certificado-producto.imprimir" | translate }}
    </button>
    <button
      #closeButton
      type="button"
      class="btn btn-success"
      data-bs-dismiss="modal"
      (click)="generarPdf(actionsEnum.download)"
    >
      {{ "certificado-producto.descargar" | translate }}
    </button>
  </div>
</ng-template>
