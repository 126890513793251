import { Pipe, PipeTransform } from "@angular/core";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { LangTranslateService } from "src/app/services/lang-translate.service";

@Pipe({
  name: "langRoute",
})
export class LangRoutePipe implements PipeTransform {
  constructor(private langService: LangTranslateService) {}

  transform(routes: string[]): Observable<string[]> {
    return this.langService.currrentLang.pipe(
      map((lang: string) => {
        return ["/", lang, ...routes];
      })
    );
  }
}
