import { Injectable } from "@angular/core";
import { ComprasService } from "./compras.service";
import { UserData, UserService } from "./user.service";
import { FirebaseRequestsService } from "./firebase-requests.service";
import { firstValueFrom } from "rxjs";
import {
  DocumentData,
  DocumentReference,
  where,
} from "@angular/fire/firestore";
import { Compra } from "../models/compra.interface";
import { CarroCompras } from "../models/CarroCompras";
import {
  DatosCertificado,
  DatosUser,
} from "../models/certificadoProducto.interface";
import { UserDataHelpers } from "../utils/getApellidosUserData";

@Injectable({
  providedIn: "root",
})
export class CertificadoProductoService {
  constructor(
    private firebaseRequests: FirebaseRequestsService,
    private comprasService: ComprasService,
    private userService: UserService
  ) {}

  async getDatosCertificado(
    idCompra: string,
    idPedido: string
  ): Promise<DatosCertificado> {
    try {
      const datosCompra: Compra = await firstValueFrom(
        this.comprasService.getPedidoById(idCompra)
      );
      const product: CarroCompras | undefined = datosCompra.productos?.find(
        (producto: CarroCompras) => producto.id === idPedido
      );

      if (!product) {
        throw new Error("No se encontró el producto");
      }

      const {
        especie,
        unidadMedida,
        cantidad,
        categoria,
        subcategoria,
        registradoEn,
        idVendedor,
        altura,
        grosor,
        largo,
      } = product;

      const producto: any = {
        especie,
        unidadMedida,
        cantidad,
        categoria,
        subcategoria,
        registradoEn,
        altura,
        grosor,
        largo,
      };

      const comprador: {
        nombre: string;
        telefono: string | number;
        email: string;
        departamento: string;
        pais: string;
        municipio: string;
        entidades: string[];
      } = await this.getDatosUsuario(datosCompra.idComprador);
      const vendedor: {
        nombre: string;
        telefono: string | number;
        email: string;
        departamento: string;
        pais: string;
        municipio: string;
        entidades: string[];
      } = await this.getDatosUsuario(idVendedor);

      return { comprador, vendedor, producto };
    } catch (err) {
      console.error(err);
      throw new Error("fatal Error");
    }
  }

  async getDatosUsuario(id: string): Promise<DatosUser> {
    const datos: UserData = await firstValueFrom(
      this.userService.getUserById(id)
    );

    if (!datos) {
      throw new Error("user not found");
    }

    const { email, telefono, pais, departamento, municipio, entidades } = datos;

    return {
      nombre: `${datos.nombres} ${UserDataHelpers.getApellidos(datos)}`,
      email,
      telefono,
      pais,
      departamento,
      municipio,
      entidades,
    };
  }

  async addCertificado(data: {
    idCompra: string;
    idPedido: string;
  }): Promise<string> {
    const { idPedido } = data;
    const certificates: DocumentData[] = await this.getCertificado(idPedido);

    if (!certificates.length) {
      const newDoc: DocumentReference =
        await this.firebaseRequests.addDocFirebaseWithAutomaticId(
          "certificados",
          data
        );
      return newDoc.id;
    } else {
      const [certificate] = certificates;
      return certificate["ref"].id;
    }
  }

  async getCertificado(idPedido: string): Promise<DocumentData[]> {
    return this.firebaseRequests.getCollectionFirebasePromiseWithRef(
      "certificados",
      [where("idPedido", "==", idPedido)]
    );
  }
}
