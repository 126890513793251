<div class="row row-title pt-1 mt-3">
  <h2 class="tituloEstadistica col-8">Estadísticas Red Forestal</h2>
  <div class="col-4">
    <select
      class="form-select"
      aria-label="Default select example"
      [(ngModel)]="currentYear"
      (ngModelChange)="this.getStatistics($event)"
    >
      <option [value]="year" *ngFor="let year of aniosSelect || []">
        {{ year }}
      </option>
    </select>
  </div>
</div>
<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
  <li [ngbNavItem]="secciones.usuarios">
    <a ngbNavLink>Usuarios</a>
    <ng-template ngbNavContent>
      <!-- registros y activaciones -->
      <div class="row section mt-5">
        <div class="col-12 col-md-6">
          <h4 class="subtituloEstadistica mt-2">
            <b> Estadística mensual para registros </b>
          </h4>
          <!-- tipos grafica -->
          <div class="btn-group btn-group-toggle mt-1">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                value="bar"
                name="flexRadioRegistrosActivaciones"
                id="flexRadioRegistrosActivacionesBar"
                [(ngModel)]="graphicTypes['usersChartData']"
                checked
              />
              <label
                class="form-check-label"
                for="flexRadioRegistrosActivacionesBar"
              >
                Barras
              </label>
            </div>
          </div>
          <div style="display: block">
            <canvas
              baseChart
              [datasets]="graphics['usersChartData'] || []"
              [labels]="barChartLabels"
              [options]="barChartOptions"
              [type]="graphicTypes['usersChartData']"
            >
            </canvas>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="table-responsive-sm">
            <table class="table mt-3 text-center">
              <thead class="table-dark">
                <tr>
                  <th scope="col">Mes</th>
                  <th scope="col">Registrados</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let fila of tablas['usersChartData']">
                  <th scope="row">{{ fila.mes || "Total" | titlecase }}</th>
                  <td>{{ fila.registrados }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-12">
          <span *ngFor="let row of maxAndMin?.usersChartData">
            <h5 *ngIf="row.cantidad">
              <b>{{ row?.label }}</b> {{ row?.description }}
            </h5>
          </span>
        </div>
      </div>
      <!-- tipos y tamaños -->
      <div class="row section mt-5">
        <div class="col-12 col-md-6">
          <h4 class="subtituloEstadistica mt-2">
            <b> Estadística mensual para tamaño y tipo </b>
          </h4>
          <!-- tipos grafica  -->
          <div class="btn-group btn-group-toggle mt-1">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                value="bar"
                name="flexRadioTipos"
                id="flexRadioTiposBar"
                [(ngModel)]="graphicTypes['typeUsersChartData']"
                checked
              />
              <label class="form-check-label" for="flexRadioTiposBar">
                Barras
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                value="line"
                name="flexRadioTipos"
                [(ngModel)]="graphicTypes['typeUsersChartData']"
                id="flexRadioTiposLine"
              />
              <label class="form-check-label" for="flexRadioTiposLine">
                Lineas
              </label>
            </div>
          </div>
          <div style="display: block">
            <canvas
              baseChart
              [datasets]="graphics['typeUsersChartData'] || []"
              [labels]="barChartLabels"
              [options]="barChartOptions"
              [type]="graphicTypes['typeUsersChartData']"
            >
            </canvas>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="table-responsive">
            <table class="table mt-3 text-center">
              <thead class="table-dark">
                <tr>
                  <th scope="col">Mes</th>
                  <th scope="col">Natural</th>
                  <th scope="col">Jurídico</th>
                  <th scope="col">Vendedor</th>
                  <th scope="col">Comprador</th>
                  <th scope="col">Técnico</th>
                  <th scope="col">Transportador</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let fila of tablas['typeUsersChartData']">
                  <th scope="row">{{ fila.mes || "Total" | titlecase }}</th>
                  <td>{{ fila.Natural }}</td>
                  <td>{{ fila["Jurídico"] }}</td>
                  <td>{{ fila.vendedor }}</td>
                  <td>{{ fila.comprador }}</td>
                  <td>{{ fila.tecnico }}</td>
                  <td>{{ fila.transportador }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-12">
          <span *ngFor="let row of maxAndMin?.typeUsersChartData">
            <h5 *ngIf="row?.cantidad">
              <b>{{ row?.label }}</b> {{ row?.description }}
            </h5>
          </span>
        </div>
      </div>
      <!-- Entidades -->
      <div class="row section mt-5">
        <div class="col-12 col-md-6">
          <h4 class="subtituloEstadistica mt-2">
            <b> Estadística mensual para entidades de usuario </b>
          </h4>
          <!-- tipos grafica  -->
          <div style="display: block">
            <canvas
              baseChart
              [datasets]="graphics['entityUsersChartData'] || []"
              [labels]="tablas?.entityUsersChartData?.labels"
              [options]="barChartOptions"
              [type]="graphicTypes['entityUsersChartData']"
            >
            </canvas>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="table-responsive-sm">
            <table class="table mt-3 text-center">
              <thead class="table-dark">
                <tr>
                  <th scope="col">Entidad</th>
                  <th scope="col">Cantidad</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let fila of tablas['entityUsersChartData']">
                  <th scope="row">
                    <small>
                      {{ fila.tipo }}
                    </small>
                  </th>
                  <td>{{ fila.cantidad }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="secciones.productos">
    <a ngbNavLink>Productos</a>
    <ng-template ngbNavContent>
      <!----------------------- creación de productos -------------------->
      <div class="row section mt-5">
        <div class="col-12 col-md-6">
          <h4 class="subtituloEstadistica mt-1">
            <b> Estadística mensual para creaciones </b>
          </h4>
          <!-- tipos grafica -->
          <div class="btn-group btn-group-toggle mt-1">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                value="bar"
                name="flexRadioProductos"
                id="flexRadioProductosBar"
                [(ngModel)]="graphicTypes['productsChartData']"
                checked
              />
              <label class="form-check-label" for="flexRadioProductosBar">
                Barras
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                value="line"
                name="flexRadioProductos"
                [(ngModel)]="graphicTypes['productsChartData']"
                id="flexRadioProductosLine"
              />
              <label class="form-check-label" for="flexRadioProductosLine">
                Lineas
              </label>
            </div>
          </div>
          <div style="display: block">
            <canvas
              baseChart
              [datasets]="graphics['productsChartData'] || []"
              [labels]="barChartLabels"
              [options]="barChartOptions"
              [type]="graphicTypes['productsChartData']"
            >
            </canvas>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="table-responsive-sm">
            <table class="table mt-3 text-center">
              <thead class="table-dark">
                <tr>
                  <th scope="col">Mes</th>
                  <th scope="col">Creados</th>
                  <th
                    scope="col"
                    *ngFor="
                      let categoria of statistics?.['productos']?.categorias
                    "
                  >
                    {{ categoria }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let fila of tablas['productsChartData']">
                  <th scope="row">{{ fila.mes || "Total" | titlecase }}</th>
                  <td>{{ fila.creado }}</td>
                  <td
                    *ngFor="
                      let categoria of statistics?.['productos']?.categorias
                    "
                  >
                    {{ fila[categoria] }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        class="row section mt-5"
        *ngFor="let categoria of statistics?.['productos']?.categorias || []"
      >
        <div class="col-12 col-md-6">
          <h4 class="subtituloEstadistica mt-1">
            <b> Estadística precios promedio {{ categoria }} </b>
          </h4>
          <!-- tipos grafica -->
          <div style="display: block">
            <canvas
              baseChart
              [datasets]="graphics[categoria + 'ProductChartData'] || []"
              [labels]="getEspeciesCategory(categoria)"
              [options]="barChartOptions"
              [type]="graphicTypes['productsChartData']"
            >
            </canvas>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="table-responsive-sm">
            <table class="table mt-3 text-center">
              <thead class="table-dark">
                <tr>
                  <th scope="col">Especie</th>
                  <th scope="col">Precio Promedio</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let fila of tablas[categoria + 'ProductChartData']">
                  <th scope="row">{{ fila.especie | titlecase }}</th>
                  <td>{{ fila.precio | currency: "$ " : true : "3.0" }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ng-template>
  </li>
  <!-- sección geografica -->
  <li [ngbNavItem]="secciones.geografica">
    <a ngbNavLink>Geográfica</a>
    <ng-template ngbNavContent>
      <!-- Estadística mensual para ubicación geografica -->
      <div class="row mt-5">
        <div class="col-12 col-md-12">
          <h4 class="subtituloEstadistica mt-1">
            <b> Estadística mensual para ubicación geografica </b>
          </h4>
          <!-- tipos grafica -->
          <div class="btn-group btn-group-toggle mt-1">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                value="bar"
                name="flexRadioGeo"
                id="flexRadioGeoBar"
                [(ngModel)]="graphicTypes['usersGeoCountryChartData']"
                checked
              />
              <label class="form-check-label" for="flexRadioGeoBar">
                Barras
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                value="line"
                name="flexRadioGeo"
                [(ngModel)]="graphicTypes['usersGeoCountryChartData']"
                id="flexRadioProductosLine"
              />
              <label class="form-check-label" for="flexRadioProductosLine">
                Lineas
              </label>
            </div>
          </div>
          <div style="display: block">
            <canvas
              baseChart
              [datasets]="graphics['usersGeoCountryChartData'] || []"
              [labels]="barChartLabels"
              [options]="barChartOptions"
              [type]="graphicTypes['usersGeoCountryChartData']"
            >
            </canvas>
          </div>
        </div>
        <div class="col-12 col-md-12">
          <div class="table-responsive-sm">
            <table class="table mt-3 text-center">
              <thead class="table-dark">
                <tr>
                  <th scope="col">Mes</th>
                  <th
                    scope="col"
                    *ngFor="
                      let pais of this.statistics?.['usuarios']?.paises || []
                    "
                  >
                    <small>
                      {{ pais.split("/")[0] | titlecase }}
                    </small>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let fila of tablas['usersGeoCountryChartData']">
                  <th scope="row">{{ fila.mes || "Total" | titlecase }}</th>
                  <td
                    *ngFor="
                      let pais of this.statistics?.['usuarios']?.paises || []
                    "
                  >
                    {{ fila[pais] }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-12">
          <span *ngFor="let row of maxAndMin?.usersGeoCountryChartData">
            <h5 *ngIf="row?.cantidad">
              <b>{{ row?.label }}</b> {{ row?.description }}
            </h5>
          </span>
        </div>
      </div>
      <!-- Estadística mensual para Departamentos -->
      <div class="row section mt-5">
        <div class="col-12 col-md-12">
          <h4 class="subtituloEstadistica mt-1">
            <b> Estadística mensual para Departamentos </b>
          </h4>
          <!-- tipos grafica  -->
          <div class="btn-group btn-group-toggle mt-1">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                value="bar"
                name="flexRadioDepartamentos"
                id="flexRadioDepartamentosBar"
                [(ngModel)]="graphicTypes['usersGeoDepartmentChartData']"
                checked
              />
              <label class="form-check-label" for="flexRadioDepartamentosBar">
                Barras
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                value="line"
                name="flexRadioDepartamentos"
                [(ngModel)]="graphicTypes['usersGeoDepartmentChartData']"
                id="flexRadioDepartamentosLine"
              />
              <label class="form-check-label" for="flexRadioDepartamentosLine">
                Lineas
              </label>
            </div>
          </div>
          <div style="display: block">
            <canvas
              baseChart
              [datasets]="graphics['usersGeoDepartmentChartData'] || []"
              [labels]="barChartLabels"
              [options]="barChartOptions"
              [type]="graphicTypes['usersGeoDepartmentChartData']"
            >
            </canvas>
          </div>
        </div>
        <div class="col-12 col-md-12">
          <div class="table-responsive-sm">
            <table class="table mt-3 text-center">
              <thead class="table-dark">
                <tr>
                  <th scope="col" class="px-0">Mes</th>
                  <th
                    *ngFor="
                      let depto of this.statistics?.['usuarios']?.departamentos
                    "
                    class="px-0"
                  >
                    <small>
                      {{
                        depto.length > 15
                          ? (depto | slice: 0 : 15) + ".."
                          : depto
                      }}
                    </small>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let fila of tablas['usersGeoDepartmentChartData']">
                  <th scope="row" class="px-0">
                    {{ fila.mes || "Total" | titlecase }}
                  </th>
                  <td
                    *ngFor="
                      let depto of this.statistics?.['usuarios']?.departamentos
                    "
                    class="px-0"
                  >
                    {{ fila[depto] }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-12">
          <span *ngFor="let row of maxAndMin?.usersGeoDepartmentChartData">
            <h5 *ngIf="row?.cantidad">
              <b>{{ row?.label }}</b> {{ row?.description }}
            </h5>
          </span>
        </div>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="secciones.suscripciones">
    <a ngbNavLink>Suscripciones</a>
    <ng-template ngbNavContent>
      <!-- Estadística mensual para suscripciones activas -->
      <div class="row section mt-5">
        <div class="col-12 col-md-6">
          <h4 class="subtituloEstadistica mt-1">
            <b> Estadística mensual para suscripciones activas </b>
          </h4>
          <!-- tipos grafica -->
          <div class="btn-group btn-group-toggle mt-1">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                value="bar"
                name="flexRadioSubscripQty"
                id="flexRadioSubscripQtyBar"
                [(ngModel)]="graphicTypes['subscriptionsQtyChartData']"
                checked
              />
              <label class="form-check-label" for="flexRadioSubscripQtyBar">
                Barras
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                value="line"
                name="flexRadioSubscripQty"
                [(ngModel)]="graphicTypes['subscriptionsQtyChartData']"
                id="flexRadioSubscripQtyLine"
              />
              <label class="form-check-label" for="flexRadioSubscripQtyLine">
                Lineas
              </label>
            </div>
          </div>
          <div style="display: block">
            <canvas
              baseChart
              [datasets]="graphics['subscriptionsQtyChartData'] || []"
              [labels]="barChartLabels"
              [options]="barChartOptions"
              [type]="graphicTypes['subscriptionsQtyChartData']"
            >
            </canvas>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="table-responsive-sm">
            <table class="table mt-3 text-center">
              <thead class="table-dark">
                <tr>
                  <th scope="col">Mes</th>
                  <th scope="col">Activos</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let fila of tablas['subscriptionsQtyChartData']">
                  <th scope="row">{{ fila.mes || "Total" | titlecase }}</th>
                  <td>{{ fila["activo"] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-12">
          <span *ngFor="let row of maxAndMin?.subscriptionsQtyChartData">
            <h5 *ngIf="row?.cantidad">
              <b>{{ row?.label }}</b> {{ row?.description }}
            </h5>
          </span>
        </div>
      </div>
      <!-- Estadística mensual para pagos de suscripciones -->
      <div class="row section mt-5">
        <div class="col-12 col-md-6">
          <h4 class="subtituloEstadistica mt-1">
            <b> Estadística mensual para pagos de suscripciones </b>
          </h4>
          <!-- tipos grafica -->
          <div class="btn-group btn-group-toggle mt-1">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                value="bar"
                name="flexRadioSubscrip"
                id="flexRadioSubscripBar"
                [(ngModel)]="graphicTypes['subscriptionsChartData']"
                checked
              />
              <label class="form-check-label" for="flexRadioSubscripBar">
                Barras
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                value="line"
                name="flexRadioSubscrip"
                [(ngModel)]="graphicTypes['subscriptionsChartData']"
                id="flexRadioSubscripLine"
              />
              <label class="form-check-label" for="flexRadioSubscripLine">
                Lineas
              </label>
            </div>
          </div>
          <div style="display: block">
            <canvas
              baseChart
              [datasets]="graphics['subscriptionsChartData'] || []"
              [labels]="barChartLabels"
              [options]="barChartOptions"
              [type]="graphicTypes['subscriptionsChartData']"
            >
            </canvas>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="table-responsive-sm">
            <table class="table mt-3 text-center">
              <thead class="table-dark">
                <tr>
                  <th scope="col">Mes</th>
                  <th
                    scope="col"
                    *ngFor="
                      let pais of ['1 mes', '3 meses', '6 meses', '12 meses']
                    "
                  >
                    <small>
                      {{ pais.split("/")[0] | titlecase }}
                    </small>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let fila of tablas['subscriptionsChartData']">
                  <th scope="row">{{ fila.mes || "Total" | titlecase }}</th>
                  <td
                    *ngFor="
                      let cantidad of [
                        '1 mes',
                        '3 meses',
                        '6 meses',
                        '12 meses'
                      ]
                    "
                  >
                    {{ fila[cantidad] }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-12">
          <span *ngFor="let row of maxAndMin?.subscriptionsChartData">
            <h5 *ngIf="row.cantidad">
              <b>{{ row?.label }}</b> {{ row?.description }}
            </h5>
          </span>
        </div>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="secciones.mensajes">
    <a ngbNavLink>Mensajes</a>
    <ng-template ngbNavContent>
      <!-- mensajes-->
      <div class="row section mt-5">
        <div class="col-12 col-md-6">
          <h4 class="subtituloEstadistica mt-2">
            <b> Estadística mensual para preguntas realizadas </b>
          </h4>
          <!-- tipos grafica -->
          <div class="btn-group btn-group-toggle mt-1">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                value="bar"
                name="flexRadioMensajes"
                id="flexRadioMensajesBar"
                [(ngModel)]="graphicTypes['preguntasChartData']"
                checked
              />
              <label class="form-check-label" for="flexRadioMensajesBar">
                Barras
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                value="line"
                name="flexRadioMensajes"
                [(ngModel)]="graphicTypes['preguntasChartData']"
                id="flexRadioMensajesLine"
              />
              <label class="form-check-label" for="flexRadioMensajesLine">
                Lineas
              </label>
            </div>
          </div>
          <div style="display: block">
            <canvas
              baseChart
              [datasets]="graphics['preguntasQtyChartData'] || []"
              [labels]="barChartLabels"
              [options]="barChartOptions"
              [type]="graphicTypes['preguntasChartData']"
            >
            </canvas>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="table-responsive-sm">
            <table class="table mt-3 text-center">
              <thead class="table-dark">
                <tr>
                  <th scope="col">Mes</th>
                  <th scope="col">Creados</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let fila of tablas['preguntasQtyChartData']">
                  <th scope="row">{{ fila.mes || "Total" | titlecase }}</th>
                  <td>{{ fila.creado }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-12">
          <span *ngFor="let row of maxAndMin?.preguntasQtyChartData">
            <h5 *ngIf="row.cantidad">
              <b>{{ row?.label }}</b> {{ row?.description }}
            </h5>
          </span>
        </div>
      </div>
      <div class="row section mt-5">
        <div class="col-12 col-md-6">
          <h4 class="subtituloEstadistica mt-2">
            <b> Estadística mensual para respuestas realizadas </b>
          </h4>
          <!-- tipos grafica -->
          <div class="btn-group btn-group-toggle mt-1">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                value="bar"
                name="flexRadioRespuestas"
                id="flexRadioRespuestasBar"
                [(ngModel)]="graphicTypes['respuestasChartData']"
                checked
              />
              <label class="form-check-label" for="flexRadioRespuestasBar">
                Barras
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                value="line"
                name="flexRadioRespuestas"
                [(ngModel)]="graphicTypes['respuestasChartData']"
                id="flexRadioRespuestasLine"
              />
              <label class="form-check-label" for="flexRadioRespuestasLine">
                Lineas
              </label>
            </div>
          </div>
          <div style="display: block">
            <canvas
              baseChart
              [datasets]="graphics['respuestasQtyChartData'] || []"
              [labels]="barChartLabels"
              [options]="barChartOptions"
              [type]="graphicTypes['respuestasChartData']"
            >
            </canvas>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="table-responsive-sm">
            <table class="table mt-3 text-center">
              <thead class="table-dark">
                <tr>
                  <th scope="col">Mes</th>
                  <th scope="col">Creados</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let fila of tablas['respuestasQtyChartData']">
                  <th scope="row">{{ fila.mes || "Total" | titlecase }}</th>
                  <td>{{ fila.creado }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav" class="mt-1"></div>

<ng-template #loadingModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center w-100">
      <b>Cargando Datos</b>
    </h4>
  </div>
  <div class="modal-body text-center">
    <img
      class="img-gif"
      src="../../assets/imagenes/loading_gif.gif"
      alt="cargando"
    />
  </div>
</ng-template>
