import { Injectable } from "@angular/core";
import {
  DocumentReference,
  arrayRemove,
  arrayUnion,
} from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { FirebaseRequestsService } from "./firebase-requests.service";
import { ProductoPreferidoComprador } from "../models/ProductoPreferidoComprador";

@Injectable({
  providedIn: "root",
})
export class PreferidoCompradorService {
  constructor(private firebaseRequest: FirebaseRequestsService) {}

  async addPrefer(data: ProductoPreferidoComprador): Promise<string> {
    try {
      const documentReference: DocumentReference =
        await this.firebaseRequest.addDocFirebaseWithAutomaticId(
          "preferidosComprador",
          data
        );
      return documentReference.id;
    } catch (error) {
      console.error(error);
      throw new Error("Error adding data");
    }
  }

  getPreferById(id: string): Observable<ProductoPreferidoComprador> {
    return this.firebaseRequest.getDocFirebaseWithIdObservable<ProductoPreferidoComprador>(
      `preferidosComprador/${id}`
    );
  }

  deletePrefer(id: string): Promise<void> {
    return this.firebaseRequest.deleteDocFirebase(`preferidosComprador/${id}`);
  }

  deletePreferInUser(idUser: string, idBorrar: string): Promise<void> {
    return this.firebaseRequest.updateDocFirebase(`usuarios/${idUser}`, {
      preferidosComprador: arrayRemove(idBorrar),
    });
  }

  addPreferInUser(id: string, idAgregar: string): Promise<void> {
    return this.firebaseRequest.updateDocFirebase(`usuarios/${id}`, {
      preferidosComprador: arrayUnion(idAgregar),
    });
  }
}
