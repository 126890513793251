import { Injectable } from "@angular/core";
import { DocumentData, Timestamp, where } from "@angular/fire/firestore";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import moment from "moment";
import { FirebaseRequestsService } from "./firebase-requests.service";
import {
  Suscripcion,
  HistorialSuscripcion,
  SuscripcionActiva,
} from "../models/subscripcion.interface";

@Injectable({
  providedIn: "root",
})
export class SubscriptionService {
  constructor(private firebaseRequest: FirebaseRequestsService) {}

  getSubscriptionById(id: string): Observable<HistorialSuscripcion | null> {
    return this.firebaseRequest
      .getDocFirebaseWithIdObservable<HistorialSuscripcion>(
        `historialSuscripciones/${id}`
      )
      .pipe(catchError(() => of(null)));
  }

  async createSubscription(data: Suscripcion): Promise<void> {
    try {
      await this.firebaseRequest.addDocFirebaseWithAutomaticId(
        "suscripciones",
        data
      );
    } catch (error) {
      console.error(error);
    }
  }

  async updateSubscription(
    id: string,
    data: HistorialSuscripcion
  ): Promise<void> {
    try {
      await this.firebaseRequest.updateDocFirebase(
        `historialSuscripciones/${id}`,
        data
      );
    } catch (error) {
      console.error(error);
    }
  }

  async getSubscriptionByUser(idUsuario: string): Promise<Suscripcion[]> {
    return this.firebaseRequest.getCollectionFirebasePromiseWithId<Suscripcion>(
      "suscripciones",
      [where("idUsuario", "==", idUsuario)]
    );
  }

  async getActiveSubscription(idUsuario: string): Promise<SuscripcionActiva> {
    try {
      const data: Suscripcion[] =
        await this.firebaseRequest.getCollectionFirebasePromiseWithId<Suscripcion>(
          "suscripciones",
          [
            where("fechaFin", ">=", Timestamp.fromDate(new Date(Date.now()))),
            where("idUsuario", "==", idUsuario),
          ]
        );
      const suscripActiva: Suscripcion = data.find((doc: DocumentData) => {
        const inicioParsed: moment.Moment = moment(
          doc["fechaInicio"].seconds * 1000
        );
        const activa: boolean = moment(moment(new Date())).isSameOrAfter(
          inicioParsed
        );
        return activa;
      }) as Suscripcion;

      const suscripcionVencida: boolean =
        await this.getFinishedSubscription(idUsuario);

      const response: SuscripcionActiva = {
        suscripcionVencida,
        suscripcionActual: suscripActiva,
      };
      return response;
    } catch (error) {
      console.warn(error);
      throw new Error("Error getting suscripcion");
    }
  }

  async getFinishedSubscription(idUsuario: string): Promise<boolean> {
    try {
      const data: Suscripcion[] =
        await this.firebaseRequest.getCollectionFirebasePromiseWithId<Suscripcion>(
          "suscripciones",
          [
            where("idUsuario", "==", idUsuario),
            where("fechaFin", "<", Timestamp.now()),
          ]
        );
      return data.length ? true : false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async removeSubscription(id: string): Promise<void> {
    try {
      await this.firebaseRequest.deleteDocFirebase(`suscripciones/${id}`);
    } catch (error) {
      console.error(error);
    }
  }
}
