<div class="container-fluid">
  <div class="row">
    <div class="col-3" id="menuProfile">
      <img class="imagen-perfil" [src]="url" alt="your image" />
      <h2 class="text-center mt-2">{{ "perfil-usuarios.menu" | translate }}</h2>
      <div class="row">
        <label for="fileUpload" class="btn-label btn">
          <img
            class="img-camara"
            src="../../assets/imagenes/camera-solid.svg"
          />
          <span class="texto-barra">{{
            "perfil-usuarios.fotoPerfil" | translate
          }}</span>
          <input
            type="file"
            id="fileUpload"
            class="btn"
            (change)="readURL($event)"
          />
        </label>
        <button
          class="btn"
          [routerLink]="[path.DatosPersonales, user?.uid]"
          routerLinkActive="active"
        >
          <img src="../../assets/imagenes/address-card-solid.svg" />
          <span class="texto-barra">{{
            "perfil-usuarios.misDatos" | translate
          }}</span>
        </button>
        <button
          *ngFor="let permiso of permisos"
          class="btn"
          [routerLink]="permiso.path"
          routerLinkActive="active"
        >
          <img [src]="permiso.img" />
          <span class="texto-barra">{{
            "perfil-usuarios." + permiso.name | translate
          }}</span>
        </button>
      </div>
    </div>
    <div class="col-9">
      <div class="informacion-perfil">
        <h2>{{ nameUser | uppercase }}</h2>
        <br />
        <img
          class="icon-svg"
          src="../../assets/imagenes/star-solid-gold.svg"
          alt="estrella"
          *ngFor="let star of puntuacion"
        />
        <h3>
          {{ "perfil-usuarios.fechaRegistro" | translate }}
          {{ fechaRegistro }}
        </h3>
        <span *ngIf="datosSuscripcion?.suscripcionActual">
          <h3 *ngIf="datosSuscripcion?.suscripcionActual?.fechaInicio">
            {{ "perfil-usuarios.inicioSuscripcion" | translate }}
            {{
              datosSuscripcion.suscripcionActual.fechaInicio
                | timestampToString: "es" : "ll"
            }}
          </h3>
          <h3 *ngIf="datosSuscripcion?.suscripcionActual?.diasRestantes">
            {{ "perfil-usuarios.diasRestantes" | translate }}
            {{ datosSuscripcion.suscripcionActual.diasRestantes }}
          </h3>
        </span>

        <span
          class="vencido-ad"
          *ngIf="
            !datosSuscripcion?.suscripcionActual &&
            datosSuscripcion?.suscripcionVencida
          "
        >
          {{ "perfil-usuarios.suscripcionVencida" | translate }}
        </span>
      </div>
      <div class="form-group">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
