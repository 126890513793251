import { Injectable } from "@angular/core";
import { combineLatest, Observable, of, OperatorFunction } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { FirebaseRequestsService } from "./firebase-requests.service";
import { RegistroFileType } from "../enums/RegistroFile";
import { RegistroCompradorJuridico } from "../models/RegistroCompradorJuridico";
import { RegistroCompradorNatural } from "../models/RegistroCompradorNatural";
import { RegistroFiles, RegistroFilesModel } from "../models/RegistroFiles";
import { RegistroTecnicos } from "../models/RegistroTecnicos";
import { RegistroTranportadores } from "../models/RegistroTransportador";
import { RegistroVendedorJuridico } from "../models/RegistroVendedorJuridico";
import { RegistroVendedorNatural } from "../models/RegistroVendedorNatural";
import { StorageService } from "./storage.service";
import { RegistroEntidad } from "../models/registroEntidad";
import { arrayUnion, DocumentData, where } from "@angular/fire/firestore";
import { UploadTaskSnapshot } from "firebase/storage";

export type UserData =
  | RegistroCompradorNatural
  | RegistroVendedorJuridico
  | RegistroVendedorNatural
  | RegistroTranportadores
  | RegistroTecnicos
  | RegistroEntidad
  | RegistroCompradorJuridico;

@Injectable({
  providedIn: "root",
})
export class UserService {
  userDocument: UserData | undefined;

  constructor(
    private firebaseRequests: FirebaseRequestsService,
    private storageService: StorageService
  ) {}

  async addUser(
    data: UserData,
    files: RegistroFiles,
    uid: string
  ): Promise<void> {
    try {
      await this.firebaseRequests.addMDocFirebaseWithCustomId(
        `usuarios/${uid}`,
        data
      );
      this.userDocument = data;
      this.storageService.uploadMany(files, uid);
    } catch (error) {
      console.error(error);
    }
  }

  async updateUser(data: Partial<UserData>, uid: string): Promise<void> {
    try {
      await this.firebaseRequests.updateDocFirebase(`usuarios/${uid}`, data);
      this.userDocument = { ...this.userDocument, ...data } as UserData;
    } catch (error) {
      console.error(error);
    }
  }

  async addCarroToUser(uid: string, idCarro: string): Promise<void> {
    try {
      await this.firebaseRequests.updateDocFirebase(`usuarios/${uid}`, {
        carroCompras: arrayUnion(idCarro),
      });
    } catch (error) {
      console.error(error);
    }
  }

  clearUser(): void {
    delete this.userDocument;
  }

  // TODO - usar solo una forma de llamar el documento del usuario.
  async getUserData(uid: string): Promise<UserData> {
    try {
      if (!this.userDocument) {
        const userData: UserData =
          await this.firebaseRequests.getDocFirebaseWithIdPromise<UserData>(
            `usuarios/${uid}`
          );
        this.userDocument = userData;
      }

      return this.userDocument;
    } catch (error) {
      console.error(error);
      throw new Error("Error getting user data");
    }
  }

  getRegisterUserStoragePath(docUserId: string, fileName: string): string {
    return `registerUser/${docUserId}/${fileName}`;
  }

  getUserById(id: string): Observable<UserData> {
    return this.firebaseRequests.getDocFirebaseWithIdObservable<UserData>(
      `usuarios/${id}`
    );
  }

  updateAvatarProfile(
    uid: string,
    photo: File
  ): Observable<{
    progress: number;
    snapshot: UploadTaskSnapshot;
  }> {
    const path = `fotoPerfilUser/${uid}`;
    return this.storageService.uploadFile(path, photo);
  }

  async getLogo(uid: string): Promise<string> {
    const pathstring: string = `fotoPerfilUser/${uid}`;
    try {
      return await this.storageService.getStoreUrlImagePromise(
        `${pathstring}_500x500`
      );
    } catch (err) {
      return await this.storageService.getStoreUrlImagePromise(pathstring);
    }
  }

  getRegisterFiles(uid: string): Observable<RegistroFilesModel> {
    const catcher: OperatorFunction<string, string | null> = catchError(() =>
      of(null)
    );

    const rutRequest$: Observable<string | null> = this.storageService
      .getStoreUrlImageObservable(
        this.getRegisterUserStoragePath(uid, RegistroFileType.Rut)
      )
      .pipe(catcher);

    const certificadoRequest$: Observable<string | null> = this.storageService
      .getStoreUrlImageObservable(
        this.getRegisterUserStoragePath(uid, RegistroFileType.Certificado)
      )
      .pipe(catcher);

    return combineLatest([rutRequest$, certificadoRequest$]).pipe(
      map(([rut, certificado]) => ({
        [RegistroFileType.Rut]: rut,
        [RegistroFileType.Certificado]: certificado,
      }))
    );
  }

  checkEmail(email: string): Observable<DocumentData[]> {
    return this.firebaseRequests.getCollectionFirebaseWithQueryObservable<DocumentData>(
      "usuarios",
      [where("email", "==", email)]
    );
  }

  async addProduct(uid: string, idProduct: string): Promise<void> {
    try {
      await this.firebaseRequests.updateDocFirebase(`usuarios/${uid}`, {
        productos: arrayUnion(idProduct),
      });
    } catch (error) {
      console.error(error);
      throw new Error("Error adding product");
    }
  }
}
