import { Component } from "@angular/core";

@Component({
  selector: "app-tree-coin",
  templateUrl: "./tree-coin.component.html",
  styleUrls: ["./tree-coin.component.css"],
})
export class TreeCoinComponent {
  constructor() {}
}
