<div *ngIf="!productosItem.length" class="alert">
  {{ "carro-compras.carroVacio" | translate }}
</div>

<div *ngIf="productosItem.length" class="container-fluid">
  <h2>
    <img src="../../assets/imagenes/shopping-cart-solid.svg" />
    {{ "carro-compras.carroProductos" | translate }}
  </h2>
  <div class="row">
    <div class="col-12">
      <p class="tabla-responsiva-texto">
        {{ "carro-compras.desliceIzquierda" | translate }}
      </p>
      <div class="table-responsive-lg">
        <table class="table table-hover">
          <thead class="thead-dark">
            <tr class="text-center">
              <th scope="col">No</th>
              <th scope="col">
                {{ "formularios.categoria" | translate }}
              </th>
              <th scope="col">
                {{ "formularios.especie" | translate }}
              </th>
              <th scope="col">
                {{ "carro-compras.cantidad" | translate }}
              </th>
              <th scope="col">
                {{ "carro-compras.unidadMedida" | translate }}
              </th>
              <th scope="col">
                {{ "carro-compras.costoUnidad" | translate }}
              </th>
              <th scope="col">
                {{ "carro-compras.subtotal" | translate }}
              </th>
              <th scope="col">
                {{ "formularios.accion" | translate }}
              </th>
            </tr>
          </thead>
          <tbody
            class="contenido_tabla"
            *ngFor="let producto of productosItem; let i = index"
          >
            <tr class="text-center">
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ producto.categoria }}</td>
              <td>{{ producto.especie }}</td>
              <!--Incluir especie-->
              <td>{{ producto.cantidad }}</td>
              <td>{{ producto.unidadMedida }}</td>
              <td>{{ producto.precio | currency }}</td>
              <td>{{ producto.precio * producto.cantidad | currency }}</td>
              <td>
                <button
                  type="button"
                  class="btn btn-danger"
                  (click)="deleteItemCarro(producto.id)"
                >
                  {{ "formularios.eliminar" | translate }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="w-100 text-center">
        <h2>{{ "carro-compras.totalPedido" | translate }}</h2>
        <p>{{ valorTotal | currency }}</p>
      </div>

      <app-limite-pedidos *ngIf="!permitirComprar"></app-limite-pedidos>

      <div class="row text-center" *ngIf="permitirComprar">
        <div class="col-sm-4 mt-1">
          <button
            type="button"
            class="btn btn-warning"
            data-bs-toggle="modal"
            data-bs-target="#solicitarProducto"
          >
            {{ "carro-compras.contactarVendedor" | translate }}
          </button>
        </div>

        <div class="col-sm-4 mt-1">
          <button type="button" class="btn btn-warning" (click)="cotizar()">
            {{ "carro-compras.cotizarProducto" | translate }}
          </button>
        </div>

        <div class="col-sm-4 mt-1">
          <button
            type="button"
            class="btn btn-success"
            [routerLink]="[path.Catalogo] | langRoute | async"
          >
            {{ "carro-compras.anadeProductos" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-cotizacion
  (callBack)="generarCotizacion = $event"
  [currentUserId]="currentUserId"
></app-cotizacion>

<!--modal, ventana emergente para verificar que se quieren solicitar el pedido-->
<div
  class="modal fade"
  id="solicitarProducto"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "carro-compras.estaSeguro" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        {{ "carro-compras.srComprador" | translate }}
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-danger"
          data-bs-dismiss="modal"
          #closeButton
        >
          {{ "formularios.cerrar" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-success"
          (click)="finalizarCompra()"
        >
          {{ "formularios.continuar" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
