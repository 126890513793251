import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Revista } from "../models/Revista";
import { Observable, Subject } from "rxjs";
import { take, takeUntil, tap } from "rxjs/operators";
import {
  AuthenticationState,
  AuthenticationStateModel,
} from "../state/authentication-state";
import { Select } from "@ngxs/store";
import { ModalAuthComponent } from "./modal-auth/modal-auth.component";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TablaComponent } from "../admin/revistas/tabla/tabla.component";
import { RevistasService } from "../services/revistas.service";

@Component({
  selector: "app-noticias",
  templateUrl: "./noticias.component.html",
  styleUrls: ["./noticias.component.css"],
  providers: [TablaComponent],
})
export class NoticiasComponent implements OnInit, OnDestroy {
  private sub$: Subject<void> = new Subject();
  @Select(AuthenticationState)
  authenticationState$!: Observable<AuthenticationStateModel>;
  revistas!: Observable<Revista[]>;
  userIsActive!: boolean;

  constructor(
    private revistasSvc: RevistasService,
    private modalService: NgbModal,
    @Inject(TablaComponent) public tablaComp: TablaComponent
  ) {
    this.authenticationState$
      .pipe(
        takeUntil(this.sub$),
        tap({
          next: (state: AuthenticationStateModel) => {
            this.userIsActive = state.userIsActive;
          },
          error: (error) => {
            console.error(error);
          },
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.traerRevistas();
  }

  ngOnDestroy(): void {
    this.sub$.next();
    this.sub$.complete();
  }

  traerRevistas(): void {
    this.revistas = this.revistasSvc.getRevistas() as Observable<Revista[]>;
  }

  verRevista(revista: Revista): void {
    this.userIsActive ? this.tablaComp.abrirPdf(revista) : this.openAuthModal();
  }

  openAuthModal(): void {
    const modal: NgbModalRef = this.modalService.open(ModalAuthComponent, {
      size: "xs",
      centered: true,
    });
    const { componentInstance } = modal;
    componentInstance.closeEvent
      .pipe(
        takeUntil(this.sub$),
        take(1),
        tap({
          next: () => {
            modal.dismiss();
          },
          error: (error) => {
            console.error(error);
          },
        })
      )
      .subscribe();
  }
}
