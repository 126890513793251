import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { User } from "@angular/fire/auth";
import { DocumentData } from "@angular/fire/firestore";
import { Observable, Subject, of } from "rxjs";
import { map, takeUntil, catchError, tap } from "rxjs/operators";
import { Select } from "@ngxs/store";
import {
  AuthenticationState,
  AuthenticationStateModel,
} from "../../state/authentication-state";
import moment from "moment";
import { UserData, UserService } from "src/app/services/user.service";
import { ComprasService } from "src/app/services/compras.service";
import { ProductoService } from "src/app/services/producto.service";
import { StorageService } from "src/app/services/storage.service";
import { UserDataHelpers } from "src/app/utils/getApellidosUserData";
import { ProductoData } from "src/app/models/ProductoData";
import { PedidosSeleccionados } from "src/app/models/pedidosSeleccionados.interface";

@Component({
  selector: "app-compras",
  templateUrl: "./compras.component.html",
  styleUrls: ["./compras.component.css"],
})
export class ComprasComponent implements OnInit, OnDestroy {
  private unsubscriber: Subject<void> = new Subject();
  private pedidoSeleccionado!: PedidosSeleccionados;
  @ViewChild("cerrarModal") cerrarModal!: ElementRef;
  @Select(AuthenticationState.user)
  user$!: Observable<AuthenticationStateModel["user"]>;
  guiaMostrar: any;
  user!: User;
  currentPage: number = 1;
  itemsPerPage: number = 5;
  misPedidos: any = [];
  originalData: any = [];
  datosCalificacion: any;
  pedidoACalificar: any;
  abrirModalCertificado!: (...args: any[]) => void;
  constructor(
    private userService: UserService,
    private comprasService: ComprasService,
    private productoService: ProductoService,
    private storageService: StorageService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.user$
      .pipe(
        takeUntil(this.unsubscriber),
        tap({
          next: (value: User | null) => {
            if (value) {
              this.user = value;
              this.getPedidos();
            }
          },
          error: (error) => {
            console.log(error);
          },
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  getPedidos(): void {
    this.comprasService
      .getPedidos(this.user.uid)
      .pipe(
        takeUntil(this.unsubscriber),
        tap({
          next: (result: DocumentData[]) => {
            let cont: number = 1;
            let cont2: number = 0;
            const pedidos: (DocumentData | undefined)[] = result.map(
              (pedido: DocumentData) => {
                if (pedido["productos"]) {
                  pedido["productos"].forEach((item: any) => {
                    if (item.idVendedor) {
                      this.getVendedor(item.idVendedor)
                        .pipe(
                          takeUntil(this.unsubscriber),
                          tap({
                            next: (vendedor: any) => {
                              item.vendedor = vendedor; // se guarda info de vendedor
                            },
                            error: (error) => {
                              console.log(error);
                            },
                          })
                        )
                        .subscribe();
                    }
                    this.getProducto(item.idProducto)
                      .pipe(
                        takeUntil(this.unsubscriber),
                        tap({
                          next: (producto: {
                            categoria: string;
                            especie: string;
                          }) => {
                            item.producto = producto; // se guarda info de vendedor
                          },
                          error: (error) => {
                            console.log(error);
                          },
                        })
                      )
                      .subscribe();

                    this.getGuia(item.id)
                      .pipe(
                        takeUntil(this.unsubscriber),
                        tap({
                          next: (guia: string | null) => {
                            if (guia) {
                              item.guia =
                                this.sanitizer.bypassSecurityTrustResourceUrl(
                                  guia
                                );
                            }
                          },
                          error: (error) => {
                            console.log(error);
                          },
                        })
                      )
                      .subscribe();

                    item.pos = cont;
                    cont++;
                  });

                  pedido["realPos"] = cont2;
                  pedido["fechaFormat"] = moment(pedido["fecha"].seconds * 1000)
                    .locale("es-US")
                    .format("DD/MM/YYYY");
                  cont2 += 1;
                  return pedido;
                }

                return;
              }
            );
            this.misPedidos = pedidos
              .filter((value: DocumentData | undefined) => value != undefined)
              .sort((a: any, b: any) => b.fecha.seconds - a.fecha.seconds);
          },
          error: (error) => {
            console.log(error);
          },
        })
      )
      .subscribe();
  }

  getVendedor(id: string): Observable<string> {
    return this.userService
      .getUserById(id)
      .pipe(
        map(
          (user: UserData) =>
            `${user.nombres || ""} ${UserDataHelpers.getApellidos(user)}`
        )
      );
  }

  getProducto(id: string): Observable<{ categoria: string; especie: string }> {
    return this.productoService.getProductById(id).pipe(
      map((producto: ProductoData) => {
        const { categoria, especie } = producto || {
          categoria: "",
          especie: "",
        };

        return { categoria, especie };
      })
    );
  }

  async recibir(): Promise<void> {
    const { itemPos, productPos, valor } = this.pedidoSeleccionado;
    const itemPedido: any = JSON.parse(JSON.stringify(itemPos));
    const id: string = itemPedido.id;
    const producto: any = itemPedido.productos[productPos];
    producto.recibido = valor;

    if (producto.vendido && !Object.hasOwnProperty.call(producto, "recibido")) {
      producto.estado = "pendiente";
    } else if (producto.vendido && producto.recibido) {
      producto.estado = "Finalizado";
    } else {
      producto.estado = "Rechazado";
    }
    producto.recibidoModificado = true;
    delete producto.vendedor;
    delete producto.producto;
    delete itemPedido.realPos;
    delete producto.guia;

    try {
      await this.comprasService.updatePedido(id, itemPedido);

      if (!valor) {
        const idProducto: any = producto.idProducto;
        const cantidad: any = producto.cantidad;

        await this.productoService.restaurarUnidadesDisponibles(
          idProducto,
          cantidad
        );
      }
    } catch (error) {
      console.warn("error al cambiar estado recibido de pedido: ", error);
    }
  }

  calificar(itemPos: any, productPos: any): void {
    const itemPedido: any = JSON.parse(JSON.stringify(itemPos));
    this.pedidoACalificar = { itemPedido, productPos };
    const datos = {
      idCompra: itemPedido.id,
      idCalificador: itemPedido.idComprador,
      idCalificado: itemPedido.productos[productPos].idVendedor,
      idItem: itemPedido.productos[productPos].id,
    };
    this.datosCalificacion = datos;
  }

  async marcarCalificado(id: string): Promise<void> {
    const pedido: any = this.pedidoACalificar.itemPedido;
    const productPos: any = this.pedidoACalificar.productPos;
    pedido.productos[productPos].calificacionComprador = id;
    delete pedido.productos[productPos].vendedor;
    delete pedido.productos[productPos].producto;

    try {
      await this.comprasService.updatePedido(pedido.id, pedido);
      this.cancelarCalificacion();
    } catch (err) {
      console.warn("error al cambiar estado recibido de pedido: ", err);
    }
  }

  cancelarCalificacion(): void {
    this.cerrarModal.nativeElement.click();
    delete this.pedidoACalificar;
  }

  getGuia(idItem: string): Observable<string | null> {
    const ruta: string = `guiaEnvio/${idItem}`;
    return this.storageService
      .getStoreUrlImageObservable(ruta)
      .pipe(catchError(() => of(null)));
  }

  seleccionarPedido(itemPos: any, productPos: any, valor: boolean): void {
    this.pedidoSeleccionado = { itemPos, productPos, valor };
  }
}
