import { Component, OnDestroy } from "@angular/core";
import { DocumentData } from "@angular/fire/firestore";
import { Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import { Permiso } from "src/app/models/permisosinterface";
import { Rol } from "src/app/models/rol.interface";
import { PermisosService } from "src/app/services/permisos.service";
import { permisos } from "src/app/utils/userPermisos";

@Component({
  selector: "app-permisos",
  templateUrl: "./permisos.component.html",
  styleUrls: ["./permisos.component.css"],
})
export class PermisosComponent implements OnDestroy {
  private unsubscriber: Subject<void> = new Subject();
  roles: Rol[] = [];
  permisosGenerales: Permiso[] = permisos;
  currentRole!: string;
  permisosRole: string[] = [];

  constructor(private permisosSvc: PermisosService) {
    this.permisosSvc
      .getRoles()
      .pipe(
        takeUntil(this.unsubscriber),
        tap({
          next: (result: DocumentData[]) => {
            this.roles = result as Rol[];
            result.length &&
              !this.currentRole &&
              this.selectRole(this.roles[0]);
          },
          error: (error) => {
            console.log(error);
          },
        })
      )
      .subscribe();
  }

  check(permiso: Permiso): void {
    const { name } = permiso;

    const exists = this.permisosRole.includes(name);

    !exists
      ? this.permisosRole.push(name)
      : (this.permisosRole = this.permisosRole.filter((p) => p !== name));
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  selectRole(role: Rol): void {
    this.currentRole = role.id;
    this.permisosRole = role.permisos;
  }

  isChecked(permiso: Permiso) {
    return this.permisosRole.includes(permiso.name);
  }

  updateRole(): void {
    this.permisosSvc
      .updateRole(this.currentRole, this.permisosRole)
      .then(() => alert("permisos actualizados"))
      .catch(() => alert("error al actualizar permisos"));
  }
}
