import { Injectable } from "@angular/core";
import { CrearClasificado } from "src/app/models/crearClasificado";
import { FirebaseRequestsService } from "./firebase-requests.service";
import {
  DocumentData,
  DocumentReference,
  orderBy,
  where,
} from "@angular/fire/firestore";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ClasificadosService {
  constructor(private firebaseRequests: FirebaseRequestsService) {}

  async addClasificado(data: CrearClasificado): Promise<string> {
    try {
      const response: DocumentReference =
        await this.firebaseRequests.addDocFirebaseWithAutomaticId(
          "clasificados",
          data
        );

      return response.id;
    } catch (error) {
      console.error(error);
      throw new Error("Could not add a rating");
    }
  }

  getClasificadoById(userId: string): Observable<DocumentData[]> {
    return this.firebaseRequests.getCollectionFirebaseWithQueryObservable<DocumentData>(
      "clasificados",
      [orderBy("tiempoCreado", "desc"), where("userID", "==", userId)]
    );
  }

  deleteClasificado(id: string): Promise<void> {
    return this.firebaseRequests.deleteDocFirebase(`clasificados/${id}`);
  }

  async getAllClasificados(): Promise<DocumentData[]> {
    try {
      const clasificados: DocumentData[] =
        await this.firebaseRequests.getCollectionFirebasePromiseWithId<DocumentData>(
          "clasificados"
        );
      return clasificados;
    } catch (err) {
      throw new Error("Error getting ratings");
    }
  }
}
