import { Component, Input, OnChanges, OnDestroy } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Path } from "../enums/Path";
import { ConversacionComponent } from "../perfil-usuarios/conversacion/conversacion.component";
import { takeUntil, tap } from "rxjs/operators";
import { firstValueFrom, Subject } from "rxjs";
import { PreguntasService } from "../services/preguntas.service";
import { Preguntas } from "../models/preguntas.interface";

@Component({
  selector: "app-mensajes",
  templateUrl: "./mensajes.component.html",
  styleUrls: ["./mensajes.component.css"],
})
export class MensajesComponent implements OnChanges, OnDestroy {
  @Input() idVendedor!: string;
  @Input() idComprador!: string;

  private unsubscriber: Subject<void> = new Subject();
  path: typeof Path = Path;
  mensajes: Preguntas[] = [];
  idProducto!: string;

  constructor(
    private preguntasSvc: PreguntasService,
    private modal: NgbModal
  ) {}

  ngOnChanges(): void {
    if (this.idVendedor) {
      this.preguntasSvc
        .getPreguntasVentas(this.idVendedor)
        .pipe(
          takeUntil(this.unsubscriber),
          tap({
            next: (res: Preguntas[]) => {
              this.mensajes = res;
            },
            error: (error) => {
              console.error(error);
            },
          })
        )
        .subscribe();
    }

    if (this.idComprador) {
      this.preguntasSvc
        .getPreguntasCompras(this.idComprador)
        .pipe(
          takeUntil(this.unsubscriber),
          tap({
            next: (res: Preguntas[]) => {
              this.mensajes = res;
            },
            error: (error) => {
              console.error(error);
            },
          })
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  verMensajes(mensaje: Preguntas): void {
    const modal = this.modal.open(ConversacionComponent, {
      scrollable: false,
      size: "lg",
      backdrop: "static",
    });
    modal.componentInstance.idConversacion = mensaje.id;
    modal.componentInstance.idCliente = this.idComprador
      ? mensaje.idVendedor
      : mensaje.idRemitente;
    modal.componentInstance.idUsuario = this.idComprador
      ? this.idComprador
      : this.idVendedor;

    firstValueFrom(modal.componentInstance.closeEvent as Subject<boolean>)
      .then(() => {
        modal.close();
      })
      .catch((error: Error) => console.error(error));
  }
}
