import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DocumentData, Timestamp } from "@angular/fire/firestore";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UploadTaskSnapshot } from "firebase/storage";
import { Subject, Subscription } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import { Aliado } from "src/app/models/aliado.interface";
import { AliadosService } from "src/app/services/aliados.service";
import { v4 as uuidv4 } from "uuid";

@Component({
  selector: "app-aliados",
  templateUrl: "./aliados.component.html",
  styleUrls: ["./aliados.component.css"],
})
export class AliadosComponent implements OnInit, OnDestroy {
  @ViewChild("btnModal", { static: true }) btnModal!: ElementRef;
  @ViewChild("btnCerrarModal") btnCerrarModal!: ElementRef;
  @ViewChild("btnModalBorrar") btnModalBorrar!: ElementRef;
  @ViewChild("cerrarModalBorrar") cerrarModalBorrar!: ElementRef;

  private unsubscriber: Subject<boolean> = new Subject();
  private subscription$!: Subscription;
  forms: { [key: string]: FormGroup } = {
    formCrear: this.fb.group({}),
    formModal: this.fb.group({}),
  };

  imagenes: any = {};
  listaAliados!: Aliado[];
  currentForm!: string;
  aliadoActual!: Aliado;

  constructor(
    private fb: FormBuilder,
    private aliados: AliadosService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.listarAliados();
  }

  ngOnDestroy(): void {
    this.unsubscriber.next(true);
  }

  private extractChangedData(): Partial<Aliado> {
    const changedData: Partial<Aliado> = {};

    Object.keys(this.forms["formModal"].value).forEach((key: string) => {
      if (
        this.aliadoActual[key as keyof Aliado] !==
        this.forms["formModal"].value[key]
      ) {
        changedData[key as keyof Aliado] = this.forms["formModal"].value[key];
      }
    });

    return changedData;
  }
  private async updateAliado(newData: Partial<Aliado>): Promise<void> {
    if (this.aliadoActual.id) {
      try {
        await this.aliados.update(this.aliadoActual.id, newData);
        alert("Aliado Modificado");
        this.btnCerrarModal.nativeElement.click();
      } catch (e) {
        console.error(e);
      }
    }
  }

  private async handleImageUpdate(newData: Partial<Aliado>): Promise<void> {
    if (this.aliadoActual.id) {
      this.subscription$ = this.aliados
        .saveImg(this.aliadoActual.id, this.imagenes.formModal)
        .pipe(
          tap({
            next: async (value: {
              progress: number;
              snapshot: UploadTaskSnapshot;
            }) => {
              if (
                value.progress === 100 &&
                value.snapshot.state === "success"
              ) {
                try {
                  delete newData.imagen;
                  await this.updateAliado(newData);
                  this.subscription$.unsubscribe();
                } catch (e) {
                  console.error(e);
                }
              } else if (
                value.snapshot.state === "error" ||
                value.snapshot.state === "canceled"
              ) {
                window.alert("Error during image upload");
                this.subscription$.unsubscribe();
              }
            },
            error: (err) => {
              this.subscription$.unsubscribe();
              console.error(err);
              window.alert("Error during image upload");
            },
          })
        )
        .subscribe();
    }
  }

  initForm() {
    this.forms["formCrear"] = this.fb.group({
      nombre: ["", Validators.required],
      descripcion: ["", Validators.required],
      enlace: ["", Validators.required],
      tipo: ["", Validators.required],
      imagen: ["", Validators.required],
    });

    this.forms["formModal"] = this.fb.group({
      nombre: ["", Validators.required],
      descripcion: ["", Validators.required],
      enlace: ["", Validators.required],
      tipo: ["", Validators.required],
      imagen: ["", Validators.required],
    });
  }

  seleccionarFoto(event: any): void {
    const file: File = event.target.files[0];
    const reader: FileReader = new FileReader();
    if (file) {
      reader.onloadend = () => {
        this.forms[this.currentForm].get("imagen")?.setValue(reader.result);
        this.imagenes[this.currentForm] = this.imagenes[this.currentForm]
          ? this.imagenes[this.currentForm]
          : {};
        this.imagenes[this.currentForm] = file;
      };
      reader.readAsDataURL(file);
    }
  }

  crear(): void {
    const data: Aliado = { ...this.forms["formCrear"].value };
    delete data.imagen;

    data.fechaCreacion = Timestamp.now();
    try {
      const idDoc: string = uuidv4();

      this.subscription$ = this.aliados
        .saveImg(idDoc, this.imagenes.formCrear)
        .pipe(
          tap({
            next: async (value: {
              progress: number;
              snapshot: UploadTaskSnapshot;
            }) => {
              if (
                value.progress === 100 &&
                value.snapshot.state === "success"
              ) {
                await this.aliados.create(idDoc, data);
                window.alert("aliado guardado");
                this.forms["formCrear"].reset();
                delete this.imagenes.formCrear;
                this.subscription$.unsubscribe();
              } else if (
                value.snapshot.state === "error" ||
                value.snapshot.state === "canceled"
              ) {
                window.alert("error");
                this.subscription$.unsubscribe();
              }
            },
            error: (err) => {
              this.subscription$.unsubscribe();
              console.error(err);
              window.alert("error");
            },
          })
        )
        .subscribe();
    } catch (err) {
      console.error(err);
    }
  }

  listarAliados(): void {
    this.aliados
      .getAllObservsable()
      .pipe(
        takeUntil(this.unsubscriber),
        tap({
          next: (value: DocumentData[]) => {
            this.listaAliados = value as Aliado[];
          },
          error(err) {
            console.error(err);
          },
        })
      )
      .subscribe();
  }

  openEdit(aliado: Aliado): void {
    this.aliadoActual = aliado;
    const datos = { ...aliado };
    this.forms["formModal"].patchValue(datos);
    this.btnModal.nativeElement.click();
  }

  async actualizar(): Promise<void> {
    try {
      if (!this.forms["formModal"].valid) {
        return;
      }

      const newData: Partial<Aliado> = this.extractChangedData();

      if (Object.keys(newData).length > 0) {
        if (Object.keys(newData).includes("imagen") && this.aliadoActual.id) {
          this.handleImageUpdate(newData);
          return;
        }

        await this.updateAliado(newData);
      }
    } catch (error) {
      console.error("Error during update:", error);
      window.alert("Error during update. Please try again.");
    }
  }

  delete(): void {
    if (this.aliadoActual.id) {
      this.aliados
        .delete(this.aliadoActual.id)
        .then(() => {
          alert("Aliado Eliminado");
          this.cerrarModalBorrar.nativeElement.click();
        })
        .catch(() => alert("Error al eliminar aliado"));
    }
  }

  openDeleteModal(aliado: Aliado): void {
    this.aliadoActual = aliado;
    this.btnModalBorrar.nativeElement.click();
  }
}
