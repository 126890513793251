<h3 class="titulo-contenido-perfil">
  {{ "perfil-usuarios.reportes" | translate }}
</h3>
<p>
  <b>{{ "reportes.total_reports" | translate }}</b> {{ totalReports }}
</p>
<p>
  {{ "reportes.parrafo_reportes" | translate }}
</p>
<!--grid para las licencias, llamar ngfor para traer los datos. importante paginar, revisar otras secciones que ya hay implementaciones-->
<div class="table-responsive-lg">
  <table class="table table-hover">
    <thead class="thead-dark">
      <tr>
        <th scope="col">{{ "licencias.title_tecnico" | translate }}</th>
        <th scope="col">No. {{ "perfil-usuarios.reportes" | translate }}</th>
        <th scope="col">{{ "licencias.fechaActivacion" | translate }}</th>
        <th scope="col">Correo</th>
        <th scope="col">celular</th>
        <th scope="col">{{ "formularios.accion" | translate }}</th>
      </tr>
    </thead>
    <tbody class="contenido-tabla">
      <tr *ngFor="let reportsByUser of allReportsByUser$ | async">
        <td>{{ reportsByUser.wtUserId | wtUserName | async }}</td>
        <td>{{ reportsByUser.wtReports.length }}</td>
        <td>{{ reportsByUser.wtUserId | wtUserLicenseDate | async }}</td>
        <td>{{ reportsByUser.wtUserId | wtUserEmail | async }}</td>
        <td>{{ reportsByUser.wtUserId | wtUserMovil | async }}</td>
        <td>
          <button
            type="button"
            class="btn btn-success"
            (click)="openReportModal(reportsByUser.wtUserId)"
          >
            {{ "reportes.ver_reporte" | translate }}
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<!--Modal para las Reportes-->
<div
  class="modal fade"
  id="reportesModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "perfil-usuarios.reportes" | translate }}
        </h5>
      </div>
      <div class="modal-body">
        <!--En esta tabla se llaman cada uno de los reportes para descargar-->
        <div class="table-responsive-lg">
          <table class="table table-hover">
            <thead class="thead-dark">
              <tr>
                <th scope="col">{{ "formularios.fecha" | translate }}</th>
                <th scope="col">{{ "formularios.municipio" | translate }}</th>
                <th scope="col">{{ "formularios.accion" | translate }}</th>
              </tr>
            </thead>
            <tbody class="contenido-tabla">
              <tr>
                <td>15/05/2023</td>
                <td>Melgar</td>
                <td>
                  <button
                    type="button"
                    class="btn btn-success"
                    data-bs-toggle="modal"
                    data-bs-target="#reportesModal"
                  >
                    {{ "cotizacion.descargar" | translate }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
          {{ "formularios.cerrar" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
