import { Timestamp } from "@angular/fire/firestore";

/**
 * Converts a date into firestore timestamp
 *
 * @param date Date object
 * @returns firestore Timestamp
 */
export const dateToTimestamp = (date: Date): Timestamp => {
  return Timestamp.fromDate(date);
};

/**
 * Converts a firestore timestampt into a date
 *
 * @param timestamp firestore Timestamp
 * @returns Date object
 */
export const timestampToDate = (timestamp: Timestamp | undefined): Date => {
  if (!timestamp) {
    return new Date();
  }
  return new Date(timestamp.seconds * 1000);
};
