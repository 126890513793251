import { ElementRef, ViewChild } from "@angular/core";
import { Component, Input, OnChanges } from "@angular/core";
import { FormArray, FormBuilder, FormControl } from "@angular/forms";
import { AUTORIDADAMBIENTAL } from "src/app/models/autoridadesAmbientales";
import { UserAdminState } from "../usuarios/usuarios.component";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-entidades-usuario",
  templateUrl: "./entidades-usuario.component.html",
  styleUrls: ["./entidades-usuario.component.css"],
})
export class EntidadesUsuarioComponent implements OnChanges {
  @Input() user!: UserAdminState | undefined;
  @ViewChild("selectEntidad") selectEntidad!: ElementRef;
  entidadesForm!: FormArray<FormControl<string | null>>;
  entidadesOpciones = AUTORIDADAMBIENTAL;

  constructor(
    private fb: FormBuilder,
    private userService: UserService
  ) {
    this.initForm();
  }

  ngOnChanges(): void {
    this.initForm();
    if (this.user) {
      this.entidadesForm.reset();
      const { entidades } = this.user;
      entidades?.forEach((entidad: string) => {
        this.entidadesForm.push(new FormControl(entidad));
      });
    }
  }

  initForm(): void {
    this.entidadesForm = this.fb.array<string | null>([]);
  }

  add(value: string): void {
    if (!this.entidadesForm.value.includes(value)) {
      this.entidadesForm.push(new FormControl(value));
      this.selectEntidad.nativeElement.value = "";
      this.updateEntidades();
    }
  }

  remove(value: string | null): void {
    if (!value) return;

    this.entidadesForm.controls = this.entidadesForm.controls.filter(
      (entidad) => entidad.value !== value
    );
    this.entidadesForm.setValue(
      this.entidadesForm.value.filter((entidad) => entidad !== value)
    );
    this.updateEntidades();
  }

  async updateEntidades(): Promise<void> {
    try {
      if (this.user) {
        await this.userService.updateUser(
          {
            entidades: this.entidadesForm.value as string[],
          },
          this.user.id
        );
      }

      alert("Entidades actualizadas");
    } catch (error) {
      console.error(error);
    }
  }
}
