import { Pipe, PipeTransform } from "@angular/core";
import { Timestamp } from "@angular/fire/firestore";
import moment from "moment";

@Pipe({
  name: "timestampToString",
})
export class TimestampToStringPipe implements PipeTransform {
  transform(
    value: Timestamp | undefined,
    locale: string = "es",
    format: string = "D  MMMM [de] YYYY hh[:]mm a"
  ): string {
    if (value) {
      const dateString: string = moment(value.seconds * 1000)
        .locale(locale)
        .format(format);
      return dateString;
    } else {
      return "";
    }
  }
}
