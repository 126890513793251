import {
  AfterViewChecked,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Timestamp } from "@angular/fire/firestore";
import { Subject, Observable } from "rxjs";
import { takeUntil, catchError, take, tap } from "rxjs/operators";
import { PreguntasService } from "src/app/services/preguntas.service";
import { StorageService } from "src/app/services/storage.service";
import { UserData, UserService } from "src/app/services/user.service";
import { Preguntas, Respuesta } from "src/app/models/preguntas.interface";
import { UserDataHelpers } from "src/app/utils/getApellidosUserData";

@Component({
  selector: "app-conversacion",
  templateUrl: "./conversacion.component.html",
  styleUrls: ["./conversacion.component.scss"],
})
export class ConversacionComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  private unsubscriber: Subject<void> = new Subject();
  @ViewChild("chatScroll") private scroll!: ElementRef;
  @Input() idConversacion!: string;
  @Input() idUsuario!: string;
  @Input() idCliente!: string;

  closeEvent: Subject<boolean> = new Subject<boolean>();
  fotoUsuario!: Observable<string>;
  nombreCliente!: string;
  mensajes: Respuesta[] = [];

  constructor(
    private preguntasSvc: PreguntasService,
    private storage: StorageService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.traerDatosCliente();

    this.preguntasSvc
      .getPreguntaById(this.idConversacion)
      .pipe(
        takeUntil(this.unsubscriber),
        tap({
          next: (preguntas: Preguntas[]) => {
            this.mensajes = preguntas[0].respuestas;
          },
          error: (error) => {
            console.error(error);
          },
        })
      )
      .subscribe();
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  toClose() {
    this.closeEvent.next(true);
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  async traerDatosCliente() {
    this.fotoUsuario = this.storage
      .getStoreUrlImageObservable(`fotoPerfilUser/${this.idCliente}_500x500`)
      .pipe(
        catchError(() => {
          return this.storage.getStoreUrlImageObservable(
            `fotoPerfilUser/${this.idCliente}`
          );
        })
      );

    this.userService
      .getUserById(this.idCliente)
      .pipe(
        take(1),
        tap((user: UserData) => {
          this.nombreCliente = `${user.nombres || ""} ${UserDataHelpers.getApellidos(user)}`;
        })
      )
      .subscribe();
  }

  enviarMensaje(mensaje: HTMLInputElement) {
    if (!mensaje.value) return;

    const respuesta: Respuesta = {
      mensaje: mensaje.value,
      fecha: Timestamp.now(),
      idUsuario: this.idUsuario,
    };

    this.preguntasSvc
      .addRespuesta(this.idConversacion, respuesta)
      .then(() => {
        mensaje.value = "";
      })
      .catch(console.error);
  }

  scrollToBottom(): void {
    const { scrollHeight } = this.scroll.nativeElement;
    this.scroll.nativeElement.scrollTop = scrollHeight;
  }
}
