import { Component } from "@angular/core";
import { User } from "@angular/fire/auth";
import { Observable } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { TimeStamp } from "src/app/core/types/time-stamp.type";
import { AuthService } from "src/app/services/auth.service";
import { WtLicense } from "src/app/woodtracer/model/wt-license";
import { WtLicenseService } from "src/app/woodtracer/service/wt-license.service";

@Component({
  selector: "app-licencias",
  templateUrl: "./licencias.component.html",
  styleUrls: ["./licencias.component.css"],
})
export class LicenciasComponent {
  /**
   * Observable con las licencias asociadas a la 'entidad'.
   */
  public licenses$!: Observable<WtLicense[]>;

  /** Acomulador con el total de licencias de la 'entidad'. */
  public totalLicences: number = 0;

  /** Acomulador con el total de licencias activas de la 'entidad'. */
  public activeLicences: number = 0;

  /** Acomulador con el total de licencias inactivas de la 'entidad'. */
  public inactiveLicences: number = 0;

  /**
   * Variable con la Licencia a desvincular.
   * Null si no hay una licencia a desvincular.
   */
  private paraDesvincular!: WtLicense | null;

  constructor(
    protected crud: WtLicenseService,
    private authService: AuthService
  ) {
    this.licenses$ = this.authService.authStateChanged().pipe(
      switchMap((user: User | null) => {
        this.crud.paginate({
          paginate: "infinite",
          sorts: [{ field: "ends", direction: "desc" }],
          filters: [
            { field: "wtCompanyId", queryOperator: "==", value: user?.uid },
          ],
        });

        return this.crud.collection$;
      }),
      tap({
        next: (licenses: WtLicense[]) => {
          if (licenses) {
            if (licenses.length > 0) {
              this.totalLicences = licenses.length;
              this.activeLicences = licenses.filter((license) =>
                this.licenseIsActive(license)
              ).length;
              this.inactiveLicences = this.totalLicences - this.activeLicences;
            }
          }
        },
      })
    );

    this.licenses$.subscribe((licences) => {
      console.log("licences", licences);
    });
  }

  /**
   * Handler para el evento click del botón 'Desvincular'.
   * Guarda la licencia a desvincular a la espera de la confirmación.
   *
   * @param license
   */
  public onDesvincular(license: WtLicense): void {
    this.paraDesvincular = license;
  }

  /**
   * Handler para el evento click del botón 'Confirmar desvincular'.
   * Desvincula la licencia guardada.
   */
  public async onDesvincularConfirm(): Promise<void> {
    /**
     * @todo @mario Poner a cargar para que se bloquee la interfaz.
     */
    try {
      if (this.paraDesvincular) {
        await this.crud.update(this.paraDesvincular.id, { wtUserId: "" });
      }
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Handler para el evento click del botón 'Cancelar desvincular'.
   * Borrar la licencia guardada para desvincular.
   */
  public onDesvincularCancel(): void {
    this.paraDesvincular = null;
  }

  /**
   * Devuelve un boolean indicando si la licencia está activa.
   *
   * @param licence
   * @returns boolean
   */
  public licenseIsActive(licence: WtLicense): boolean {
    return (
      licence.status === "active" &&
      (licence.begins as TimeStamp).toDate().getTime() < Date.now() &&
      (licence.ends as TimeStamp).toDate().getTime() > Date.now()
    );
  }
}
