import { Injectable } from "@angular/core";
import { FirebaseRequestsService } from "./firebase-requests.service";
import { orderBy } from "@angular/fire/firestore";
import { Especie } from "../models/especie.interface";

@Injectable({
  providedIn: "root",
})
export class EspeciesService {
  private collection: string = "especies";

  constructor(private firebaseRequests: FirebaseRequestsService) {}

  getAll(): Promise<Especie[]> {
    return this.firebaseRequests.getCollectionFirebasePromiseWithId<Especie>(
      this.collection,
      [orderBy("nombre", "asc")]
    );
  }

  async update(id: string, nombre: string): Promise<void> {
    try {
      await this.firebaseRequests.updateDocFirebase(
        `${this.collection}/${id}`,
        { nombre }
      );
      alert("especie actualizada");
    } catch (e) {
      alert("error actualizando especie");
    }
  }

  async delete(id: string): Promise<void> {
    try {
      await this.firebaseRequests.deleteDocFirebase(`${this.collection}/${id}`);
      alert("especie eliminada");
    } catch (e) {
      alert("error eliminando especie");
    }
  }

  async crear(nombre: string): Promise<void> {
    try {
      await this.firebaseRequests.addDocFirebaseWithAutomaticId(
        this.collection,
        { nombre }
      );
      alert("especie creada");
    } catch (e) {
      alert("error creando especie");
    }
  }
}
