<div class="modal-header">
  <div class="col text-center">
    <h4>Actualización de correo</h4>
  </div>
</div>
<div class="modal-body">
  <form [formGroup]="emailForm" class="mt-2 email-form">
    <div class="row">
      <div class="col-8">
        <div class="form-group">
          <!-- <label for="emailVendedor">Email</label> -->
          <input
            type="text"
            [disabled]="loading"
            placeholder="Email"
            id="emailVendedor"
            class="form-control"
            formControlName="email"
            [ngClass]="{
              'is-invalid':
                emailForm.get('email')?.invalid &&
                emailForm.get('email')?.touched,
              'is-valid': emailForm.get('email')?.valid
            }"
          />
          <div
            *ngIf="
              emailForm.get('email')?.errors && emailForm.get('email')?.touched
            "
          >
            <small
              class="text-danger"
              *ngIf="emailForm.get('email')?.hasError('required')"
            >
              Campo requerido
            </small>
            <small
              class="text-danger"
              *ngIf="emailForm.get('email')?.hasError('pattern')"
            >
              Correo mal escrito
            </small>
            <small
              class="text-danger"
              *ngIf="emailForm.get('email')?.hasError('isUniqueEmail')"
            >
              Email ya está siendo usado
            </small>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="text-center" (click)="actualizarCorreo()">
          <img
            *ngIf="loading"
            class="img-gif"
            src="../../assets/imagenes/loading_gif.gif"
            alt="cargando"
          />
          <button
            type="submit"
            class="w-100 btn btn-warning text-light"
            [disabled]="emailForm.invalid || loading"
          >
            <span class="text-light"> Actualizar </span>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    type="button"
    (click)="cerrar.next(true)"
    class="btn btn-sm btn-danger"
  >
    Cancelar
  </button>
</div>
