import { Component, Input, OnChanges, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Select } from "@ngxs/store";
import { Subject } from "rxjs";
import { Observable } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import {
  AuthenticationState,
  AuthenticationStateModel,
} from "../state/authentication-state";
import { PreguntasService } from "../services/preguntas.service";
import { Preguntas, Respuesta } from "../models/preguntas.interface";
import { Timestamp } from "@angular/fire/firestore";
import { User } from "@angular/fire/auth";

@Component({
  selector: "app-preguntas",
  templateUrl: "./preguntas.component.html",
  styleUrls: ["./preguntas.component.css"],
})
export class PreguntasComponent implements OnChanges, OnDestroy {
  @Input() idProducto!: string;
  @Input() idVendedor!: string;
  @Input() panelCompleto!: boolean;
  @Select(AuthenticationState.user)
  user$!: Observable<AuthenticationStateModel["user"]>;

  private unsubscriber: Subject<void> = new Subject();
  permitirResponder: boolean = false;
  formPregunta!: FormGroup;
  listaPreguntas!: Preguntas[];
  usuarioActivo!: string;
  currentPage: number = 1;
  itemsPerPage: number = 5;

  trackPreguntas = (index: number, item: Preguntas) => item.id;

  trackRespuestas = (index: number, item: Respuesta) => item.fecha;

  constructor(
    private fb: FormBuilder,
    private preguntaSvc: PreguntasService
  ) {}

  ngOnChanges(): void {
    if (this.idProducto && this.idVendedor) {
      this.preguntaSvc
        .getPreguntas(this.idProducto)
        .pipe(
          takeUntil(this.unsubscriber),
          tap({
            next: (res: Preguntas[]) => {
              this.listaPreguntas = res;
            },
            error: (error) => {
              console.error(error);
            },
          })
        )
        .subscribe();

      this.getDatosVendedor();
    }
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  getDatosVendedor(): void {
    this.initForm();
    this.user$
      .pipe(
        takeUntil(this.unsubscriber),
        tap({
          next: (user: User | null) => {
            if (!user) return;

            this.usuarioActivo = user.uid;
            this.permitirResponder = user.uid === this.idVendedor;
            this.formPregunta.get("idVendedor")?.setValue(this.idVendedor);
            this.formPregunta.get("idProducto")?.setValue(this.idProducto);
            this.formPregunta.get("idRemitente")?.setValue(user.uid);
          },
          error: (error) => {
            console.error(error);
          },
        })
      )
      .subscribe();
  }

  initForm(): void {
    this.formPregunta = this.fb.group({
      mensaje: ["", Validators.required],
      idProducto: ["", Validators.required],
      idRemitente: ["", Validators.required],
      idVendedor: ["", Validators.required],
    });
  }

  guardarPregunta(): void {
    if (this.formPregunta.valid) {
      const data: Preguntas = { ...this.formPregunta.value };
      data.fechaCreacion = Timestamp.now();

      this.preguntaSvc
        .savePregunta(data)
        .then(() => {
          alert("Pregunta generada correctamente");
          this.formPregunta.get("mensaje")?.reset();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  responder(idPregunta: string, mensaje: string): void {
    const respuesta: Respuesta = {
      mensaje,
      fecha: Timestamp.now(),
      idUsuario: this.usuarioActivo,
    };

    this.preguntaSvc
      .addRespuesta(idPregunta, respuesta)
      .then(() => {
        mensaje = "";
      })
      .catch(console.error);
  }

  activarCollapse(id: string): void {
    const element: string = `collapseresp${id}`;
    document.getElementById(element)?.classList.toggle("show");
  }
}
