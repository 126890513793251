import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { StoreServiceInterface } from "../interface/store-service.interface";

/**
 * StoreService abstracto.
 */
export abstract class StoreService<S> implements StoreServiceInterface<S> {
  public state$: Observable<S>;
  public state: S;
  public previous: S | null = null;

  protected bs: BehaviorSubject<S>;

  public abstract store: string;

  constructor(initialValue: Partial<S>) {
    this.bs = new BehaviorSubject<S>(initialValue as S);
    this.state$ = this.bs.asObservable();

    this.state = initialValue as S;
    this.state$.subscribe((s) => {
      this.state = s;
    });
  }

  patch(newValue: Partial<S>, event: string = "Not specified"): void {
    this.previous = this.state;
    const newState = Object.assign({}, this.state, newValue);
    if (!environment.production) {
      console.groupCollapsed(`[${this.store} store] [patch] [event: ${event}]`);
      console.log("change", newValue);
      console.log("prev", this.previous);
      console.log("next", newState);
      console.groupEnd();
    }
    this.bs.next(newState);
  }

  set(newValue: Partial<S>, event: string = "Not specified"): void {
    this.previous = this.state;
    const newState = Object.assign({}, newValue) as S;
    if (!environment.production) {
      console.groupCollapsed(`[${this.store} store] [set] [event: ${event}]`);
      console.log("change", newValue);
      console.log("prev", this.previous);
      console.log("next", newState);
      console.groupEnd();
    }
    this.bs.next(newState);
  }
}
