import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgChartsModule } from "ng2-charts";
import { NgxPaginationModule } from "ngx-pagination";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UsuariosComponent } from "./usuarios/usuarios.component";
import { EstadisticasComponent } from "./estadisticas/estadisticas.component";
import { ComentariosComponent } from "./comentarios/comentarios.component";
import { PublicidadComponent } from "./publicidad/publicidad.component";
import { FacturacionComponent } from "./facturacion/facturacion.component";
import { AliadosComponent } from "./aliados/aliados.component";
import { AdminRoutingModule } from "./admin-routing.module";
import { PermisosComponent } from "./permisos/permisos.component";
import { SuscripcionUsuarioComponent } from "./suscripcion-usuario/suscripcion-usuario.component";
import { EntidadesUsuarioComponent } from "./entidades-usuario/entidades-usuario.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ApplicationPipesModule } from "../application-pipes/application-pipes-module";
import { EspeciesComponent } from "./especies/especies.component";
import { CambioCorreoComponent } from "./usuarios/cambio-correo/cambio-correo.component";
import { RevistasComponent } from "./revistas/revistas.component";
import { TablaComponent } from "./revistas/tabla/tabla.component";
import { PerfilUsuariosModule } from "../perfil-usuarios/perfil-usuarios.module";
import { ModalPdfComponent } from "./revistas/modal-pdf/modal-pdf.component";
import { ModalConfirmacionComponent } from "./revistas/modal-confirmacion/modal-confirmacion.component";

@NgModule({
  declarations: [
    UsuariosComponent,
    EstadisticasComponent,
    ComentariosComponent,
    PublicidadComponent,
    FacturacionComponent,
    AliadosComponent,
    PermisosComponent,
    SuscripcionUsuarioComponent,
    EntidadesUsuarioComponent,
    EspeciesComponent,
    CambioCorreoComponent,
    RevistasComponent,
    TablaComponent,
    ModalPdfComponent,
    ModalConfirmacionComponent,
  ],
  imports: [
    AdminRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgChartsModule,
    NgxPaginationModule,
    NgbModule,
    ApplicationPipesModule,
    PerfilUsuariosModule,
  ],
  exports: [TablaComponent, RevistasComponent],
})
export class AdminModule {}
