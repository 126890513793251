import { Component, OnInit } from "@angular/core";
import { Select } from "@ngxs/store";
import { Observable, Subject, takeUntil, tap } from "rxjs";
import { AuthenticationStateModel } from "../state/authentication-state";
import { AuthenticationState } from "../state/authentication-state/state";
import { UserData, UserService } from "../services/user.service";
import { PqrService } from "../services/pqr.service";
import { Pqr } from "../models/pqr.interface";
import { UserDataHelpers } from "../utils/getApellidosUserData";

@Component({
  selector: "app-pqr-comentarios",
  templateUrl: "./pqr-comentarios.component.html",
  styleUrls: ["./pqr-comentarios.component.css"],
})
export class PqrComentariosComponent implements OnInit {
  private sub$: Subject<void> = new Subject();
  @Select(AuthenticationState)
  authenticationState$!: Observable<AuthenticationStateModel>;

  pqrData: Pqr = {};

  constructor(
    private userService: UserService,
    private pqr: PqrService
  ) {}

  ngOnInit(): void {
    this.getEmail();
  }

  getEmail(): void {
    this.authenticationState$
      .pipe(
        takeUntil(this.sub$),
        tap({
          next: (state: AuthenticationStateModel) => {
            if (state.user) {
              const { email, uid } = state.user;
              if (email && uid) {
                this.pqrData.email = email;
                this.pqrData.idUsuario = uid;
                this.pqrData.comentario = "";
                this.getUserData(uid);
              }
            }
          },
          error: (error) => {
            console.log(error);
          },
        })
      )
      .subscribe();
  }

  getUserData(uid: string): void {
    this.userService
      .getUserById(uid)
      .pipe(
        takeUntil(this.sub$),
        tap({
          next: (user: UserData) => {
            this.pqrData.nombre = `${user.nombres || ""} ${UserDataHelpers.getApellidos(user) || ""}`;
          },
          error: (error) => {
            console.log(error);
          },
        })
      )
      .subscribe();
  }

  sendPqr(): void {
    const { comentario, email, nombre, idUsuario } = this.pqrData;
    if (comentario && email && nombre && idUsuario) {
      this.pqr
        .addPQR({ comentario, email, nombre, idUsuario })
        .then(() => {
          alert("PQR Enviado");
          delete this.pqrData.comentario;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
}
