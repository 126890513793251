import { Component, OnDestroy } from "@angular/core";
import { StorageService } from "../services/storage.service";
import { Subject, takeUntil, tap } from "rxjs";
import { ResponseCurrentUpload } from "../models/storage.interface";

@Component({
  selector: "app-progreso-upload",
  templateUrl: "./progreso-upload.component.html",
  styleUrls: ["./progreso-upload.component.css"],
})
export class ProgresoUploadComponent implements OnDestroy {
  private sub$: Subject<void> = new Subject();
  progress: ResponseCurrentUpload[] = [];

  constructor(private storage: StorageService) {
    this.storage.currentUploads
      .pipe(
        takeUntil(this.sub$),
        tap({
          next: (responses: ResponseCurrentUpload[]) => {
            this.progress = responses;
          },
          error: (err: Error) => {
            console.error(err);
          },
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.sub$.next();
    this.sub$.complete();
  }
}
