import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "porcentajeRound",
})
export class PorcentajeRoundPipe implements PipeTransform {
  transform(value: number): number {
    return Math.round(value);
  }
}
