import { Injectable } from "@angular/core";
import {
  Auth,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  UserCredential,
  signOut,
  User,
  EmailAuthProvider,
  reauthenticateWithCredential,
  EmailAuthCredential,
  updatePassword,
  onAuthStateChanged,
} from "@angular/fire/auth";
import { browserSessionPersistence, setPersistence } from "firebase/auth";
import { Observable, Subscriber } from "rxjs";
import { FirebaseFunctions } from "../functions/firebase.functions";
import { HttpsCallableResult } from "firebase/functions";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private firebaseFunctions: FirebaseFunctions,
    private auth: Auth
  ) {
    setPersistence(this.auth, browserSessionPersistence);
  }

  authStateChanged(): Observable<User | null> {
    const authStateChanged$: Observable<User | null> = new Observable<User>(
      (subscriber: Subscriber<User | null>) => {
        const unsubscribe = onAuthStateChanged(
          this.auth,
          (dataUser: User | null) => {
            subscriber.next(dataUser);
            return;
          },
          (error) => {
            subscriber.error(error);
          }
        );

        // Cuando el observable se desuscribe, detén la escucha en Firebase
        return () => unsubscribe();
      }
    );

    return authStateChanged$;
  }

  async resetPassword(email: string): Promise<void> {
    try {
      return sendPasswordResetEmail(this.auth, email);
    } catch (error) {
      console.error(error);
    }
  }

  async login(
    email: string,
    contraseña: string
  ): Promise<UserCredential | undefined> {
    try {
      const result: UserCredential = await signInWithEmailAndPassword(
        this.auth,
        email,
        contraseña
      );
      return result;
    } catch (error) {
      console.error(error);
      return;
    }
  }

  async createUserWithEmailAndPasswordInFirebase(
    email: string,
    contraseña: string
  ): Promise<UserCredential | undefined> {
    try {
      const result: UserCredential = await createUserWithEmailAndPassword(
        this.auth,
        email,
        contraseña
      );
      return result;
    } catch (error) {
      console.error(error);
      alert(error);
      return;
    }
  }

  async logout(): Promise<void> {
    await signOut(this.auth);
  }

  userIsLoggedIn(): boolean {
    return Boolean(this.getCurrentUser());
  }
  getCurrentUser(): User | null {
    return this.auth.currentUser;
  }

  async reauthenticate(currentPassword: string): Promise<UserCredential> {
    const currentUser: User | null = this.getCurrentUser();
    if (!currentUser) {
      throw new Error("Current User not found");
    }

    if (!currentUser.email) {
      throw new Error("Current User does not have an email");
    }

    const authCredential: EmailAuthCredential = EmailAuthProvider.credential(
      currentUser.email,
      currentPassword
    );
    return reauthenticateWithCredential(currentUser, authCredential);
  }

  async changePassword(
    currentPassword: string,
    newPassword: string
  ): Promise<void> {
    try {
      const userReauthentica: UserCredential =
        await this.reauthenticate(currentPassword);
      await updatePassword(userReauthentica.user, newPassword);
      window.alert("contraseña Actualizada");
    } catch (err: any) {
      console.error(err);
      if (err.code === "auth/wrong-password") {
        window.alert("Contraseña incorrecta");
      } else {
        window.alert("Contraseña  No Actualizada");
      }
    }
  }

  changeEmail(uid: string, email: string): Promise<HttpsCallableResult> {
    return this.firebaseFunctions.changeEmail(uid, email);
  }
}
