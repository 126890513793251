<div class="seccion">
  <h1 class="titulo-seccion">
    {{ "validador.validadorCertificados" | translate }}
  </h1>
  <p class="parrafo-seccion">
    {{ "validador.descripcionCertificados" | translate }}
    <b>{{ "validador.legal" | translate }}</b
    >.
  </p>

  <div class="contenedor-validador">
    <div class="form-group">
      <label for="IdCertificate">{{ "validador.ingreseId" | translate }}</label>
      <input
        type="text"
        name="yearsExperience"
        class="form-control"
        placeholder="{{ 'validador.ingreseIdPlaceholder' | translate }}"
        id="IdCertificate"
        [(ngModel)]="idCertificado"
      />
    </div>
    <!-- download the certification file -->
    <span *ngIf="isLoading && tipo === 'certificado'">
      <ng-container *ngTemplateOutlet="loader"></ng-container>
    </span>
    <button
      type="button"
      class="btn btn-warning btn-lg"
      [disabled]="!idCertificado"
      (click)="searchCertificado(actions.download)"
    >
      {{ "validador.buscar" | translate }}
    </button>
  </div>
  <!-- Sección de validación de cotizaciones -->
  <h1 class="titulo-seccion" id="tituloDos">
    {{ "validador.validadorCotizaciones" | translate }}
  </h1>
  <p class="parrafo-seccion">
    {{ "validador.descripcionCotizaciones" | translate }}
  </p>
  <div class="contenedor-validador">
    <div class="form-group">
      <label>{{ "validador.ingreseIdCotizacion" | translate }}</label>
      <input
        type="text"
        class="form-control"
        placeholder="{{
          'validador.ingreseIdCotizacionPlaceholder' | translate
        }}"
        [(ngModel)]="idCotizacion"
      />
    </div>
    <span *ngIf="isLoading && tipo === 'cotización'">
      <ng-container *ngTemplateOutlet="loader"></ng-container>
    </span>
    <button
      type="button"
      class="btn btn-warning btn-lg"
      [disabled]="!idCotizacion"
      (click)="searchCotizacion()"
    >
      {{ "validador.buscar" | translate }}
    </button>
  </div>
</div>

<ng-template #loader>
  <div>
    <img
      class="img-gif"
      src="../../assets/imagenes/loading_gif.gif"
      alt="cargando"
    />
    <span class="sr-only">{{ "formularios.cargando" | translate }}</span>
  </div>
</ng-template>

<ng-template #notFound let-c="close">
  <div class="modal-header d-flex justify-content-center">
    <h4 class="modal-titled-flex justify-content-center" id="modal-basic-title">
      {{
        ("validador." + tipo | translate) +
          " " +
          ("validador.noEncontrado" | translate) | titlecase
      }}
    </h4>
  </div>
  <div class="modal-body text-center">
    <button
      type="button"
      class="btn btn-success"
      data-bs-dismiss="modal"
      (click)="c()"
    >
      {{ "validador.aceptar" | translate }}
    </button>
  </div>
</ng-template>
