import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "app-modal-pdf",
  templateUrl: "./modal-pdf.component.html",
  styleUrls: ["./modal-pdf.component.css"],
})
export class ModalPdfComponent implements OnInit {
  public urlSegura!: SafeResourceUrl;
  public loading: boolean = false;
  private pdfUrl!: string;
  private closeEvent: Subject<boolean> = new Subject<boolean>();
  constructor(private sanitizer: DomSanitizer) {}

  async generarUrl(): Promise<void> {
    this.loading = true;
    try {
      this.urlSegura = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pdfUrl
      );
      this.loading = false;
    } catch (e) {
      console.log(e);
    }
  }

  ngOnInit(): void {
    this.generarUrl();
  }

  close(): void {
    this.closeEvent.next(true);
  }
}
