<h3>{{ "contabilidad.informacionContable" | translate }}</h3>
<p>
  {{ "contabilidad.encontrarInformacion" | translate }}
</p>
<div class="table-responsive-lg">
  <h4 class="mt-3">{{ "contabilidad.historialVentas" | translate }}</h4>
  <table class="table table-hover">
    <thead class="thead-dark">
      <tr>
        <th scope="col">No.</th>
        <th scope="col">{{ "formularios.categoria" | translate }}</th>
        <th scope="col">{{ "contabilidad.pedidos" | translate }}</th>
        <th scope="col">{{ "contabilidad.rechazos" | translate }}</th>
        <th scope="col">{{ "contabilidad.entregados" | translate }}</th>
        <th scope="col">{{ "contabilidad.ingresos" | translate }}</th>
      </tr>
    </thead>
    <tbody class="contenido-tabla">
      <tr
        *ngFor="
          let item of keysPedidosVentas
            | paginate
              : { itemsPerPage: itemsPerPage, currentPage: currentPage };
          let i = index
        "
      >
        <th scope="row">{{ itemsPerPage * (currentPage - 1) + i + 1 }}</th>
        <td>{{ item }}</td>
        <td>{{ pedidosVentas[item].cantidad || 0 }}</td>
        <td>{{ pedidosVentas[item].rechazado || 0 }}</td>
        <td>{{ pedidosVentas[item].finalizado || 0 }}</td>
        <td>{{ pedidosVentas[item].egresos | currency }}</td>
      </tr>
    </tbody>
  </table>
  <h5>
    {{ "contabilidad.totalIngresos" | translate }} {{ totalVentas | currency }}
  </h5>
</div>
<!--paginado para los resultados-->
<pagination-controls
  class="my-pagination"
  (pageChange)="currentPage = $event"
  previousLabel="{{ 'paginacion.anterior' | translate }}"
  nextLabel="{{ 'paginacion.siguiente' | translate }}"
>
</pagination-controls>
<div class="table-responsive-lg">
  <h4 class="mt-3">{{ "contabilidad.historialComporas" | translate }}</h4>
  <table class="table table-hover">
    <thead class="thead-dark">
      <tr>
        <th scope="col">No.</th>
        <th scope="col">{{ "formularios.categoria" | translate }}</th>
        <th scope="col">{{ "contabilidad.pedidos" | translate }}</th>
        <th scope="col">{{ "contabilidad.rechazos" | translate }}</th>
        <th scope="col">{{ "contabilidad.entregados" | translate }}</th>
        <th scope="col">{{ "contabilidad.egresos" | translate }}</th>
      </tr>
    </thead>
    <tbody class="contenido-tabla">
      <tr
        *ngFor="
          let item of keysPedidosCompras
            | paginate
              : { itemsPerPage: itemsPerPage, currentPage: currentPage };
          let i = index
        "
      >
        <th scope="row">{{ itemsPerPage * (currentPage - 1) + i + 1 }}</th>
        <td>{{ item }}</td>
        <td>{{ pedidosCompras[item].cantidad || 0 }}</td>
        <td>{{ pedidosCompras[item].rechazado || 0 }}</td>
        <td>{{ pedidosCompras[item].finalizado || 0 }}</td>
        <td>{{ pedidosCompras[item].egresos | currency }}</td>
      </tr>
    </tbody>
  </table>
  <h5>
    {{ "contabilidad.totalEgresos" | translate }} {{ totalCompras | currency }}
  </h5>
</div>
<!--paginado para los resultados-->
<pagination-controls
  class="my-pagination"
  (pageChange)="currentPage = $event"
  previousLabel="{{ 'paginacion.anterior' | translate }}"
  nextLabel="{{ 'paginacion.siguiente' | translate }}"
>
</pagination-controls>
