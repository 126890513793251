import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { catchError } from "rxjs/operators";
import { Path } from "../enums/Path";
import { ProductoData } from "../models/ProductoData";
import { StorageService } from "../services/storage.service";
import { CalificacionInProductService } from "../services/calificacion-in-product.service";

@Component({
  selector: "app-producto[producto]",
  templateUrl: "./producto.component.html",
  styleUrls: ["./producto.component.css"],
})
export class ProductoComponent implements OnInit, OnDestroy {
  @Input() producto!: ProductoData;
  path: typeof Path = Path;
  defaultThumbnailUrl: string = "assets/imagenes/noImage.png";

  private unsubscriber: Subject<void> = new Subject();
  public vendedorPerfilUrl!: Observable<string>;
  public puntuacion!: number;

  constructor(
    private storageService: StorageService,
    private calificacion: CalificacionInProductService
  ) {}

  ngOnInit(): void {
    this.vendedorPerfilUrl = this.storageService
      .getStoreUrlImageObservable(
        `fotoPerfilUser/${this.producto.idVendedor}_500x500`
      )
      .pipe(
        catchError(() => {
          return this.storageService.getStoreUrlImageObservable(
            `fotoPerfilUser/${this.producto.idVendedor}`
          );
        })
      );

    this.traerCalificacion(this.producto.idVendedor);
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  traerCalificacion(idUser: string): void {
    this.calificacion
      .getCalificacion(idUser)
      .then((puntuacion: number) => (this.puntuacion = puntuacion))
      .catch((err) => console.error(err));
  }
}
