import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Select } from "@ngxs/store";
import { Observable, Subject, firstValueFrom } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import {
  AuthenticationState,
  AuthenticationStateModel,
} from "src/app/state/authentication-state";
import { serviciosTecnicos } from "src/app/models/serviciosTecnicos";
import { estudiosTecnicos } from "src/app/models/estudiosTecnicos";
import { maquinariaEquiposTec } from "src/app/models/maquinariaEquipoTec";
import { CrearClasificado } from "src/app/models/crearClasificado";
import { UserType } from "src/app/enums/UserTypes";
import { DatosCalificacion } from "src/app/models/CalificacionyComentarios";
import { ServiceUserService } from "src/app/services/service-user.service";
import { AuthService } from "src/app/services/auth.service";
import { UserData, UserService } from "src/app/services/user.service";
import { ClasificadosService } from "src/app/services/clasificados.service";
import { FirebaseRequestsService } from "src/app/services/firebase-requests.service";
import { User } from "@angular/fire/auth";
import { NuevoServicioTecnico } from "src/app/models/addServiceData";
import { DocumentData, Timestamp } from "@angular/fire/firestore";
import {
  ContractService,
  ResponseObservableContractService,
  ServiceUser,
} from "src/app/models/contractService.interface";

@Component({
  selector: "app-tecnico",
  templateUrl: "./tecnico.component.html",
  styleUrls: ["./tecnico.component.css"],
})
export class TecnicoComponent implements OnInit, OnDestroy {
  @Select(AuthenticationState.user)
  user$!: Observable<AuthenticationStateModel["user"]>;
  addTechnicianServiceForm!: FormGroup;
  currentService!: string;
  serviciosUsuario: ServiceUser[] = [];
  typeUser!: UserType | UserType[];

  private unsubscriber: Subject<void> = new Subject();
  public servicios: string[] = serviciosTecnicos;
  public clasificadosList: CrearClasificado[] = [];
  public estudios: string[] = estudiosTecnicos;
  public maquinariasEquipos: string[] = maquinariaEquiposTec;

  solicitudes!: ResponseObservableContractService[];
  ocultarSolicitudes!: boolean;
  datosCalificacion!: DatosCalificacion;

  constructor(
    private serviceUser: ServiceUserService,
    private authService: AuthService,
    private fb: FormBuilder,
    private ref: ChangeDetectorRef,
    private firebaseRequests: FirebaseRequestsService,
    private userService: UserService,
    private clasificadosService: ClasificadosService
  ) {
    this.getServicios();
    this.initForm();
    this.traerClasificados();
  }

  get equipmentMachinery(): AbstractControl[] | null {
    return (
      this.addTechnicianServiceForm.get("equipmentMachinery") as FormArray
    ).controls;
  }

  ngOnInit(): void {
    this.user$.pipe(takeUntil(this.unsubscriber)).subscribe((user) => {
      if (user) {
        this.getUserData(user.uid);
      }
    });
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  async onCreateForestService(): Promise<void> {
    if (this.addTechnicianServiceForm.invalid) {
      this.addTechnicianServiceForm.markAllAsTouched();
      return;
    }

    const currentUser: User | null = this.authService.getCurrentUser();

    if (currentUser) {
      const data: NuevoServicioTecnico = {
        ...this.addTechnicianServiceForm.value,
        tiempoCreado: Timestamp.now(),
        userId: currentUser.uid,
        user: this.firebaseRequests.getFirebaseDocReference(
          `usuarios/${currentUser.uid}`
        ),
        type: "tecnico",
      };

      try {
        const response: string = await this.serviceUser.addServiceForest(data);
        if (response) {
          document.getElementById("modalCrearServicio")?.click();
          this.resetFormAndFiles();
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  resetFormAndFiles(): void {
    this.addTechnicianServiceForm.reset();
  }

  isValidField(field: string, child?: number): boolean {
    if (child) {
      const campo: AbstractControl = (
        this.addTechnicianServiceForm.controls[field] as FormArray
      ).controls[child];
      return campo.dirty && campo.valid;
    }

    return (
      (this.addTechnicianServiceForm.get(field)?.dirty &&
        this.addTechnicianServiceForm.get(field)?.valid) ||
      false
    );
  }

  isInvalidField(field: string, child?: number): boolean {
    if (child) {
      const campo: AbstractControl = (
        this.addTechnicianServiceForm.controls[field] as FormArray
      ).controls[child];
      return (campo.touched || campo.dirty) && !campo.valid;
    }

    return (
      ((this.addTechnicianServiceForm.get(field)?.touched ||
        this.addTechnicianServiceForm.get(field)?.dirty) &&
        !this.addTechnicianServiceForm.get(field)?.valid) ||
      false
    );
  }

  initForm(): void {
    this.addTechnicianServiceForm = this.fb.group({
      nameService: ["", Validators.required],
      workExperience: ["", Validators.required],
      yearsExperience: ["", Validators.required],
      profession: ["", Validators.required],
      forestCourse: [""],
      equipmentMachinery: this.fb.array([this.fb.control("")]),
      professionalCard: ["", Validators.required],
      carUser: ["", Validators.required],
    });
  }

  addEquipo(): void {
    (this.addTechnicianServiceForm.get("equipmentMachinery") as FormArray).push(
      this.fb.control("")
    );
    this.ref.detectChanges();
  }

  removeEquipo(): void {
    const controls: FormArray = this.addTechnicianServiceForm.get(
      "equipmentMachinery"
    ) as FormArray;
    controls.removeAt(controls.length - 1);
    this.ref.detectChanges();
  }

  getServicios() {
    const currentUser: User | null = this.authService.getCurrentUser();
    if (currentUser) {
      this.serviceUser
        .getUserServices(currentUser.uid, "tecnico")
        .pipe(
          takeUntil(this.unsubscriber),
          tap({
            next: (serviciosUsuario: ServiceUser[]) => {
              this.serviciosUsuario = serviciosUsuario;
            },
            error: (error) => {
              console.error(error);
            },
          })
        )
        .subscribe();
    }
  }

  async deleteServicio(id: string): Promise<void> {
    if (id) {
      await this.serviceUser.deleteService(id);
    }
  }

  mostrarSolicitudes(): void {
    let transportador: boolean;
    let tecnico: boolean;

    if (this.typeUser instanceof Array) {
      transportador = this.typeUser.includes(UserType.Transportador);
      tecnico = this.typeUser.includes(UserType.Tecnico);
    } else {
      transportador = this.typeUser === UserType.Transportador;
      tecnico = this.typeUser === UserType.Tecnico;
    }

    this.ocultarSolicitudes = transportador && tecnico;
    this.getSolicitudes();
  }

  getUserData(uid: string): void {
    this.userService
      .getUserById(uid)
      .pipe(
        takeUntil(this.unsubscriber),
        tap({
          next: (user: UserData) => {
            this.typeUser = user.tipo;
            this.mostrarSolicitudes();
          },
          error: (error) => {
            console.error(error);
          },
        })
      )
      .subscribe();
  }

  marcarCalificado(id: string): void {
    const servicio: string = this.currentService;

    this.serviceUser
      .updateServicio(servicio, {
        calificadoContratado: true,
        idCalificacionContratado: id,
        fechaFinalizacion: Timestamp.now(),
      })
      .then(() => {})
      .catch((err) => console.warn("error al calificar servicio: ", err));
  }

  async getSolicitudes(): Promise<void> {
    if (this.ocultarSolicitudes) {
      return;
    }

    const currentUser: User | null = this.authService.getCurrentUser();

    if (currentUser) {
      this.serviceUser
        .getServiceRequest(currentUser.uid)
        .pipe(
          takeUntil(this.unsubscriber),
          tap({
            next: async (solicitudes: ContractService[]) => {
              const servicios: ResponseObservableContractService[] = [];
              try {
                await Promise.all(
                  solicitudes.map(async (svc: ContractService) => {
                    const servicioUsuario: ServiceUser =
                      await this.firebaseRequests.getDocFirebaseWithIdPromise<ServiceUser>(
                        svc.servicio.path
                      );

                    const user: UserData = await firstValueFrom(
                      this.userService.getUserById(svc.idContratante)
                    );

                    servicios.push({
                      ...svc,
                      servicioUsuario,
                      user,
                    });
                  })
                );
                this.solicitudes = servicios;
              } catch (error) {
                console.error("Error al obtener las solicitudes:", error);
              }
            },
            error: (error) => {
              console.error(error);
            },
          })
        )
        .subscribe();
    }
  }

  setDatosCalificacion(svc: ResponseObservableContractService): void {
    const currentUser: User | null = this.authService.getCurrentUser();

    if (currentUser) {
      const datos: DatosCalificacion = {
        idServicio: svc.idServicio,
        idCalificador: currentUser.uid,
        idCalificado: svc.idContratante,
      };

      this.datosCalificacion = datos;
    }
  }

  async traerClasificados(): Promise<DocumentData[]> {
    try {
      const clasificados: DocumentData[] =
        await this.clasificadosService.getAllClasificados();
      return clasificados;
    } catch (err) {
      console.log(err);
      throw new Error("Error getting all clasificados");
    }
  }
}
