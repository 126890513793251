import { OnChanges } from "@angular/core";
import { Component, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { tap } from "rxjs";
import moment from "moment";
import { SubscriptionService } from "src/app/services/subscriptions.service";
import { Suscripcion } from "src/app/models/subscripcion.interface";

@Component({
  selector: "app-suscripcion-usuario",
  templateUrl: "./suscripcion-usuario.component.html",
  styleUrls: ["./suscripcion-usuario.component.css"],
})
export class SuscripcionUsuarioComponent implements OnChanges {
  @Input() userId!: string;
  suscripcionForm!: FormGroup;
  suscripciones!: Suscripcion[];

  constructor(
    private fb: FormBuilder,
    private suscripcionSvc: SubscriptionService
  ) {
    this.initForm();
  }

  ngOnChanges() {
    if (this.userId) {
      this.traerSuscripciones(this.userId);
    }
  }

  initForm(): void {
    this.suscripcionForm = this.fb.group({
      fechaInicio: ["", Validators.required],
      fechaFin: ["", Validators.required],
      duracion: [1, Validators.required],
    });

    this.suscripcionForm
      .get("fechaInicio")
      ?.valueChanges.pipe(
        tap({
          next: () => {
            this.setFechaFin();
          },
          error: (error) => {
            console.log(error);
          },
        })
      )
      .subscribe();

    this.suscripcionForm
      .get("duracion")
      ?.valueChanges.pipe(
        tap({
          next: () => {
            this.setFechaFin();
          },
          error: (error) => {
            console.log(error);
          },
        })
      )
      .subscribe();
  }

  async agregarSuscripcion(): Promise<void> {
    try {
      const datosForm = {
        ...this.suscripcionForm.value,
        idUsuario: this.userId,
      };

      datosForm.fechaInicio = moment(datosForm.fechaInicio).toDate();
      datosForm.fechaFin = moment(datosForm.fechaFin).toDate();
      await this.suscripcionSvc.createSubscription(datosForm);
      this.traerSuscripciones(this.userId);
    } catch (e) {
      console.error(e);
    }
  }

  async traerSuscripciones(idUsuario: string): Promise<void> {
    try {
      this.suscripciones = (await this.suscripcionSvc.getSubscriptionByUser(
        idUsuario
      )) as Suscripcion[];
    } catch (e) {
      console.error(e);
    }
  }

  setFechaFin() {
    const duracion = this.suscripcionForm.get("duracion")?.value;
    const fechaInicio = this.suscripcionForm.get("fechaInicio")?.value;

    if (duracion && fechaInicio) {
      const final = moment(fechaInicio)
        .add(+duracion, "M")
        .format("YYYY-MM-DD");

      this.suscripcionForm.get("fechaFin")?.setValue(final);
    }
  }

  formatDate(date: string | any, format: string = "ll") {
    return moment(date.seconds * 1000)
      .locale("es")
      .format(format);
  }

  async removerSuscripcion(suscripcion: Suscripcion) {
    try {
      const { id } = suscripcion;
      await this.suscripcionSvc.removeSubscription(id);
      this.traerSuscripciones(this.userId);
    } catch (e) {
      console.error(e);
    }
  }
}
