<form [formGroup]="productoForm">
  <div class="row">
    <div class="form-group col-md-6">
      <label for="pais">{{ "formularios.pais" | translate }}</label>
      <select
        #selectPais
        id="pais"
        type="text"
        name="pais"
        class="form-select"
        aria-label="Default select example"
        formControlName="pais"
        [ngClass]="{
          'is-invalid': isInvalidField('pais'),
          'is-valid': isValidField('pais')
        }"
        required
        (change)="onSelectPais(selectPais.value)"
      >
        <option value="">
          {{ "formularios.seleccionePais" | translate }}
        </option>
        <option *ngFor="let pais of listaPaises" [value]="pais">
          {{ pais }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('pais')"></app-errores>
    </div>
    <div class="form-group col-md-6">
      <label for="seleccioneDepartamento">{{
        "formularios.departamento" | translate
      }}</label>
      <select
        #SelectDep
        name="departamento"
        class="form-select"
        aria-label="Default select example"
        id="seleccioneDepartamento"
        formControlName="departamento"
        required
        (change)="selectDepartamento(SelectDep.value)"
        [ngClass]="{
          'is-invalid': isInvalidField('departamento'),
          'is-valid': isValidField('departamento')
        }"
      >
        <option value="">
          {{ "formularios.seleccioneDepartamento" | translate }}
        </option>
        <option
          *ngFor="let departamento of listaDepartamentos"
          [value]="departamento"
        >
          {{ departamento }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('departamento')"></app-errores>
    </div>
    <div class="form-group col-md-6">
      <label for="seleccioneMunicipio">{{
        "formularios.municipio" | translate
      }}</label>
      <select
        name="municipio"
        class="form-select"
        aria-label="Default select example"
        id="seleccioneMunicipio"
        formControlName="municipio"
        [ngClass]="{
          'is-invalid': isInvalidField('municipio'),
          'is-valid': isValidField('municipio')
        }"
      >
        <option value="">
          {{ "formularios.seleccioneMunicipio" | translate }}
        </option>
        <option *ngFor="let municipio of listaMunicipios" [value]="municipio">
          {{ municipio }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('municipio')"></app-errores>
    </div>
    <div class="form-group col-md-6" *ngIf="!visualizar">
      <label for="seleccioneEspecie">{{
        "formularios.especie" | translate
      }}</label>
      <select
        name="especie"
        class="form-select"
        aria-label="Default select example"
        id="seleccioneEspecie"
        formControlName="especie"
        [ngClass]="{
          'is-invalid': isInvalidField('especie'),
          'is-valid': isValidField('especie')
        }"
        required
      >
        <option value="">
          {{ "formularios.seleccioneEspecie" | translate }}
        </option>
        <option
          *ngFor="let especie of especiesHabilitadas?.sort()"
          [value]="especie"
        >
          {{ especie }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('especie')"></app-errores>
    </div>
    <div class="form-group col-md-6">
      <label for="seleccioneSistema">{{
        "crear-producto.sistemaSiembra" | translate
      }}</label>
      <select
        name="Sistema"
        class="form-select"
        aria-label="Default select example"
        id="seleccioneSistema"
        formControlName="sistema"
        [ngClass]="{
          'is-invalid': isInvalidField('sistema'),
          'is-valid': isValidField('sistema')
        }"
      >
        <option value="Cercas Vivas">
          {{ "crear-producto.cercasVivas" | translate }}
        </option>
        <option value="Sistema Agroforestal">
          {{ "crear-producto.sistemaAgroforestal" | translate }}
        </option>
        <option value="Sistema Silvopastoril">
          {{ "crear-producto.sistemaSilvopastoril" | translate }}
        </option>
        <option value="Plantación Forestal">
          {{ "crear-producto.plantacionForestal" | translate }}
        </option>
        <option value="Arboles Aislados">
          {{ "crear-producto.arbolesAislados" | translate }}
        </option>
        <option value="Bosque natural">
          {{ "crear-producto.bosqueNatural" | translate }}
        </option>
        <option value="Enriquecimiento Forestal">
          {{ "crear-producto.enriquecimiento" | translate }}
        </option>
      </select>
      <app-errores [control]="productoForm.get('sistema')"></app-errores>
    </div>
    <div class="form-group col-md-6" *ngIf="!visualizar">
      <label for="unidadNoMaderable">{{
        "crear-producto.unidadMedida" | translate
      }}</label>
      <select
        name="unidadMedida"
        class="form-select"
        aria-label="Default select example"
        id="unidadNoMaderable"
        formControlName="unidadMedida"
        [ngClass]="{
          'is-invalid': isInvalidField('unidadMedida'),
          'is-valid': isValidField('unidadMedida')
        }"
      >
        <option value="">
          {{ "crear-producto.selectUnidadMedida" | translate }}
        </option>
        <option value="bono">{{ "crear-producto.bono" | translate }}</option>
      </select>
      <app-errores [control]="productoForm.get('unidadMedida')"></app-errores>
    </div>
    <div class="form-group col-md-6" *ngIf="!visualizar">
      <label for="cantidadNoMaderable">{{
        "crear-producto.cantidadBonosDisponibles" | translate
      }}</label>
      <input
        name="disponibilidadVenta"
        class="form-control"
        placeholder="Para vender"
        id="cantidadNoMaderable"
        formControlName="disponibilidadVenta"
        [ngClass]="{
          'is-invalid': isInvalidField('disponibilidadVenta'),
          'is-valid': isValidField('disponibilidadVenta')
        }"
        currencyMask
        [options]="{ prefix: '', precision: 0, thousands: '.' }"
      />
      <app-errores
        [control]="productoForm.get('disponibilidadVenta')"
      ></app-errores>
    </div>

    <div class="form-group col-md-6">
      <label for="fechaFabricacion">{{
        "crear-producto.fechaExpedicion" | translate
      }}</label>

      <input
        formControlName="fechaFabricacion"
        [ngClass]="{
          'is-invalid': isInvalidField('fechaFabricacion'),
          'is-valid': isValidField('fechaFabricacion')
        }"
        class="form-control"
        name="fechaFabricacion"
        formControlName="fechaFabricacion"
        placeholder="{{ 'crear-producto.fechaVencimiento' | translate }}"
        ngbDatepicker
        #fechaFabricacion="ngbDatepicker"
        (click)="fechaFabricacion.toggle()"
      />

      <app-errores
        [control]="productoForm.get('fechaFabricacion')"
      ></app-errores>
    </div>
    <div class="row" *ngIf="!visualizar">
      <div class="form-group col-md-6">
        <label for="moneda">{{ "crear-producto.moneda" | translate }}</label>
        <select
          name="moneda"
          class="form-select"
          aria-label="Default select example"
          id="moneda"
          formControlName="moneda"
          [ngClass]="{
            'is-invalid': isInvalidField('moneda'),
            'is-valid': isValidField('moneda')
          }"
        >
          <option value="">
            {{ "crear-producto.selectMoneda" | translate }}
          </option>
          <option [value]="moneda" *ngFor="let moneda of listaMonedas">
            {{ "crear-producto." + moneda | translate }}
          </option>
        </select>
        <app-errores [control]="productoForm.get('moneda')"></app-errores>
      </div>
      <div class="form-group col-md-6">
        <label for="precioVenta4"
          >{{ "crear-producto.precioPorBono" | translate
          }}<a target="_blank" href="https://issuu.com/redforestal"
            >({{ "crear-producto.preciosReferencia" | translate }})</a
          ></label
        >
        <input
          currencyMask
          [options]="{
            inputMode: 0,
            precision: 0,
            thousands: '.'
          }"
          name="precio"
          class="form-control"
          id="precioVenta4"
          formControlName="precio"
          [ngClass]="{
            'is-invalid': isInvalidField('precio'),
            'is-valid': isValidField('precio')
          }"
        />
        <app-errores [control]="productoForm.get('precio')"></app-errores>
      </div>
    </div>
    <div class="form-group col-md-12">
      <label for="otros">{{
        "crear-producto.especificacionesTecnicas" | translate
      }}</label>
      <textarea
        class="form-control"
        placeholder="{{ 'crear-producto.porSuSeguridad' | translate }}"
        name="datosInteres"
        rows="3"
        id="otros"
        formControlName="datosInteres"
      ></textarea>
      <app-errores [control]="productoForm.get('datosInteres')"></app-errores>
    </div>
  </div>
</form>
