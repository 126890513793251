import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import moment from "moment";
import { StorageService } from "./storage.service";
import { FirebaseRequestsService } from "./firebase-requests.service";
import { UploadTaskSnapshot } from "firebase/storage";
import {
  DocumentData,
  DocumentReference,
  Timestamp,
  where,
} from "@angular/fire/firestore";

@Injectable({
  providedIn: "root",
})
export class PublicidadService {
  constructor(
    private storageService: StorageService,
    private firebaseRequests: FirebaseRequestsService
  ) {}

  subirFoto(
    path: string,
    imagen: File
  ): Observable<{
    progress: number;
    snapshot: UploadTaskSnapshot;
  }> {
    return this.storageService.uploadFile(path, imagen);
  }

  guardar(datos: any): Promise<DocumentReference> {
    return this.firebaseRequests.addDocFirebaseWithAutomaticId(
      "publicidad",
      datos
    );
  }

  actualizarPublicidad(id: string, data: any): Promise<void> {
    return this.firebaseRequests.updateDocFirebase(`publicidad/${id}`, data);
  }

  traerPublicidad(activos: boolean = true): Observable<DocumentData[]> {
    return this.firebaseRequests.getCollectionFirebaseWithQueryObservable<DocumentData>(
      "publicidad",
      [where("activo", "==", activos)]
    );
  }

  getPublicidad(sliders: boolean = true): Observable<DocumentData[]> {
    const opts: string[] = sliders
      ? [`slide1`, `slide2`, `slide3`]
      : ["izquierda", "centro", "derecha"];

    return this.firebaseRequests
      .getCollectionFirebaseWithQueryObservable<DocumentData>("publicidad", [
        where("nombre", "in", opts),
        where("activo", "==", true),
        where("fechaTerminacion", ">=", Timestamp.now()),
      ])
      .pipe(
        map((result: DocumentData[]) => {
          const items: DocumentData[] = [];
          result.map((doc) => {
            const data: DocumentData = doc;
            const isBefore: boolean = moment(new Date()).isBefore(
              moment(data["fechaInicio"].seconds * 1000)
            );

            if (!isBefore) items.push(data);
          });
          return items;
        })
      );
  }
}
