import { AbstractControl, AsyncValidatorFn } from "@angular/forms";
import { debounceTime, map, take } from "rxjs/operators";
import { UserService } from "../services/user.service";
import { of } from "rxjs";

export const isUniqueEmail = (userSvc: UserService): AsyncValidatorFn => {
  return (control: AbstractControl) => {
    if (control.value) {
      return userSvc.checkEmail(control.value).pipe(
        debounceTime(1000),
        map((response) => (response.length ? { isUniqueEmail: true } : null)),
        take(1)
      );
    }
    return of(null);
  };
};
