import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Pipe({
  name: "getFile",
})
export class GetFilePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url: string): SafeResourceUrl | undefined {
    try {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    } catch (e) {
      console.log(e);
      return;
    }
  }
}
