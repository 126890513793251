<h3 class="titulo-contenido-perfil">
  {{ "perfil-usuarios.licencias" | translate }}
</h3>
<!--Datos licencias-->
<div class="row">
  <div class="col-4">
    <p>
      <b>{{ "licencias.total_licencias" | translate }}</b> {{ totalLicences }}
    </p>
  </div>
  <div class="col-4">
    <p>
      <b>{{ "licencias.licencias_activas" | translate }}</b>
      {{ activeLicences }}
    </p>
  </div>
  <div class="col-4">
    <p>
      <b>{{ "licencias.licencias_inactivas" | translate }}</b>
      {{ inactiveLicences }}
    </p>
  </div>
</div>
<p>
  {{ "licencias.parrafo_licencias" | translate }}
</p>
<!--grid para las licencias, llamar ngfor para traer los datos. importante paginar, revisar otras secciones que ya hay implementaciones-->
<div class="table-responsive-lg">
  <table class="table table-hover">
    <thead class="thead-dark">
      <tr>
        <th scope="col">{{ "licencias.cod_licencia" | translate }}</th>
        <th scope="col">{{ "subasta-grid.fechaInicio" | translate }}</th>
        <th scope="col">{{ "subasta-grid.fechaFin" | translate }}</th>
        <th scope="col">{{ "subasta-grid.estado" | translate }}</th>
        <th scope="col">{{ "licencias.title_tecnico" | translate }}</th>
        <th scope="col">{{ "formularios.accion" | translate }}</th>
      </tr>
    </thead>
    <tbody class="contenido-tabla">
      <tr *ngFor="let license of licenses$ | async">
        <td>{{ license.redeemCode }}</td>
        <td>{{ license.begins | timestamp | date: "dd-MM-yyyy" }}</td>
        <td>{{ license.ends | timestamp | date: "dd-MM-yyyy" }}</td>
        <td>{{ license.status === "active" ? "Activo" : "Inactivo" }}</td>
        <td>{{ license.wtUserId | wtUserName | async }}</td>
        <td>
          <button
            type="button"
            class="btn btn-danger"
            data-bs-toggle="modal"
            data-bs-target="#desvincularModal"
            [disabled]="license.wtUserId === '' || !licenseIsActive(license)"
            (click)="onDesvincular(license)"
          >
            {{ "licencias.desvincular" | translate }}
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<!--Modal para las licencias-->

<div
  class="modal fade"
  id="desvincularModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "licencias.desvincular" | translate }}
        </h5>
      </div>
      <div class="modal-body">
        <p>{{ "licencias.modal_parrafo" | translate }}</p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-danger"
          data-bs-dismiss="modal"
          (click)="onDesvincularCancel()"
        >
          {{ "formularios.cancelar" | translate }}
        </button>
        <button
          type="submit"
          class="btn btn-success"
          data-bs-dismiss="modal"
          (click)="onDesvincularConfirm()"
        >
          {{ "formularios.continuar" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
