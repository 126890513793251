<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">
    {{ "perfil-usuarios.reportes" | translate }}
  </h5>
</div>
<div class="modal-body">
  <!--En esta tabla se llaman cada uno de los reportes para descargar-->
  <div class="table-responsive-lg">
    <table class="table table-hover">
      <thead class="thead-dark">
        <tr>
          <th scope="col">{{ "formularios.fecha" | translate }}</th>
          <th scope="col">{{ "formularios.municipio" | translate }}</th>
          <th scope="col">{{ "formularios.accion" | translate }}</th>
        </tr>
      </thead>
      <tbody class="contenido-tabla">
        <tr *ngFor="let report of reports$ | async">
          <td>{{ report.fCreado | timestamp | date: "dd-MM-yyyy" }}</td>
          <td>{{ report.municipio }}</td>
          <td>
            <button
              type="button"
              class="btn btn-success"
              (click)="
                downloadFile(report.urlPdf, 'report_' + report.localId + '.pdf')
              "
            >
              {{ "cotizacion.descargar" | translate }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="onClose()">
    {{ "formularios.cerrar" | translate }}
  </button>
</div>
