import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filtro",
})
export class FiltroPipe implements PipeTransform {
  // TODO - type the parameters correctly.
  transform(productos: any, filtroProducto: any): any {
    return productos.filter((item: any) => {
      if (!item || !filtroProducto) {
        return item;
      }

      return (
        item.departamento
          ?.toLowerCase()
          .indexOf(filtroProducto.departamento.toLowerCase()) >= 0 &&
        item.categoria
          ?.toLowerCase()
          .indexOf(filtroProducto.categoria.toLowerCase()) >= 0 &&
        !item.eliminado
      );
    });
  }
}
