import { Injectable } from "@angular/core";
import {
  Functions,
  HttpsCallable,
  HttpsCallableResult,
  httpsCallable,
} from "@angular/fire/functions";

@Injectable({
  providedIn: "root",
})
export class FirebaseFunctions {
  constructor(private functions: Functions) {}

  public changeEmail(uid: string, email: string): Promise<HttpsCallableResult> {
    const callable: HttpsCallable = httpsCallable(
      this.functions,
      "generic-actualizarCorreo"
    );
    return callable({ uid, email });
  }

  public getUsers(
    limit: number,
    offset: number,
    estado: boolean | null,
    search: string | undefined
  ): Promise<HttpsCallableResult<GenericUsuariosResponse>> {
    const callable: HttpsCallable<any, GenericUsuariosResponse> = httpsCallable(
      this.functions,
      "generic-usuarios"
    );
    return callable({ limit, offset, estado, search });
  }

  public getEstadisticas(anio: string): Promise<HttpsCallableResult> {
    const callable: HttpsCallable = httpsCallable(
      this.functions,
      "generic-estadisticas"
    );
    return callable({ anio });
  }

  private errorChecker = (data: any) => {
    if (data.error) {
      throw new Error(data.message);
    }
  };
}

/**
 * Describe la respuesta de la función `generic-usuarios`.
 */
export interface GenericUsuariosResponse {
  paginas: number;
  total: number;
  usuarios: any[];
}
