import { Component } from "@angular/core";
import { Path } from "../enums/Path";

@Component({
  selector: "app-enlaces-de-interes",
  templateUrl: "./enlaces-de-interes.component.html",
  styleUrls: ["./enlaces-de-interes.component.css"],
})
export class EnlacesDeInteresComponent {
  public path: typeof Path = Path;
  public secciones: Array<string> = [
    "manualUsuarios",
    "terminosCondiciones",
    "responsabilidadSocial",
    "capacidad",
    "registro",
    "modificacionesAcuerdo",
    "listadoProductos",
    "selloRed",
    "privacidadInformacion",
    "obligacionesUsuarios",
    "prohibiciones",
    "violacionesSistema",
    "responsabilidad",
    "fallasSistema",
    "tarifasFacturacion",
    "sistemaReputacion",
    "propiedadIntelectual",
    "indemnidad",
    "anexos",
    "jurisdiccion",
    "domicilio",
    "politicaPrivacidad",
    "derechosAcceso",
    "informacionRecabamos",
    "usoInformacion",
    "confidencialidad",
    "clavePersonal",
    "menoresEdad",
    "usoInformacionUsuarios",
    "cookies",
    "webBeacons",
    "spam",
    "ordenAutoridades",
    "seguridadAlmacenamiento",
    "cambioEmails",
    "responsableProteccion",
    "areaPeticiones",
    "mediosDerechos",
    "periodoVigenciaDatos",
    "politicaCambiosDevolucion",
    "politicaCambios",
    "generaCambio",
    "productoNoPedi",
    "enviarProducto",
    "condicionesDevoluciones",
    "devolverProducto",
    "derechoRetracto",
    "costoDevolucion",
    "solicitoReembolso",
    "prestadoresServicios",
    "subastas",
  ];
  constructor() {}
}
