import { Injectable } from "@angular/core";
import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  Query,
  QueryConstraint,
  QueryDocumentSnapshot,
  QuerySnapshot,
  SetOptions,
  addDoc,
  collection,
  collectionData,
  deleteDoc,
  doc,
  getDoc,
  getDocFromCache,
  getDocFromServer,
  getDocs,
  query,
  setDoc,
  updateDoc,
} from "@angular/fire/firestore";
import { Observable, firstValueFrom, from, map } from "rxjs";
import { TypeRef } from "../enums/TypeRef";
import { v4 as uuidv4 } from "uuid";

@Injectable({
  providedIn: "root",
})
/**
 * @author Daniel Agudelo <danielagudelo103@gmail.com>
 * Service that handles Firebase requests centrally.
 */
export class FirebaseRequestsService {
  constructor(private firestore: Firestore) {}

  /**
   * Returns a Promise that resolves with the document data (fields and values) for the document
   * referred to by the provided `pathCollectionWithDoc`. It's directly from the server
   *
   * @param pathCollectionWithDoc - A slash-separated path to the document within the Firestore database.
   * @returns A Promise that resolves with the document data.
   */
  getDocFirebaseServer(
    pathCollectionWithDoc: string
  ): Promise<DocumentSnapshot<DocumentData>> {
    const docRef: DocumentReference<DocumentData> = doc(
      this.firestore,
      pathCollectionWithDoc
    );

    return getDocFromServer(docRef);
  }

  /**
   * Returns a Promise that resolves with the document data (fields and values) for the document
   * referred to by the provided `pathCollectionWithDoc`. It's directly from the cache
   *
   * @param pathCollectionWithDoc - A slash-separated path to the document within the Firestore database.
   * @returns A Promise that resolves with the document data.
   */
  getDocFirebaseCache(
    pathCollectionWithDoc: string
  ): Promise<DocumentSnapshot<DocumentData>> {
    const docRef: DocumentReference<DocumentData> = doc(
      this.firestore,
      pathCollectionWithDoc
    );

    return getDocFromCache(docRef);
  }

  /**
   * Returns an Observable that resolves with a DocumentSnapshot for the document at the specified
   * path. It can be from the cache or the server
   *
   * @param pathCollectionWithDoc - A slash-separated path to the document within the Firestore database.
   * @returns An Observable that resolves with the DocumentSnapshot.
   */
  getDocFirebaseObservable(
    pathCollectionWithDoc: string
  ): Observable<DocumentSnapshot<DocumentData>> {
    const docRef: DocumentReference<DocumentData> = doc(
      this.firestore,
      pathCollectionWithDoc
    );
    return from(getDoc(docRef));
  }

  /**
   * Returns a Promise that resolves with the document data (fields and values) for the document referred to by the provided
   * `pathCollectionWithDoc`. It can be from the cache or the server
   *
   * @param pathCollectionWithDoc - A slash-separated path to the document within the Firestore database.
   * @returns A Promise that resolves with the document data.
   */
  getDocFirebasePromise(
    pathCollectionWithDoc: string
  ): Promise<DocumentSnapshot<DocumentData>> {
    const docRef: DocumentReference<DocumentData> = doc(
      this.firestore,
      pathCollectionWithDoc
    );
    return getDoc(docRef);
  }

  /**
   * Returns a Promise that resolves with the document data (fields and values) for the document referred to by the provided
   * `pathCollection`.
   *
   * @param pathCollectionWithDoc - A slash-separated path to the document within the Firestore database.
   * @returns A Promise that resolves with the document data.
   */
  async getDocFirebaseWithRef(
    pathCollectionWithDoc: string
  ): Promise<DocumentData> {
    const docRef: DocumentReference<DocumentData> = doc(
      this.firestore,
      pathCollectionWithDoc
    );

    const resSnap: DocumentSnapshot = await getDoc(docRef);

    return {
      ...resSnap.data(),
      ref: resSnap.ref,
    };
  }

  /**
   * * Returns a Promise that resolves to the document data (fields and values) with the document id
   * `pathCollection`.
   *
   * @param pathCollectionWithDoc - A slash-separated path to the document within the Firestore database.
   * @returns A Promise that resolves with the document data.
   */
  async getDocFirebaseWithIdPromise<T>(
    pathCollectionWithDoc: string
  ): Promise<T> {
    const docRef: DocumentReference<DocumentData> = doc(
      this.firestore,
      pathCollectionWithDoc
    );

    const resSnap: DocumentSnapshot = await getDoc(docRef);

    return {
      ...resSnap.data(),
      id: resSnap.id,
    } as T;
  }

  /**
   * * Returns a Observable that resolves to the document data (fields and values) with the document id
   * `pathCollection`.
   *
   * @param pathCollectionWithDoc - A slash-separated path to the document within the Firestore database.
   * @returns A Promise that resolves with the document data.
   */
  getDocFirebaseWithIdObservable<T>(
    pathCollectionWithDoc: string
  ): Observable<T> {
    const docRef: DocumentReference<DocumentData> = doc(
      this.firestore,
      pathCollectionWithDoc
    );

    return from(getDoc(docRef)).pipe(
      map(
        (resSnap: DocumentSnapshot) =>
          ({
            ...resSnap.data(),
            id: resSnap.id,
          }) as T
      )
    );
  }

  /**
   * Returns a Promise that resolves with an array of document data (fields and values) for all documents
   * within the collection referred to by the provided `pathCollection`.
   *
   * @param pathCollection - A slash-separated path to the collection within the Firestore database.
   * @param queryConstraint - An array of `QueryConstraint`s to apply to the query.
   * @returns A Promise that resolves with the array of document data.
   */
  async getCollectionFirebasePromiseWithRef(
    pathCollection: string,
    queryConstraint: QueryConstraint[] = []
  ): Promise<DocumentData[]> {
    const collectionRef: CollectionReference<DocumentData> = collection(
      this.firestore,
      pathCollection
    );

    const q: Query<DocumentData> = query(collectionRef, ...queryConstraint);

    const resSnap: QuerySnapshot = await getDocs(q);

    const data: DocumentData[] = resSnap.docs.map(
      (docSnap: QueryDocumentSnapshot<DocumentData>) => ({
        ...docSnap.data(),
        ref: docSnap.ref,
      })
    );

    return data;
  }

  async getCollectionFirebasePromiseWithDocSnap(
    pathCollection: string,
    queryConstraint: QueryConstraint[] = []
  ): Promise<DocumentData[]> {
    const collectionRef: CollectionReference<DocumentData> = collection(
      this.firestore,
      pathCollection
    );

    const q: Query<DocumentData> = query(collectionRef, ...queryConstraint);

    const resSnap: QuerySnapshot = await getDocs(q);

    const data: DocumentData[] = resSnap.docs.map(
      (docSnap: QueryDocumentSnapshot<DocumentData>) => ({
        ...docSnap.data(),
        id: docSnap.id,
        docSnap: docSnap,
      })
    );

    return data;
  }

  /**
   * Returns a Promise that resolves with an array of document data (fields and values) for all documents
   * within the collection id to by the provided `pathCollection`.
   *
   * @param pathCollection - A slash-separated path to the collection within the Firestore database.
   * @param queryConstraints - An array of `QueryConstraint`s to apply to the query.
   * @returns A Promise that resolves with the array of document data.
   */
  async getCollectionFirebasePromiseWithId<T>(
    pathCollection: string,
    queryConstraints: QueryConstraint[] = []
  ): Promise<T[]> {
    const collectionRef: CollectionReference<DocumentData> = collection(
      this.firestore,
      pathCollection
    );
    const q: Query<DocumentData> = query(collectionRef, ...queryConstraints);

    return firstValueFrom(
      collectionData(q, {
        idField: "id",
      })
    ) as Promise<T[]>;
  }

  /**
   * Returns an Observable that emits all documents in the collection as they are added, modified, or removed.
   *
   * @param pathCollection - A slash-separated path to the collection within the Firestore database.
   * @param queryConstraints - An array of `QueryConstraint`s to apply to the query.
   * @returns An Observable that emits a `DocumentData[]` containing the current set of documents in the collection.
   */
  getCollectionFirebaseWithQueryObservable<T>(
    pathCollection: string,
    queryConstraints: QueryConstraint[] = []
  ): Observable<T[]> {
    const collectionRef: CollectionReference<DocumentData> = collection(
      this.firestore,
      pathCollection
    );
    const q: Query<DocumentData> = query(collectionRef, ...queryConstraints);

    return collectionData(q, {
      idField: "id",
    }) as Observable<T[]>;
  }

  /**
   * Returns a Promise that resolves to an Object of type QuerySnapshot
   *
   * @param pathCollection - A slash-separated path to the collection within the Firestore database.
   * @param queryConstraint - An array of `QueryConstraint`s to apply to the query.
   * @returns A Promise that resolves with an QuerySnapshot.
   */
  getAllCollectionFirebasePromiseQuerySnapshot(
    pathCollection: string,
    queryConstraint: QueryConstraint[] = []
  ): Promise<QuerySnapshot> {
    const collectionRef: CollectionReference<DocumentData> = collection(
      this.firestore,
      pathCollection
    );
    const q: Query<DocumentData> = query(collectionRef, ...queryConstraint);

    return getDocs(q);
  }

  /**
   * Returns a reference to the specified location in the database.
   *
   * @param pathCollection - A slash-separated path to the collection or document within the Firestore database.
   * @param typeRef - The type of reference to return. Can be either 'collection' or 'doc'.
   * @returns A reference to the specified location.
   */
  getFirebaseReferences(
    pathCollection: string,
    typeRef: TypeRef
  ): CollectionReference<DocumentData> | DocumentReference<DocumentData> {
    return typeRef === "collection"
      ? collection(this.firestore, pathCollection)
      : doc(this.firestore, pathCollection);
  }

  /**
   * Returns a reference to the specified location in the database.
   *
   * @param pathDoc - A slash-separated path to the document within the Firestore database.
   * @returns A reference to the specified location.
   */
  getFirebaseDocReference(pathDoc: string): DocumentReference<DocumentData> {
    return doc(this.firestore, pathDoc);
  }

  /**
   * Returns a reference to the specified location in the database.
   *
   * @param doc - A slash-separated path to the document within the Firestore database.
   * @returns A reference to the specified location.
   */
  getFirebaseDocSnapReference(
    doc: DocumentReference
  ): Promise<DocumentSnapshot> {
    return getDoc(doc);
  }

  /**
   * Returns a reference to the specified location in the database.
   *
   * @param pathCollection - A slash-separated path to the collection within the Firestore database.
   * @returns A reference to the specified location.
   */
  getFirebaseCollectionReference(
    pathCollection: string
  ): CollectionReference<DocumentData> {
    return collection(this.firestore, pathCollection);
  }

  /**
   * Adds a new document to the specified collection with a user-defined ID,
   * overwriting any existing data in the document.
   *
   * @param pathDoc - The path of the document to add, including the collection name and document ID.
   * @param data - The data to store in the document.
   * @returns A Promise that resolves when the document has been written to the database.
   */
  addMDocFirebaseWithCustomId(pathDoc: string, data: any): Promise<void> {
    const refDoc: DocumentReference<DocumentData> = doc(
      this.firestore,
      pathDoc
    );
    return setDoc(refDoc, data, { merge: false });
  }

  /**
   * Adds a new document to the specified collection with an ID defined by firebase,
   * overwriting any existing data in the document.
   *
   * @param pathCollection - The path collection to add the new document
   * @param data - The data to store in the document.
   * @returns A Promise that resolves when the document has been written to the database, returning an object of type DocumentReference
   */
  addDocFirebaseWithAutomaticId(
    pathCollection: string,
    data: any
  ): Promise<DocumentReference> {
    const refDoc: CollectionReference = collection(
      this.firestore,
      pathCollection
    );
    return addDoc(refDoc, data);
  }

  /**
   * Updates the document referred to by the specified `pathDoc` with the specified data.
   *
   * @param pathDoc - The path of the document to update, including the collection name and document ID.
   * @param dataUpdate - An object containing the fields and values with which to update the document.
   * @returns This method does not return a value.
   */
  updateDocFirebase(pathDoc: string, dataUpdate: any): Promise<void> {
    const refDoc: DocumentReference<DocumentData> = doc(
      this.firestore,
      pathDoc
    );

    return updateDoc(refDoc, dataUpdate);
  }

  /**
   * Updates the document referred to by the specified `pathDoc` with the specified data.
   * using the setDoc method
   *
   * @param pathDoc - The path of the document to update, including the collection name and document ID.
   * @param dataUpdate - An object containing the fields and values with which to update the document.
   * @param options - options to use when updating the document
   * @returns This method does not return a value.
   */
  updateDocFirebaseWithSetDoc(
    pathDoc: string,
    dataUpdate: any,
    options: SetOptions = {}
  ): Promise<void> {
    const refDoc: DocumentReference<DocumentData> = doc(
      this.firestore,
      pathDoc
    );

    return setDoc(refDoc, dataUpdate, options);
  }

  /**
   * Deletes the document at the specified path.
   *
   * @param pathDoc - The path of the document to delete, including the collection name and document ID.
   * @returns This method does not return a value.
   */
  deleteDocFirebase(pathDoc: string): Promise<void> {
    const refDoc: DocumentReference<DocumentData> = doc(
      this.firestore,
      pathDoc
    );

    return deleteDoc(refDoc);
  }

  createId(): string {
    return uuidv4();
  }
}
