import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Select } from "@ngxs/store";
import { Observable, Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import {
  AuthenticationState,
  AuthenticationStateModel,
} from "../state/authentication-state";
import { Path } from "../enums/Path";
import { User } from "@angular/fire/auth";
import { SubscriptionService } from "../services/subscriptions.service";
import { SuscripcionActiva } from "../models/subscripcion.interface";

@Component({
  selector: "app-btn-registro",
  templateUrl: "./btn-registro.component.html",
  styleUrls: ["./btn-registro.component.css"],
})
export class BtnRegistroComponent implements OnInit, OnDestroy {
  private unsubscriber: Subject<void> = new Subject();
  @Select(AuthenticationState.user)
  public user$!: Observable<AuthenticationStateModel["user"]>;
  public path: typeof Path = Path;
  public isSubscribed!: boolean;
  public user: User | null = null;

  constructor(
    private router: Router,
    private suscripcionSvc: SubscriptionService
  ) {}

  ngOnInit(): void {
    this.user$
      .pipe(
        takeUntil(this.unsubscriber),
        tap({
          next: (value: User | null) => {
            this.user = value;
            if (value) {
              this.getSubscripcion(value.uid);
            } else {
              this.isSubscribed = false;
            }
          },
          error: (error) => {
            console.log(error);
          },
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  redireccionarRegistro(): void {
    this.router.navigate([Path.Auth, Path.SeleccionDeRegistro]);
  }

  redireccionarSuscripcion(): void {
    this.router.navigate([Path.Proyecto]);
  }

  getSubscripcion(id: string): void {
    if (!id) {
      return;
    }

    this.suscripcionSvc
      .getActiveSubscription(id)
      .then((response: SuscripcionActiva) => {
        this.isSubscribed = response?.suscripcionActual ? true : false;
      })
      .catch((err) => console.error(err));
  }
}
