import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { CrearProductoComponent } from "../../crear-producto.component";
import { BaseFormComponent } from "../base-form.component";
import { monedaLista } from "src/assets/data/monedas";
import { AuthService } from "src/app/services/auth.service";
import { UserService } from "src/app/services/user.service";
import { BaseFormService } from "src/app/services/base-form.service";

@Component({
  selector: "app-arbol-en-pie",
  templateUrl: "./arbol-en-pie.component.html",
  styleUrls: ["./arbol-en-pie.component.css"],
})
export class ArbolEnPieComponent extends BaseFormComponent implements OnInit {
  public listaMonedas: string[] = monedaLista;

  constructor(
    public override crearProductoComponent: CrearProductoComponent,
    public override authService: AuthService,
    public override userService: UserService,
    public override formBuilder: FormBuilder,
    public override baseFormService: BaseFormService
  ) {
    super(
      crearProductoComponent,
      authService,
      userService,
      formBuilder,
      baseFormService
    );
  }

  override ngOnInit(): void {
    this.addControls();
    this.updateAllLocations();
  }

  addControls(): void {
    // se agregan campos faltantes
    const fieldsToAdd = [
      { nombre: "edad", validators: [Validators.required] },
      { nombre: "volumenTotal" },
    ];

    // campos sobrantes
    const fieldsToRm: string[] = ["alturaBolsa", "calibreBolsa"];

    fieldsToAdd.map((field) =>
      this.productoForm.addControl(
        field.nombre,
        this.formBuilder.control("", field.validators)
      )
    );

    fieldsToRm.map((field: string) => this.productoForm.removeControl(field));

    // se envía formulario completo
    this.visualizar && this.baseFormService.completForm.next(this.productoForm);
  }
}
