<div class="seccion">
  <h2 class="titulo-seccion">Red Forestal S.A.S</h2>

  <p class="parrafo-seccion">
    {{ "empresa.parrafo1" | translate }}
  </p>
  <p class="parrafo-seccion">
    {{ "empresa.parrafo2" | translate }}
  </p>
  <p class="parrafo-seccion">
    {{ "empresa.parrafo3" | translate }}
  </p>
  <p class="parrafo-seccion">
    {{ "empresa.parrafo4" | translate }}
  </p>
  <!--------- Projects ------->
  <h2 class="titulo-seccion mt-5">{{ "empresa.title_projec" | translate }}</h2>
  <!--------- redforestal.com Project ------->
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4">
        <iframe
          class="video-youtube"
          src="https://www.youtube.com/embed/OCHof8KDrRI?si=v9Zpj4HlE8JEE6go"
          title="YouTube video player"
          allowfullscreen
        ></iframe>
      </div>
      <div class="col-md-8">
        <h5 class="mt-0">
          <b>{{ "empresa.project_01" | translate }}</b>
        </h5>
        <p class="mb-0">
          <b>{{ "empresa.year" | translate }}</b> 2021
        </p>
        <p class="mb-0">
          <b>{{ "empresa.description" | translate }}</b>
          {{ "empresa.des_01" | translate }}
        </p>
      </div>
    </div>
  </div>
  <!--------- FinkApp Project ------->
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4">
        <iframe
          class="video-youtube"
          src="https://www.youtube.com/embed/oq43t3bjuoI?si=J_RiwVjwLck0jxNA"
          title="YouTube video player"
          allowfullscreen
        ></iframe>
      </div>
      <div class="col-md-8">
        <h5 class="mt-0">
          <b>{{ "empresa.project_02" | translate }}</b>
        </h5>
        <p class="mb-0">
          <b>{{ "empresa.year" | translate }}</b> 2022
        </p>
        <p class="mb-0">
          <b>{{ "empresa.description" | translate }}</b>
          {{ "empresa.des_02" | translate }}
        </p>
      </div>
    </div>
  </div>
  <!--------- WoodTracer Project ------->
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4">
        <iframe
          class="video-youtube"
          src="https://www.youtube.com/embed/AJM0-GY9vxg?si=5szo6060viyvpTBF"
          title="YouTube video player"
          allowfullscreen
        ></iframe>
      </div>
      <div class="col-md-8">
        <h5 class="mt-0">
          <b>{{ "empresa.project_03" | translate }}</b>
        </h5>
        <p class="mb-0">
          <b>{{ "empresa.year" | translate }}</b> 2023
        </p>
        <p class="mb-0">
          <b>{{ "empresa.description" | translate }}</b>
          {{ "empresa.des_03" | translate }}
        </p>
      </div>
    </div>
  </div>

  <!--------- company recognitions ------->
  <h2 class="titulo-seccion mt-5">
    {{ "empresa.reconocimientos" | translate }}
  </h2>
  <ul>
    <li>
      <b>2012 -</b>
      <a
        href="https://www.facebook.com/watch/?v=600645197493709"
        target="_blank"
      >
        {{ "empresa.fondoEmprender" | translate }}
      </a>
    </li>
    <li><b>2012 -</b> {{ "empresa.ventures" | translate }}</li>
    <li><b>2013 -</b> {{ "empresa.colciencias" | translate }}</li>
    <li><b>2016 -</b> {{ "empresa.emprendedorLatinoamerica" | translate }}</li>
    <li><b>2016 -</b> {{ "empresa.premiosJuventud" | translate }}</li>
    <li>
      <b>2017 -</b>
      <a
        href="https://www.premioslatinoamericaverde.com/top500/?years=2017"
        target="_blank"
      >
        {{ "empresa.ranking4" | translate }}
      </a>
    </li>
    <li>
      <b>2018 -</b>
      {{ "empresa.foro" | translate }}
    </li>
    <li><b>2019 -</b> {{ "empresa.iufro" | translate }}</li>
    <li>
      <b>2020 -</b>
      <a
        href="https://www.madera21.cl/project-view/aplicativo-web-para-el-comercio-electronico-de-madera-legal-y-productos-forestales-no-maderables/"
        target="_blank"
      >
        {{ "empresa.semanaMadera" | translate }}
      </a>
    </li>
    <li>
      <b>2020 -</b>
      <a href="https://activa.catie.ac.cr/web/red-forestal/" target="_blank">
        {{ "empresa.innovature" | translate }}
      </a>
    </li>
    <li>
      <b>2021 -</b>
      <a
        href="https://titanes.noticias.caracoltv.com/nominado/juan-francisco-guzman"
        target="_blank"
      >
        {{ "empresa.titanes" | translate }}
      </a>
    </li>
    <li>
      <b>2023 -</b>
      <a
        href="https://britcham.com.co/premios-britcham-lazos-2023/"
        target="_blank"
      >
        {{ "empresa.lazos" | translate }}
      </a>
    </li>
  </ul>
  <!--------- Work Team ------->
  <div class="container-fluid">
    <h2 class="titulo-seccion mb-3">
      {{ "empresa.nuestroEquipo" | translate }}
    </h2>
    <div class="row">
      <div class="col-lg-6">
        <div class="d-sm-flex">
          <div class="flex-shrink-0">
            <img
              src="../../assets/imagenes/f_perfil_1.webp"
              class="img-profile"
              alt="..."
            />
          </div>
          <div class="flex-grow-1 ms-3">
            <h5 class="mt-0">Juan Francisco Guzmán Zabala</h5>
            <p class="mb-0">{{ "empresa.juanfCargo" | translate }}</p>
            <p class="mb-0">{{ "empresa.profesionJuanf" | translate }}</p>
            <p class="mb-0">{{ "empresa.especializacionJuanf" | translate }}</p>
            <p class="mb-0">{{ "empresa.masterJuanf" | translate }}</p>
            <p>info&#64;redforestal.com</p>
          </div>
        </div>
        <div class="d-sm-flex">
          <div class="flex-shrink-0">
            <img
              src="../../assets/imagenes/f_perfil_2.png"
              class="img-profile"
              alt="..."
            />
          </div>
          <div class="flex-grow-1 ms-3">
            <h5 class="mt-0">Juan Felipe Arenas Moreno</h5>
            <p class="mb-0">{{ "empresa.felipeCargo" | translate }}</p>
            <p class="mb-0">{{ "empresa.felipeProfesion" | translate }}</p>
            <p class="mb-0">info&#64;redforestal.com</p>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="d-sm-flex">
          <div class="flex-shrink-0">
            <img
              src="../../assets/imagenes/f_perfil_3.webp"
              class="img-profile"
              alt="..."
            />
          </div>
          <div class="flex-grow-1 ms-3">
            <h5 class="mt-0">David Esteban Gutierrez Guzmán</h5>
            <p class="mb-0">{{ "empresa.davidCargo" | translate }}</p>
            <p class="mb-0">{{ "empresa.davidProfesion" | translate }}</p>
            <p class="mb-0">{{ "empresa.davidMaster" | translate }}</p>
            <p class="mb-0">info&#64;redforestal.com</p>
          </div>
        </div>
        <div class="d-sm-flex">
          <div class="flex-shrink-0">
            <img
              src="../../assets/imagenes/webpLeo.webp"
              class="img-profile"
              alt="..."
            />
          </div>
          <div class="flex-grow-1 ms-3">
            <h5 class="mt-0">Leonardo Iregui Albarracín</h5>
            <p class="mb-0">{{ "empresa.leonardoCargo" | translate }}</p>
            <p class="mb-0">{{ "empresa.leonardoProfesion" | translate }}</p>
            <p class="mb-0">{{ "empresa.leonardoEsp" | translate }}</p>
            <p>info&#64;redforestal.com</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--------- call to action button ------->
  <app-btn-registro></app-btn-registro>
</div>
