import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ComprasComponent } from "./compras/compras.component";
import { VentasComponent } from "./ventas/ventas.component";
import { DatosPersonalesComponent } from "./datos-personales/datos-personales.component";
import { ServiciosComponent } from "./servicios/servicios.component";
import { CalificacionComponent } from "./calificacion/calificacion.component";
import { ContabilidadComponent } from "./contabilidad/contabilidad.component";
import { TreeCoinComponent } from "./tree-coin/tree-coin.component";
import { CrearProductoComponent } from "./crear-producto/crear-producto.component";
import { FavoritosComponent } from "./favoritos/favoritos.component";
import { CrearServicioComponent } from "./crear-servicio/crear-servicio.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgxPaginationModule } from "ngx-pagination";
import { CalificacionyComentariosComponent } from "../calificacion-y-comentarios/calificacion-y-comentarios.component";
// import { SubastaComponent } from "./subasta/subasta.component";
import { PerfilUsuariosRoutingModule } from "./perfil-usuarios-routing.module";
import { TecnicoComponent } from "./crear-servicio/tecnico/tecnico.component";
import { TransportadorComponent } from "./crear-servicio/transportador/transportador.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { NgxCurrencyDirective } from "ngx-currency";
import { PqrComentariosComponent } from "../pqr-comentarios/pqr-comentarios.component";
import { ApplicationPipesModule } from "../application-pipes/application-pipes-module";
import { ConversacionComponent } from "./conversacion/conversacion.component";
import { MisProductosComponent } from "./crear-producto/mis-productos/mis-productos.component";
import { BosqueComponent } from "./crear-producto/forms/bosque/bosque.component";
import { FormsComponent } from "./crear-producto/forms/forms.component";
import { ArbolEnPieComponent } from "./crear-producto/forms/arbol-en-pie/arbol-en-pie.component";
import { MaderaRollizaComponent } from "./crear-producto/forms/madera-rolliza/madera-rolliza.component";
import { MaderaAserradaComponent } from "./crear-producto/forms/madera-aserrada/madera-aserrada.component";
import { NoMaderableComponent } from "./crear-producto/forms/no-maderable/no-maderable.component";
import { ViveroComponent } from "./crear-producto/forms/vivero/vivero.component";
import { EquipoMaquinariaComponent } from "./crear-producto/forms/equipo-maquinaria/equipo-maquinaria.component";
import { BonosCarbonoComponent } from "./crear-producto/forms/bonos-carbono/bonos-carbono.component";
import { ProductoTerminadoComponent } from "./crear-producto/forms/producto-terminado/producto-terminado.component";
import { InsumosComponent } from "./crear-producto/forms/insumos/insumos.component";
import { BaseFormComponent } from "./crear-producto/forms/base-form.component";
import { ModalCrearComponent } from "./crear-producto/modal-crear/modal-crear.component";
import { ErroresComponent } from "./crear-producto/forms/errores/errores.component";
import { PerfilUsuariosComponent } from "./perfil-usuarios.component";
import { LicenciasComponent } from "./licencias/licencias.component";
import { ReportesComponent } from "./reportes/reportes.component";
import { WoodtracerModule } from "../woodtracer/woodtracer.module";
import { MensajesComponent } from "../mensajes/mensajes.component";
import { CertificadoProductoComponent } from "../certificado-producto/certificado-producto.component";
import { ComponentesGlobalesModule } from "../componentes-globales/componentes-globales.module";
import { LandsComponent } from "./crear-producto/forms/lands/lands.component";

const productForms = [
  BosqueComponent,
  FormsComponent,
  ArbolEnPieComponent,
  MaderaRollizaComponent,
  MaderaAserradaComponent,
  NoMaderableComponent,
  ViveroComponent,
  EquipoMaquinariaComponent,
  BonosCarbonoComponent,
  ProductoTerminadoComponent,
  InsumosComponent,
  LandsComponent,
  BaseFormComponent,
  ModalCrearComponent,
  ErroresComponent,
];

@NgModule({
  declarations: [
    ComprasComponent,
    VentasComponent,
    DatosPersonalesComponent,
    ServiciosComponent,
    CalificacionComponent,
    ContabilidadComponent,
    TreeCoinComponent,
    CrearProductoComponent,
    FavoritosComponent,
    CrearServicioComponent,
    CalificacionyComentariosComponent,
    // SubastaComponent,
    TecnicoComponent,
    TransportadorComponent,
    CertificadoProductoComponent,
    PqrComentariosComponent,
    MensajesComponent,
    ConversacionComponent,
    MisProductosComponent,
    LicenciasComponent,
    ReportesComponent,
    PerfilUsuariosComponent,
    LandsComponent,
    ...productForms,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    PerfilUsuariosRoutingModule,
    NgbModule,
    TranslateModule,
    NgxCurrencyDirective,
    // SubastasModule,
    ComponentesGlobalesModule,
    ApplicationPipesModule,
    WoodtracerModule,
  ],
  exports: [
    CalificacionyComentariosComponent,
    PqrComentariosComponent,
    ...productForms,
  ],
})
export class PerfilUsuariosModule {}
