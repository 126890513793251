import { Injectable } from "@angular/core";
import { Timestamp, arrayUnion, orderBy, where } from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import moment from "moment";
import { StorageService } from "./storage.service";
import { FirebaseRequestsService } from "./firebase-requests.service";
import { Preguntas, Respuesta } from "../models/preguntas.interface";

@Injectable({
  providedIn: "root",
})
export class PreguntasService {
  constructor(
    private firebaseRequest: FirebaseRequestsService,
    private storage: StorageService
  ) {}

  /**
   * Maps a Firestore document snapshot to a Pregunta object, including the user's profile image.
   *
   * @param action - The Firestore document snapshot to map.
   * @returns The Pregunta object.
   */
  private mapPregunta(action: Preguntas): Preguntas {
    action["imagen"] = this.getImageObservable(
      `fotoPerfilUser/${action["idRemitente"]}_500x500`,
      `fotoPerfilUser/${action["idRemitente"]}`
    );

    if (action["respuestas"]) {
      action["respuestas"].forEach((respuesta: Respuesta) => {
        respuesta["imagen"] = this.getImageObservable(
          `fotoPerfilUser/${respuesta["idUsuario"]}_500x500`,
          `fotoPerfilUser/${respuesta["idUsuario"]}`
        );
      });
    }

    return action;
  }

  /**
   * Returns an Observable that emits a URL to an image stored in the Firebase storage bucket.
   * If the image is not found, the Observable emits a URL to a default image.
   *
   * @param path1 - The first path to attempt to retrieve the image from.
   * @param path2 - The second path to attempt to retrieve the image from.
   * @returns An Observable that emits an URL to an image stored in the Firebase storage
   */
  private getImageObservable(path1: string, path2: string): Observable<string> {
    return this.storage
      .getStoreUrlImageObservable(path1)
      .pipe(catchError(() => this.storage.getStoreUrlImageObservable(path2)));
  }

  /**
   * Formats a timestamp into a human-friendly date string.
   *
   * @param timestamp - The timestamp to format.
   * @returns The formatted date string.
   */
  private formatFecha(timestamp: number): string {
    return moment(timestamp).locale("es").format("D  MMMM [de] YYYY hh[:]mm a");
  }

  /**
   * Formats a timestamp into a human-friendly date string.
   *
   * @param date - timestamp seconds * 1000
   * @returns The formatted date string.
   */
  private formatTimestamp(date: number): string {
    return moment(date).locale("es").fromNow();
  }

  savePregunta(datos: Partial<any>): Promise<void> {
    const customId: string = this.firebaseRequest.createId();

    return this.firebaseRequest.addMDocFirebaseWithCustomId(
      `preguntas/${customId}`,
      {
        ...datos,
        idPregunta: customId,
        fechaUltimoMensaje: Timestamp.now(),
      }
    );
  }

  getPreguntas(idProducto: string): Observable<Preguntas[]> {
    return this.firebaseRequest
      .getCollectionFirebaseWithQueryObservable<Preguntas>("preguntas", [
        orderBy("fechaCreacion", "desc"),
        where("idProducto", "==", idProducto),
      ])
      .pipe(
        map((actions: Preguntas[]) =>
          actions.map((action: Preguntas) => this.mapPregunta(action))
        )
      );
  }

  addRespuesta(idPregunta: string, respuesta: Respuesta): Promise<void> {
    return this.firebaseRequest.updateDocFirebase(`preguntas/${idPregunta}`, {
      fechaUltimoMensaje: Timestamp.now(),
      respuestas: arrayUnion(respuesta),
    });
  }

  getPreguntasVentas(idVendedor: string): Observable<Preguntas[]> {
    return this.firebaseRequest
      .getCollectionFirebaseWithQueryObservable<Preguntas>("preguntas", [
        orderBy("fechaCreacion", "desc"),
        where("idVendedor", "==", idVendedor),
      ])
      .pipe(
        map((actions: Preguntas[]) =>
          actions.map((action: Preguntas) => {
            if (action["respuestas"]) {
              const lastMsj: Respuesta =
                action["respuestas"][action["respuestas"].length - 1];

              action["fechaUltimoMensaje"]
                ? action["fechaUltimoMensaje"]
                : lastMsj.fecha;
              action["lastMsj"] = lastMsj.mensaje; // asignación de ultimo mensaje
            } else {
              action["lastMsj"] = action["mensaje"]; // asignación ultimo mensaje
              action["fechaUltimoMensaje"] = action["fechaCreacion"];
            }
            return action;
          })
        )
      ) as Observable<Preguntas[]>;
  }

  getPreguntasCompras(idComprador: string): Observable<Preguntas[]> {
    return this.firebaseRequest
      .getCollectionFirebaseWithQueryObservable<Preguntas>("preguntas", [
        orderBy("fechaCreacion", "desc"),
        where("idRemitente", "==", idComprador),
      ])
      .pipe(
        map((actions: Preguntas[]) =>
          actions.map((action: Preguntas) => {
            if (action["respuestas"]) {
              const lastMsj: Respuesta =
                action["respuestas"][action["respuestas"].length - 1];

              action["fechaUltimoMensaje"]
                ? action["fechaUltimoMensaje"]
                : lastMsj.fecha;
              action["lastMsj"] = lastMsj.mensaje; // asignación de ultimo mensaje
            } else {
              action["lastMsj"] = action["mensaje"]; // asignación ultimo mensaje
              action["fechaUltimoMensaje"] = action["fechaCreacion"];
            }
            return action;
          })
        )
      ) as Observable<Preguntas[]>;
  }

  getPreguntaById(id: string): Observable<Preguntas[]> {
    return this.firebaseRequest.getCollectionFirebaseWithQueryObservable<Preguntas>(
      "preguntas",
      [where("idPregunta", "==", id)]
    );
  }
}
