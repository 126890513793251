import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { forkJoin, Subject } from "rxjs";
import { ProductoData } from "../models/ProductoData";
import { switchMap, takeUntil, tap } from "rxjs/operators";
import { ProductoService } from "../services/producto.service";
import { PublicidadService } from "../services/publicidad.service";
import { StorageService } from "../services/storage.service";
import { DocumentData } from "@angular/fire/firestore";

@Component({
  selector: "app-catalogo",
  templateUrl: "./catalogo.component.html",
  styleUrls: ["./catalogo.component.css"],
})
export class CatalogoComponent implements OnInit, OnDestroy {
  private unsubscriber: Subject<void> = new Subject();
  nuevosProductos: Array<ProductoData> = [];
  productos: Array<ProductoData> = [];
  page!: number;
  publicidad: any = {
    izquierda: {
      src: "../../assets/imagenes/espacioPublicitario.webp",
      href: "",
    },
    centro: {
      src: "../../assets/imagenes/espacioPublicitario.webp",
      href: "",
    },
    derecha: {
      src: "../../assets/imagenes/espacioPublicitario.webp",
      href: "",
    },
  };
  formFiltro!: FormGroup;
  lastProduct: any;
  disableMore!: boolean;
  loading = false;

  constructor(
    private productoService: ProductoService,
    private publicidadService: PublicidadService,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.getPublicidad();
    this.productoService.filtroProducto
      .pipe(
        takeUntil(this.unsubscriber),
        tap({
          next: () => {
            this.getProductos();
          },
          error: (error) => {
            console.error(error);
          },
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  getPublicidad(): void {
    this.publicidadService
      .getPublicidad(false)
      .pipe(
        takeUntil(this.unsubscriber),
        switchMap((publicidad: DocumentData[]) => {
          return forkJoin(
            publicidad.map((publicidad: DocumentData) => {
              return this.storageService
                .getStoreUrlImageObservable(publicidad["imagen"])
                .pipe(
                  takeUntil(this.unsubscriber),
                  tap({
                    next: (src: string) => {
                      this.publicidad[publicidad["nombre"]] = {
                        src,
                        href: publicidad["enlace"],
                      };
                    },
                    error: (error) => {
                      console.error(error);
                    },
                  })
                );
            })
          );
        })
      )
      .subscribe();
  }

  goTo(enlace: string): void {
    if (enlace) {
      window.open(enlace);
    }
  }

  async getProductos(resetProductos = true): Promise<void> {
    this.loading = true;
    const { productos, last } = await this.productoService.getProductFiltro(
      resetProductos ? undefined : this.lastProduct
    );

    this.lastProduct = last;
    if (resetProductos) {
      this.productos = productos;
      this.disableMore = false;
    } else if (productos.length) {
      this.productos.push(...productos);
    } else {
      this.disableMore = true;
    }
    this.loading = false;
  }
}
