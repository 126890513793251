<div class="card-body">
  <span [formGroup]="formPregunta" *ngIf="panelCompleto">
    <label for="preguntasProduct" class="form-label">
      <b>{{ "preguntas.tienesPregunta" | translate }}</b>
    </label>
    <textarea
      class="form-control"
      id="preguntasProduct"
      rows="3"
      formControlName="mensaje"
    ></textarea>
    <button
      class="btn btn-success mt-3"
      [disabled]="!formPregunta.valid"
      (click)="guardarPregunta()"
    >
      Enviar
    </button>
    <hr />
  </span>

  <h3 class="titulo-level3 mt-3">{{ "preguntas.conversacion" | translate }}</h3>
  <ul class="list-unstyled">
    <li
      class="media row"
      *ngFor="
        let item of listaPreguntas
          | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage };
        trackBy: trackPreguntas
      "
    >
      <div class="col-2 text-center">
        <img
          [src]="item.imagen | async"
          class="imagen-preguntas"
          alt="Cargando imagen"
        />
      </div>
      <div class="media-body col-10">
        <h5 class="titulo-comentario">{{ item.fechaCreacion | timestampToString }}</h5>
        <p class="texto-comentario">
          {{ item.mensaje }}
        </p>
      </div>

      <div class="col-12 text-right" *ngIf="item.respuestas?.length">
        <button
          class="btn btn-link"
          data-bs-toggle="collapse"
          data-bs-target="#collapseresp"
          aria-expanded="true"
          aria-controls="collapseresp"
          (click)="activarCollapse(item.id)"
        >
          + {{ "preguntas.verRespuestas" | translate }}
        </button>

        <div class="row">
          <div
            id="collapseresp{{ item.id }}"
            class="collapse hide col-12"
            aria-labelledby="headingOne"
            data-bs-parent="#accordion"
          >
            <div
              *ngFor="
                let respuesta of item.respuestas;
                trackBy: trackRespuestas
              "
            >
              <div class="row comentario">
                <div class="col-1 text-center">
                  <img
                    class="imagen-respuestas"
                    [src]="respuesta.imagen | async"
                    alt=""
                  />
                </div>
                <div class="col-8 text-left">
                  {{ respuesta.mensaje }}
                </div>
                <div class="col-3 m-auto">
                  <small>
                    {{ respuesta.fecha | timestampToString }}
                  </small>
                </div>
              </div>
            </div>

            <div
              class="col-12"
              *ngIf="
                usuarioActivo === item.idVendedor ||
                usuarioActivo === item.idRemitente
              "
            >
              <label for="preguntasProduct" class="form-label">
                <b>{{ "preguntas.responder" | translate }}</b>
              </label>
              <textarea
                class="form-control"
                id="preguntasProduct"
                #input
              ></textarea>
              <div class="text-right mb-3">
                <button
                  class="btn btn-success mt-3"
                  [disabled]="!input.value"
                  (click)="responder(item.id, input.value)"
                >
                  {{ "preguntas.responder" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-12"
        *ngIf="
          !item.respuestas?.length &&
          (usuarioActivo === item.idVendedor ||
            usuarioActivo === item.idRemitente)
        "
      >
        <label for="preguntasProduct" class="form-label">
          <b>{{ "preguntas.responder" | translate }}</b>
        </label>
        <textarea class="form-control" id="preguntasProduct" #input></textarea>
        <div class="text-right mb-3">
          <button
            class="btn btn-success mt-3"
            [disabled]="!input.value"
            (click)="responder(item.id, input.value)"
          >
            {{ "preguntas.responder" | translate }}
          </button>
        </div>
      </div>
    </li>
  </ul>
  <!--Paginación-->
  <pagination-controls
    class="my-pagination text-center"
    (pageChange)="currentPage = $event"
    previousLabel="{{ 'paginacion.anterior' | translate }}"
    nextLabel="{{ 'paginacion.siguiente' | translate }}"
  >
  </pagination-controls>
</div>
