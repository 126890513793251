<div class="seccion">
  <h2 class="titulo-seccion">{{ "alianzas.aliados" | translate }}</h2>
  <p class="parrafo-seccion">
    {{ "alianzas.tenemosUnaRed" | translate }}
  </p>
 
  <h3 class="subtitulo mt-5">{{ "alianzas.educacion" | translate }}</h3>
  <div
    class="row mt-2 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5"
  >
    <div class="col" *ngFor="let educacion of listaAliados[1]">
      <div class="card w-100 text-center mt-3" (click)="abrirEnlace(educacion)">
        <img
          [src]="educacion?.imagen"
          class="card-img-top"
          alt="Imagen de Aliado"
        />
        <div class="card-body">
          <h5 class="card-title">
            <b>{{ educacion.nombre }}</b>
          </h5>
          <p class="card-text">
            {{ educacion.descripcion }}
          </p>
        </div>
      </div>
    </div>
  </div>
 
  <h3 class="subtitulo mt-5">
    {{ "alianzas.gobierno" | translate }}
  </h3>
  <div
    class="row mt-2 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5"
  >
    <div class="col" *ngFor="let gobierno of listaAliados[2]">
      <div class="card w-100 mt-3" (click)="abrirEnlace(gobierno)">
        <img
          [src]="gobierno.imagen"
          class="card-img-top"
          alt="Imagen de Aliado"
        />
        <div class="card-body">
          <h5 class="card-title">
            <b>{{ gobierno.nombre }}</b>
          </h5>
          <p class="card-text">
            {{ gobierno.descripcion }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <h3 class="subtitulo mt-5">{{ "alianzas.emprendimiento" | translate }}</h3>
  <div
    class="row mt-2 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5"
  >
    <div class="col" *ngFor="let emprendimiento of listaAliados[3]">
      <div class="card w-100 text-center mt-3" (click)="abrirEnlace(emprendimiento)">
        <img
          [src]="emprendimiento.imagen"
          class="card-img-top"
          alt="Imagen de Aliado"
        />
        <div class="card-body">
          <h5 class="card-title">
            <b>{{ emprendimiento.nombre }}</b>
          </h5>
          <p class="card-text">
            {{ emprendimiento.descripcion }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <h3 class="subtitulo mt-5">{{ "alianzas.financiero" | translate }}</h3>
  <div
    class="row mt-2 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5"
  >
    <div class="col" *ngFor="let financiero of listaAliados[4]">
      <div class="card w-100 text-center mt-3" (click)="abrirEnlace(financiero)">
        <img
          [src]="financiero.imagen"
          class="card-img-top"
          alt="Imagen de Aliado"
        />
        <div class="card-body">
          <h5 class="card-title">
            <b>{{ financiero.nombre }}</b>
          </h5>
          <p class="card-text">
            {{ financiero.descripcion }}
          </p>
        </div>
      </div>
    </div>
  </div>

</div>
