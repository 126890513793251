import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { WtReportService } from "../service/wt-report.service";
import { WtReportStore } from "../service/wt-report.store";
import { Observable } from "rxjs";
import { WtReport } from "../model/wt-report";
import { FileDownloaderService } from "src/app/core/service/file-downlader.service";

/**
 * Este componente muestra los reportes asociados al 'WtUser' indicado en 'currentId'.
 *
 * @dev Este componente consulta de manera independiente los reportes, para poder que
 * sea reactivo a los cambios en la base de datos.
 */
@Component({
  selector: "app-report-details",
  templateUrl: "./report-details.component.html",
  styleUrls: ["./report-details.component.css"],
  providers: [WtReportService, WtReportStore],
})
export class ReportDetailsComponent implements OnInit {
  /**
   * Close popup (if form is a popup).
   */
  @Output() closeEventEmitter = new EventEmitter<boolean>();

  /**
   * ID del WtUser para el cuál cargar los reportes.
   */
  @Input() currentId!: string;

  /**
   * Observable con los reportes del WtUser indicado en 'currentId'.
   */
  public reports$!: Observable<WtReport[]>;

  constructor(
    protected crud: WtReportService,
    private downloader: FileDownloaderService
  ) {}

  /**
   * @dev Es necesario inicializar la colección en ngOnInit, porque debe estar
   * inicializado el componente para que el Input() funcione.
   */
  ngOnInit(): void {
    this.crud.paginate({
      paginate: "infinite",
      sorts: [{ field: "localId", direction: "desc" }],
      filters: [
        { field: "wtUserId", queryOperator: "==", value: this.currentId },
      ],
    });
    this.reports$ = this.crud.collection$;
  }

  /**
   * Close button click.
   */
  onClose() {
    this.closeEventEmitter.emit(true);
  }

  /**
   * Downloads file from url
   *
   * @param fileUrl
   * @param fileName
   */
  public async downloadFile(fileUrl: string, fileName: string): Promise<void> {
    await this.downloader.download(fileUrl, fileName);
  }
}
