<div
  class="card mb-4 box-shadow"
  [routerLink]="[path.DetalleDeProducto, producto.id || ''] | langRoute | async"
>
  <div class="imgHolder">
    <img
      class="card-img-top"
      [src]="producto.thumbnailUrl | getImage: defaultThumbnailUrl | async"
      alt="Imagen del producto"
    />
  </div>

  <div class="card-body">
    <ngb-rating
      class="star"
      [max]="5"
      [rate]="puntuacion"
      *ngIf="puntuacion"
      [readonly]="true"
    ></ngb-rating>
    <br />
    <h5>{{ producto.categoria }}</h5>
    <div *ngIf="producto.categoria">
      <h6 class="card-subtitle mb-2 text-muted">
        {{ producto.producto }}
      </h6>
    </div>
    <h6 class="card-subtitle mb-2 text-muted">
      ${{ producto.precio | number }} {{ producto.moneda | uppercase }}
    </h6>
    <h6 class="card-subtitle mb-2 text-muted">
      {{ producto.pais }} - {{ producto.departamento }}
    </h6>
    <h6 class="card-subtitle mb-2 text-muted">
      {{ producto.especie }}
    </h6>
  </div>
  <div class="card-footer">
    <div
      *ngIf="producto.sello"
      class="footer-sello"
      data-bs-tooltip="Producto Verificado"
    >
      <img
        class="foto-sello"
        src="../../assets/imagenes/logo_red_forestal_png.png"
      />
    </div>
    <div class="footer-perfil">
      <img
        class="foto-perfil rounded"
        [src]="vendedorPerfilUrl | getImage: defaultThumbnailUrl | async"
      />
    </div>
  </div>
</div>
