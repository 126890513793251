export const subproductoInsumos: string[] = [
  "Abono Orgánico",
  "Acaricida",
  "Adhesivos",
  "Alambres",
  "Bactericida",
  "Bandejas de Germinación",
  "Barniz",
  "Bolsa Vivero",
  "Cerca Eléctrica",
  "Coadyuvantes",
  "Cola",
  "Desinfectante de Suelo",
  "Elementos de Fumigación",
  "Enmienda Orgánica",
  "Fertilizantes biológicos",
  "Fertilizantes foliares",
  "Fertilizantes",
  "Fungicida",
  "Geomembrana",
  "Guayas para Invernadero",
  "Herbicida",
  "Herrajes",
  "Hidrorretenedores",
  "Hilos y Cuerdas",
  "Insecticida",
  "laca",
  "Lija",
  "Malla para Invernadero",
  "Mangueras",
  "Materas",
  "Nematicida",
  "Pegamento",
  "Pinturas",
  "Plástico para Invernadero",
  "Otros plásticos",
  "Polisombra",
  "Productos para Atadora de Tallos",
  "Puntillas y Grapas",
  "Regulador de Crecimiento",
  "Resane",
  "Rodenticida",
  "Silicona",
  "Sistemas y Accesorios para Riego",
  "Sustrato",
  "Tanques y Geotanques",
  "Tarugos",
  "Telas para Invernadero",
  "Tintes",
  "Tornillos",
  "Tratamiento de Semillas",
];
