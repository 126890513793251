import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, Validators } from "@angular/forms";
import { CrearProductoComponent } from "../../crear-producto.component";
import { BaseFormComponent } from "../base-form.component";
import { monedaLista } from "src/assets/data/monedas";
import { AuthService } from "src/app/services/auth.service";
import { UserService } from "src/app/services/user.service";
import { BaseFormService } from "src/app/services/base-form.service";
import { Subject, takeUntil, tap } from "rxjs";

@Component({
  selector: "app-vivero",
  templateUrl: "./vivero.component.html",
  styleUrls: ["./../forms.component.css"],
})
export class ViveroComponent
  extends BaseFormComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() frutal!: boolean;
  private sub$: Subject<void> = new Subject();
  public listaMonedas: string[] = monedaLista;

  constructor(
    public override crearProductoComponent: CrearProductoComponent,
    public override authService: AuthService,
    public override userService: UserService,
    public override formBuilder: FormBuilder,
    public override baseFormService: BaseFormService
  ) {
    super(
      crearProductoComponent,
      authService,
      userService,
      formBuilder,
      baseFormService
    );
  }

  override ngOnInit(): void {
    this.updateAllLocations();
  }

  override ngOnDestroy(): void {
    this.sub$.next();
    this.sub$.complete();
  }

  ngOnChanges(): void {
    this.addControls();
  }

  addControls(): void {
    // se agregan campos faltantes
    const fieldsToAdd = this.frutal
      ? [
          { nombre: "injerto", validators: [Validators.required] },
          { nombre: "nombrePatron" },
        ]
      : [];

    const fieldsToRm: string[] = ["sistema", "cantidadMinimaVenta"];

    fieldsToAdd.map((field) =>
      this.productoForm.addControl(
        field.nombre,
        this.formBuilder.control("", field.validators)
      )
    );

    fieldsToRm.map((field: string) => this.productoForm.removeControl(field));

    if (this.frutal) {
      this.productoForm
        .get("injerto")
        ?.valueChanges.pipe(
          takeUntil(this.sub$),
          tap({
            next: (value: any) => {
              const control: AbstractControl | null =
                this.productoForm.get("nombrePatron");
              if (+value) {
                control?.setValidators(Validators.required);
              } else {
                control?.clearValidators();
                control?.setValidators(null);
                control?.updateValueAndValidity();
              }
            },
            error: (err: Error) => {
              console.error(err);
            },
          })
        )
        .subscribe();
    }

    this.visualizar && this.baseFormService.completForm.next(this.productoForm);
  }
}
