import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Path } from "../enums/Path";

@Component({
  selector: "app-limite-pedidos",
  templateUrl: "./limite-pedidos.component.html",
  styleUrls: ["./limite-pedidos.component.css"],
})
export class LimitePedidosComponent {
  @Input() productos!: boolean;

  constructor(private router: Router) {}

  redireccionarSuscripcion(): void {
    const route: string[] = this.router.url.split("/");
    const [, lang] = route;

    this.router.navigate([lang, Path.Proyecto]);
  }
}
