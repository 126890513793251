import { Injectable } from "@angular/core";
import {
  DocumentReference,
  DocumentData,
  where,
  Timestamp,
} from "@angular/fire/firestore";
import { StorageService } from "./storage.service";
import { FirebaseRequestsService } from "./firebase-requests.service";
import { Compra } from "../models/compra.interface";
import { map, Observable } from "rxjs";
import { UploadTaskSnapshot } from "firebase/storage";

@Injectable({
  providedIn: "root",
})
export class ComprasService {
  constructor(
    private firebaseRequests: FirebaseRequestsService,
    private storageService: StorageService
  ) {}

  async addCompra(datos: Compra): Promise<DocumentReference> {
    try {
      return this.firebaseRequests.addDocFirebaseWithAutomaticId(
        "compras",
        datos
      );
    } catch (error) {
      console.error({ error });
      throw new Error("Error adding data");
    }
  }

  getPedidos(idComprador: string): Observable<DocumentData[]> {
    return this.firebaseRequests.getCollectionFirebaseWithQueryObservable<DocumentData>(
      "compras",
      [where("idComprador", "==", idComprador)]
    );
  }

  async updatePedido(id: string, data: Compra): Promise<void> {
    return this.firebaseRequests.updateDocFirebase(`compras/${id}`, data);
  }

  getPedidosVendedor(idVendedor: string): Observable<DocumentData[]> {
    return this.firebaseRequests
      .getCollectionFirebaseWithQueryObservable<DocumentData>("compras")
      .pipe(
        // TODO - Se deberia de hacer el query directamente a la
        // base de datos en vez de traer todo y filtrar aqui.
        map((compras) => {
          const comprasVendedor: any[] = [];
          compras.map((doc) => {
            const comprasFilter = doc["productos"].filter(
              (compra: any) => compra.idVendedor === idVendedor
            );

            comprasFilter.length && comprasVendedor.push({ ...doc });
          });
          return comprasVendedor;
        })
      );
  }

  getPedidoById(id: string): Observable<Compra> {
    return this.firebaseRequests.getDocFirebaseWithIdObservable<Compra>(
      `compras/${id}`
    );
  }

  uploadGuia(
    idItem: string,
    doc: File
  ): Observable<{
    progress: number;
    snapshot: UploadTaskSnapshot;
  }> {
    const path: string = `guiaEnvio/${idItem}`;
    return this.storageService.uploadFile(path, doc);
  }

  getPedidoByMonth(idComprador: string, month: number): Observable<Compra[]> {
    const currentYear: number = new Date().getFullYear();
    const startDate: Date = new Date(currentYear, month - 1, 1);
    const lastDayOfMonth: Date = new Date(
      currentYear,
      month,
      0,
      23,
      59,
      59,
      999
    );
    return this.firebaseRequests.getCollectionFirebaseWithQueryObservable<DocumentData>(
      "compras",
      [
        where("idComprador", "==", idComprador),
        where("fecha", ">=", Timestamp.fromDate(startDate)),
        where("fecha", "<=", Timestamp.fromDate(lastDayOfMonth)),
      ]
    ) as Observable<Compra[]>;
  }
}
