import { Injectable } from "@angular/core";
import {
  DocumentReference,
  DocumentSnapshot,
  QueryConstraint,
  QuerySnapshot,
  limit,
  orderBy,
  startAfter,
  startAt,
  where,
} from "@angular/fire/firestore";
import { switchMap } from "rxjs/operators";
import { Observable, firstValueFrom, forkJoin } from "rxjs";
import {
  NuevoServicioTecnico,
  NuevoServicioTransportador,
} from "../models/addServiceData";
import { UserData, UserService } from "./user.service";
import { FirebaseRequestsService } from "./firebase-requests.service";
import {
  ContractService,
  ResponseObservableContractService,
  ServiceUser,
} from "../models/contractService.interface";

@Injectable({
  providedIn: "root",
})
export class ServiceUserService {
  currentpage: number = 1;
  lastRefs: any[] = [];
  fin: boolean = false;
  limitPage: number = 5;
  serviceType: string = "";

  constructor(
    private firebaseRequest: FirebaseRequestsService,
    public userService: UserService
  ) {}

  async addService(data: NuevoServicioTransportador): Promise<string> {
    try {
      const documentReference: DocumentReference =
        await this.firebaseRequest.addDocFirebaseWithAutomaticId(
          "serviceUser",
          data
        );
      return documentReference.id;
    } catch (error) {
      console.error(error);
      throw new Error("Error adding data");
    }
  }

  async addServiceForest(data: NuevoServicioTecnico): Promise<string> {
    try {
      const documentReference: DocumentReference =
        await this.firebaseRequest.addDocFirebaseWithAutomaticId(
          "serviceUser",
          data
        );
      return documentReference.id;
    } catch (error) {
      console.error(error);
      throw new Error("Error adding data");
    }
  }

  async updateServicio(
    idServicio: string,
    data: Partial<ContractService>
  ): Promise<void> {
    try {
      await this.firebaseRequest.updateDocFirebase(
        `serviciosContratados/${idServicio}`,
        data
      );
    } catch (error) {
      console.error(error);
      throw new Error("Error updating data");
    }
  }

  getUserServices(uid: string, type: string): Observable<ServiceUser[]> {
    return this.firebaseRequest.getCollectionFirebaseWithQueryObservable<ServiceUser>(
      "serviceUser",
      [where("userId", "==", uid), where("type", "==", type)]
    );
  }

  async deleteService(idServicio: string): Promise<void> {
    return this.firebaseRequest.deleteDocFirebase(`serviceUser/${idServicio}`);
  }

  getServices(action?: "prev" | "next"): Observable<ServiceUser[]> {
    const query: QueryConstraint[] = [orderBy("tiempoCreado", "desc")];
    switch (action) {
      case "next":
        query.push(startAfter(this.lastRefs[this.currentpage].lastSnap));
        this.currentpage++;
        break;
      case "prev":
        query.push(startAt(this.lastRefs[this.currentpage - 1].firstSnap));
        this.currentpage--;
        break;
    }

    if (this.serviceType != "") {
      query.push(where("nameService", "==", this.serviceType));
    }

    query.push(limit(this.limitPage));

    return this.firebaseRequest
      .getCollectionFirebaseWithQueryObservable<ServiceUser>(
        "serviceUser",
        query
      )
      .pipe(
        switchMap((servicios: ServiceUser[]) => {
          return this.mapServiceData(servicios);
        })
      );
  }

  private mapServiceData(servicios: ServiceUser[]): Observable<ServiceUser[]> {
    return new Observable<ServiceUser[]>((observer) => {
      if (!servicios.length) {
        observer.next([]);
        observer.complete();
      }

      const first: DocumentReference =
        this.firebaseRequest.getFirebaseDocReference(
          `serviceUser/${servicios[0]["id"]}`
        );
      const last: DocumentReference =
        this.firebaseRequest.getFirebaseDocReference(
          `serviceUser/${servicios[servicios.length - 1]["id"]}`
        );

      const firstSnapPromise: Promise<DocumentSnapshot> =
        this.firebaseRequest.getFirebaseDocSnapReference(first);
      const lastSnapPromise: Promise<DocumentSnapshot> =
        this.firebaseRequest.getFirebaseDocSnapReference(last);
      Promise.all([firstSnapPromise, lastSnapPromise])
        .then(([firstSnap, lastSnap]) => {
          this.lastRefs[this.currentpage] = { firstSnap, lastSnap };
          return this.firebaseRequest.getAllCollectionFirebasePromiseQuerySnapshot(
            "serviceUser"
          );
        })
        .then((services: QuerySnapshot) => {
          this.fin = services.size <= this.currentpage * this.limitPage;
          observer.next(servicios);
          observer.complete();
        })
        .catch((error) => {
          console.error(error);
          observer.error(error);
        });
    });
  }

  async contractService(
    service: ContractService | Partial<ContractService>
  ): Promise<string> {
    try {
      service.servicio = this.firebaseRequest.getFirebaseDocReference(
        `serviceUser/${service.idServicio}`
      );

      const documentReference: DocumentReference =
        await this.firebaseRequest.addDocFirebaseWithAutomaticId(
          "serviciosContratados",
          service
        );

      return documentReference.id;
    } catch (error) {
      console.error(error);
      throw new Error("Error adding data");
    }
  }

  async checkContract(serviceId: string, userId: string): Promise<boolean> {
    try {
      const dataServices: ContractService[] =
        await this.firebaseRequest.getCollectionFirebasePromiseWithId<ContractService>(
          "serviciosContratados",
          [
            where("idContratante", "==", userId),
            where("idServicio", "==", serviceId),
          ]
        );
      return dataServices.length ? true : false;
    } catch (error) {
      console.error(error);
      throw new Error("Error adding data");
    }
  }

  getContractedServices(
    idUser: string
  ): Observable<ResponseObservableContractService[]> {
    return this.firebaseRequest
      .getCollectionFirebaseWithQueryObservable<ContractService>(
        "serviciosContratados",
        [
          orderBy("fechaContratacion", "desc"),
          where("idContratante", "==", idUser),
        ]
      )
      .pipe(
        switchMap((services: ContractService[]) => {
          const mappedServices$ = services.map((service: ContractService) => {
            return this.mapServiceContractedServices(service);
          });
          return forkJoin(mappedServices$);
        })
      );
  }

  private async mapServiceContractedServices(
    service: ContractService
  ): Promise<ResponseObservableContractService> {
    const contractService: ContractService = service;
    const servicio: ServiceUser = await this.getServiceById(
      contractService.servicio.id
    );
    const userData: UserData = await firstValueFrom(
      this.userService.getUserById(servicio.userId)
    );
    return { ...contractService, user: userData, servicioUsuario: servicio };
  }

  getServiceById(id: string): Promise<ServiceUser> {
    return this.firebaseRequest.getDocFirebaseWithIdPromise<ServiceUser>(
      `serviceUser/${id}`
    );
  }

  getServiceRequest(idUser: string): Observable<ContractService[]> {
    return this.firebaseRequest.getCollectionFirebaseWithQueryObservable<ContractService>(
      "serviciosContratados",
      [where("idContratado", "==", idUser)]
    );
  }
}
