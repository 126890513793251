import { Injectable } from "@angular/core";
import { StoreService } from "src/app/core/service/store.service";
import { WtReportState } from "../model/wt-report.state";

@Injectable({
  providedIn: "root",
})
export class WtReportStore extends StoreService<WtReportState> {
  public store: string =
    "wt-report-store" + "-" + Date.now().toString().slice(-3);

  constructor() {
    super({
      collection: [],
    });
  }
}
